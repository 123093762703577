import React from 'react'
import DeviceStatusAirflowSection from './airflowsection/DeviceStatusAirflowSection.js';
import DeviceStatusAbnormalities from './abnormalities/DeviceStatusAbnormalities.js';
import DeviceStatusConfigurations from './configurations/DeviceStatusConfigurations.js';
import {paramNames} from "../../../parameters/parameterSpec.js";
import {isAwareDevice} from "../../../../helpers/helperfunctions.js";
import AwareDeviceStatusConfigurations from "./configurations/AwareDeviceStatusConfigurations.js";
import {awareTelemNames} from "../../../parameters/awareTelemSpec.js";

/** Renders information about the device, hereunder alarms, processes, airflow across the device and operational conditions
 * @param {object} device object with latest_telemetry and metadata
 * @param {string[]} deviceAlarms List of translated alarms that are currently active on the device
 * @param {string[]} deviceWarnings List of translated warnings that are currently active on the device
 * @param {string[]} hardwareErrors List of translated hardware errors that are currently active on the device
 * @param {string[]} deviceProcesses List of processes on device
 */
export default function DeviceStatus({device, deviceAlarms, deviceWarnings, hardwareErrors, deviceProcesses}) {

    function getRoom() {
        if (!device.connected) return "-"
        if (isAwareDevice(device.serial_number))
            return device?.latest_telemetry?.[awareTelemNames.t_ar]?.toLocaleString(undefined, {
                maximumFractionDigits: 1, minimumFractionDigits: 1
            })
        else
            return device?.latest_telemetry?.[paramNames.room_temperature]
    }

    function getRoomHasErrors() {
        if (!device.connected) return ""
        if (isAwareDevice(device.serial_number)) return ""
        else return device?.hardware_errors?.HWError_RoomTemperaturesensor
    }

    function getInlet() {
        if (!device.connected) return "-"
        if (isAwareDevice(device.serial_number)) return device?.latest_telemetry?.[awareTelemNames.t_ai]?.toLocaleString(undefined, {
            maximumFractionDigits: 1, minimumFractionDigits: 1
        })
        else return device?.latest_telemetry?.[paramNames.inlet_temperature]
    }

    function getOutlet() {
        if (!device.connected) return "-"
        if (isAwareDevice(device.serial_number)) return device?.latest_telemetry?.[awareTelemNames.t_ae]?.toLocaleString(undefined, {
            maximumFractionDigits: 1, minimumFractionDigits: 1
        })
        else return device?.latest_telemetry?.[paramNames.exhaust_temperature_ventilation]
    }

    function getOutletHasErrors() {
        if (!device.connected) return ""
        if (isAwareDevice(device.serial_number)) return ""
        else return device?.hardware_errors?.HWError_ExhaustTemperaturesensorVentilationUnit
    }

    function getInletHasErrors() {
        if (!device.connected) return ""
        if (isAwareDevice(device.serial_number)) return ""
        else return device?.hardware_errors?.HWError_InletTemperaturesensor
    }

    function getOutsideHasErrors() {
        if (!device.connected) return ""
        if (isAwareDevice(device.serial_number)) return ""
        else return device?.hardware_errors?.HWError_OutsideTemperaturesensor
    }

    function getOutside() {
        if (!device.connected) return "-"
        if (isAwareDevice(device.serial_number)) {
            return device?.latest_telemetry?.[awareTelemNames.t_ao]?.toLocaleString(undefined, {
                maximumFractionDigits: 1, minimumFractionDigits: 1
            })
        }
        if (device.installed_components.cooler) return device?.latest_telemetry?.[paramNames.outside_temperature]
        return device?.latest_telemetry?.[paramNames.outside_temperature_ventilation]
    }

    return (
        <div className='d-flex d-xs-block'>
            {isAwareDevice(device.serial_number) &&
                <AwareDeviceStatusConfigurations
                    className='boxstyling rounded p-4 px-5 bg-airmaster-blue-dark me-2 text-white'
                    device={device} style={{flex: '2', fontSize: '19px'}}
                />
            }
            {!isAwareDevice(device.serial_number) &&
                <DeviceStatusConfigurations
                    className='boxstyling rounded p-4 px-5 bg-airmaster-blue-dark me-2 text-white'
                    device={device} style={{flex: '2', fontSize: '19px'}}
                />
            }
            <div className='d-flex flex-column' style={{flex: '5'}}>
                <DeviceStatusAirflowSection
                    device={device}
                    outlet={getOutlet()}
                    outletHasError={getOutletHasErrors()}
                    inlet={getInlet()}
                    inletHasError={getInletHasErrors()}
                    outside={getOutside()}
                    outsideHasError={getOutsideHasErrors()}
                    room={getRoom()}
                    roomHasError={getRoomHasErrors()}
                />
                {!(deviceAlarms.length === 0 && deviceWarnings.length === 0 && hardwareErrors.length === 0 && deviceProcesses.length === 0) &&
                    <DeviceStatusAbnormalities
                        deviceAlarms={deviceAlarms} deviceWarnings={deviceWarnings} hardwareErrors={hardwareErrors}
                        deviceProcesses={deviceProcesses}
                    />
                }
            </div>
        </div>
    )
}