import React from 'react'
import {Button, OverlayTrigger, Popover} from 'react-bootstrap';
import { ExclamationTriangle, InfoCircle} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';
import {formatDateTimeString} from "../../../../helpers/helperfunctions.js";
import {useParams} from "react-router-dom";
import { log } from 'handlebars';
import {useMsal} from "@azure/msal-react";
import {hasAnyRole} from "../../../../helpers/authHelper.js";
import {roleConstants} from "../../../../helpers/constants.js";

/** Renders an individual item in the devicelog table. The component renders differently depending on the log-event type.
 * @param {object} logItem log data object to render in the table
 */
export default function DeviceLogTablepItem({logItem}) {
    const {t} = useTranslation(['dashboard', 'telemetry', 'devicelogs', 'common', 'settingspage'])
    const {deviceSerialNumber} = useParams();
    const {accounts} = useMsal();

    let event = JSON.stringify(logItem.event).replace(/"/g, ''); // remove quotes
    let [eventType, message] = event.split('##');

    function renderMessagePopover() {
        if (!hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) || message === undefined) {
            return null;
        }
        return (
            <OverlayTrigger
                placement="top"
                rootClose
                trigger="click"
                animation={false}
                transition={false}
                overlay={
                    <Popover className="shadow default-width" id="popover-basic">
                        <Popover.Header className="text-white bg-primary" as="h5">
                            {t('dashboard:log.message')}
                        </Popover.Header>
                        <Popover.Body>
                            <div className="log-body-styling">{message}</div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button
                    variant="popover"
                    style={{ fontSize: "12px" }}
                    className="ps-0 pt-0 shadow-none text-decoration-underline"
                >
                    {t('dashboard:log.see_message')}
                </Button>
            </OverlayTrigger>
        );
    }

    function getAlarmLayout(text) {
        const isFilterText = text.toLocaleLowerCase().startsWith("filter");
        return (
            <>
                <span>{t('dashboard:log.table_items.alarm')}: </span>
                {isFilterText ? (
                    <a href={`/device/${deviceSerialNumber}/configurations/filter`}>{text}</a>
                ) : (
                    <span>{text}</span>
                )}
                <br/>
                {renderMessagePopover()}
            </>
        );
    }

    function getWarningLayout(text) {
        return <>
            <span>{t('dashboard:log.table_items.warning')}: </span>{text}<br/>
            {renderMessagePopover()}
        </>
    }

    const appendIcon = (type) => {
        switch (type) {
            case 'WARNING':
                return <div style={{width:'32px',height:'32px', flex: '0 0 32px'}}><ExclamationTriangle className='w-100 h-100 color-warning-dark'/></div>
            case 'HARDWARE_ERROR': // fall through to alarm
            case 'ALARM':
                return <div style={{width:'32px',height:'32px', flex: '0 0 32px'}}><ExclamationTriangle className='w-100 h-100 color-danger-dark'/></div>
            case 'PARAMETER':
            case 'PARAMETER_CHANGE_REQUEST':
            case 'PROCESS':
                return <div style={{width:'32px',height:'32px', flex: '0 0 32px'}}><InfoCircle className='w-100 h-100 color-success-dark'/></div>
            default:
                break;
        }
    }

    function getParameterDescriptionText(data) {
        if (data.event === 'PERIOD_CHANGE_REQUEST')
            return <span>{t('dashboard:log.period_change_request_text')}<b
                className='ms-1'>{data?.description?.startsWith("DEFAULT_PERIOD_NAME") ? t('settingspage:device_timers.default_period_popover_dialog.title') : data?.description}</b>.
                <span className='ms-1'>{t('dashboard:log.table_items.parameter_change_request')}</span></span>

        if (data.event === 'PARAMETER_CHANGE_REQUEST')
            return <>
                <OverlayTrigger placement="top" rootClose trigger='click' animation={false}
                                transition={false} overlay={
                    <Popover className=' shadow default-width' id="popover-basic">
                        <Popover.Header className='text-white default-header-color'
                                        as="h5">{data?.userMail}</Popover.Header>
                        <Popover.Body>
                            <div className='log-body-styling'>
                                <div className='d-flex pb-1'>
                                    <div className='w-75px'>{t("dashboard:log.user.mail")}</div>
                                    <div>{data?.userMail}</div>
                                </div>
                                {data?.userPhone &&
                                    <div className='d-flex pb-1'>
                                        <div className='w-75px'>{t("dashboard:log.user.phone")}</div>
                                        <div>{data?.userPhone}</div>
                                    </div>
                                }
                                <div className='d-flex pb-1'>
                                    <div className='w-75px'>{t("dashboard:log.user.name")}</div>
                                    <div>{data?.userName ?? t("dashboard:log.user.deleted_user")}</div>
                                </div>
                                <div className='d-flex'>
                                    <div className='w-75px'>{t("dashboard:log.user.role")}</div>
                                    <div>{t(`common:appRoles.${data?.userRole}`)}</div>
                                </div>
                            </div>
                        </Popover.Body>
                    </Popover>
                }>
                    <Button variant="popover" style={{fontSize: "12px"}}
                            className="pt-0 pb-1 pe-1 ps-0 shadow-none text-decoration-underline">
                        {data?.userMail}
                    </Button>
                </OverlayTrigger>
                <span>{t('dashboard:log.table_items.parameter_change_request')}</span>
            </>

        return <span>{t('dashboard:log.table_items.parameter', {count: data.parameters.length === 1 ? t(`devicelogs:${data.parameters[0].parameter}`) : data.parameters.length})}</span>
    }

    function getEventLayout(data) {
        return <>
            {getParameterDescriptionText(data)}
            {data.parameters.length === 1 ?
                <span className=' text-decoration-underline'>
                    {`${t(`devicelogs:${data.parameters[0].parameter}`)} ${t("common:general.from")} ${data.parameters[0].before} ${t("common:general.to")} ${data.parameters[0].now}`}
                </span>
                :
                <OverlayTrigger placement="top" rootClose trigger='click' animation={false}
                                transition={false} overlay={
                    <Popover className='shadow default-width' id="popover-basic">
                        <Popover.Header className='text-white bg-primary' as="h5">Parameter changes</Popover.Header>
                        <Popover.Body>
                            <div className='log-body-styling'>
                                {data.parameters.map(parameter =>
                                    <div key={parameter.parameter}
                                         className='flex-xl-basis-1 flex-xl-grow-1 d-flex flex-row w-100 py-2 py-xl-0 pe-4'>
                                                                <span
                                                                    className='my-1 pe-3 text-nowrap'>{t(`devicelogs:${parameter.parameter}`)}:</span>
                                        <div className='float-end small flex-grow-1 text-end align-self-center'>
                                            <div
                                                className='d-inline-block bg-danger rounded text-center text-white px-2'>{parameter.before}</div>
                                            <span className='d-inline-block text-center mx-2'>{' \u2192 '}</span>
                                            <div
                                                className='d-inline-block bg-success rounded text-center text-white px-2'>{parameter.now}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Popover.Body>
                    </Popover>
                }>
                    <Button variant="popover" style={{fontSize: "12px"}}
                            className="ps-0 pt-0 shadow-none text-decoration-underline">
                        {t('dashboard:log.see_changes')}
                    </Button>
                </OverlayTrigger>
            }
        </>
    }

    function getBackground(type) {
        if (type === 'WARNING') return "bg-warning-dim"
        if (type === 'PROCESS') return "bg-success-dim"
        if (logItem.type === 'HARDWARE_ERROR' || logItem.type === 'ALARM') return "bg-danger-dim"
        if (logItem.type === 'PARAMETER') return "bg-success-dim"
    }

    return (
        <div className={`p-4 py-3 mb-1 rounded position-relative ${getBackground(logItem.type)}`}>
            <div className='d-flex align-items-center'>
                {appendIcon(logItem.type)}
                <div className='ps-2 flex-grow-1'>
                    {logItem.type === 'WARNING' && getWarningLayout(t(`telemetry:${eventType}`))}
                    {logItem.type === 'PROCESS' && `${t('dashboard:log.table_items.process')}: ${t(`telemetry:${eventType}`)}`}
                    {(logItem.type === 'HARDWARE_ERROR' || logItem.type === 'ALARM') && getAlarmLayout(t(`telemetry:${eventType}`))}
                    {(logItem.type === 'PARAMETER' || logItem.type === 'PARAMETER_CHANGE_REQUEST') && getEventLayout(logItem)}
                </div>
            </div>
            <div className='d-block text-end text-muted position-absolute'
                 style={{fontSize: "11px", bottom: '10px', right: '10px'}}>
                {formatDateTimeString(logItem.ts, true, true)}
            </div>
        </div>
    )
}