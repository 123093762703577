// noinspection EqualityComparisonWithCoercionJS

import React, {useEffect, useState} from "react";
import {DeviceTechnicalTelemetryOverviewItemContainer} from "./DeviceTechnicalTelemetryOverviewItemContainer.js";
import {DeviceTechnicalTelemetryOverviewItem} from "./DeviceTechnicalTelemetryOverviewItem.js";
import {awareTelemNames, awareTelemSpec} from "./awareTelemSpec.js";
import {paramNames, paramSpec} from "./parameterSpec.js";
import {
    convertEpochToDateTime,
    generateAwareAlarmWarningsTableFromJson,
    getFormattedDate, roundToFractionDigits,
    saveCSV,
    trimRight
} from "../../helpers/helperfunctions.js";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {FileSpreadsheetFill} from "react-bootstrap-icons";
import {toInteger} from "lodash";
import {isAdmin} from "../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import UploadSettingsModal from "./modals/UploadSettingsModal.js";
import {isUndefined} from "lodash/lang.js";

/** Renders technical telemetry overview component
 * @param device Device to use telemetry from
 * @returns {JSX.Element}
 * @constructor
 */
export function AwareDeviceTechnicalTelemetryOverview({device}) {
    const {t} = useTranslation(['technicalpage', 'common'])
    const {accounts} = useMsal();
    const [uploadSettingsModalConfigObj, setUploadSettingsModalConfigObj] = useState({})

    const system_alarms = device.latest_telemetry["system_alarms"]
    const system_warnings = device.latest_telemetry["system_warnings"]

    function settingIsChanged(key, value) {
        if (isUndefined(device.device_twin.reported.parameters[key])) return true
        const reportedValue = device.device_twin.reported.parameters[key]
        if (typeof value === "string" || typeof value === "boolean") return value !== reportedValue
        if (reportedValue.length === 16 && reportedValue.split('').every(v => v === '1' || v === '0')) {
            // in the settings files, bitwise are trimmed from right and recorded as base10, eg '1111100000000000' = 31
            //  ,so we do that for the existing values before comparing here
            const trimmedVal = trimRight(reportedValue, '0')
            if (trimmedVal === '') return value !== '0'
            const base10Value = parseInt(trimmedVal, 2)
            return base10Value !== toInteger(value)
        }
        return parseFloat(device.device_twin.reported.parameters[key]) !== parseFloat(value)
    }

    /** @param {string} result */
    async function uploadSettingsFile(result) {
        const decimalSep = findDecimalSeparator(result)

        const uploadedSettingsMap = Object.assign({}, ...result.trim().split("\n").map(s => {
            const [key, value] = s.split(";")
            return {[key]: parseUploadValue(decimalSep, value)}
        }))

        const readOnlyKeysSet = new Set(["482"])
        const uploadedSettingsDiscardedBecauseReadonlyEntryList = uploadedSettingsMap
            .toEntries()
            .filter(s => readOnlyKeysSet.has(s[0]))
        const uploadedSettingsWithoutReadonlyEntryList = uploadedSettingsMap
            .toEntries()
            .filter(s => !readOnlyKeysSet.has(s[0]))

        const unchangedEntryList = uploadedSettingsWithoutReadonlyEntryList.filter(([key, value]) => !settingIsChanged(key, value))
        const changedEntryList = uploadedSettingsWithoutReadonlyEntryList.filter(([key, value]) => settingIsChanged(key, value))

        setUploadSettingsModalConfigObj({
            uploadedSettingsMap,
            uploadedSettingsDiscardedBecauseReadonlyEntryList,
            uploadedSettingsWithoutReadonlyEntryList,
            unchangedEntryList,
            changedEntryList,
            device,
            isVisible: true
        })

        document.getElementById('uploadSettingsFileInput').value = null // otherwise can only upload once
    }

    useEffect(() => {
        // only admins can see this element, so we check if it is there before adding eventlistener
        const uploadSettingsFileInputEle = document.getElementById('uploadSettingsFileInput')
        if (uploadSettingsFileInputEle) {
            uploadSettingsFileInputEle.addEventListener('change', function () {
                if (this.files.length === 0) return;
                const reader = new FileReader();
                reader.onload = event => uploadSettingsFile(event.target.result);
                reader.readAsText(this.files[0]);
            })
        }
    }, [])

    function downloadSettingsCSV() {
        const csvRows = [];
        const keys = Object.keys(device.device_twin.reported.parameters);
        for (const key of keys) {
            csvRows.push(`${key};${device.device_twin.reported.parameters[key]}`);
        }
        saveCSV(`${device.serial_number}.csv`, csvRows.join("\n"))
    }

    return <div>
        <UploadSettingsModal configObj={uploadSettingsModalConfigObj} setConfigObj={setUploadSettingsModalConfigObj}/>

        <div className='d-flex justify-content-between align-content-center mb-1'>

            {!isAdmin(accounts) &&
                <div>{t('technicalpage:overview.last_updated')} : {convertEpochToDateTime(device.latest_telemetry["time"])}</div>
            }
            {isAdmin(accounts) &&
                <div className='d-flex'>
                    <div className='me-4'>Sidste opdateret
                        telemetri: {convertEpochToDateTime(device.latest_telemetry["time"])}</div>
                    {device?.device_twin?.reported?.commandTime &&
                        <div>Sidste opdateret
                            parametre: {getFormattedDate(device?.device_twin?.reported?.commandTime)}</div>
                    }
                </div>
            }

            {isAdmin(accounts) &&
                <div>
                    <label className="btn btn-secondary btn-sm me-2 btn btn-primary">
                        <FileSpreadsheetFill className="me-1"/>{t('technicalpage:overview.upload_settings_btn.text')}
                        <input type="file" id="uploadSettingsFileInput" className="d-none"/>
                        {/* onClick handler is uploadSettingsFile function through roundabout js file eventhandler */}
                    </label>
                    <Button onClick={downloadSettingsCSV} type="button" className='btn btn-secondary btn-sm'>
                        <FileSpreadsheetFill className="me-1"/>{t('technicalpage:overview.download_settings_btn.text')}
                    </Button>
                </div>
            }
        </div>

        <div className="device-parameters-contentbox d-xs-block p-2">

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_mode]}
                                                      valueOverride={device.connected ? t('telemetry:AwareSystemMode_' + device.latest_telemetry[awareTelemNames.op_mode]) : "-"}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rc_state]}
                                                      valueOverride={device.connected ? t('telemetry:RecircState_' + device.latest_telemetry[awareTelemNames.rc_state]) : "-"}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.msm_state]}
                                                      valueOverride={device.connected ? t('telemetry:MainState_' + device.latest_telemetry[awareTelemNames.msm_state]) : "-"}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.started_by]}
                                                      valueOverride={device.connected ? t('telemetry:AwareStartedBy_' + device.latest_telemetry[awareTelemNames.started_by]) : "-"}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.filter_operating_time]}
                                                      value={device.latest_telemetry[awareTelemNames.filter_operating_time]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system_alarm')}>
                <div className="d-flex">
                    <div className='status-system-alarms'>
                        {
                            (system_alarms !== undefined && Object.keys(system_alarms).length > 0)
                                ? <div
                                    dangerouslySetInnerHTML={{__html: generateAwareAlarmWarningsTableFromJson(system_alarms)}}/>
                                : t('technicalpage:no_system_alarms')
                        }
                    </div>
                </div>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system_warnings')}>
                <div className="d-flex">
                    <div className='status-system-warnings'>
                        {
                            (system_warnings !== undefined && Object.keys(system_warnings).length > 0)
                                ? <div
                                    dangerouslySetInnerHTML={{__html: generateAwareAlarmWarningsTableFromJson(system_warnings)}}/>
                                : t('technicalpage:no_system_warnings')
                        }
                    </div>
                </div>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.flow_setpoints')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.udf]}
                                                      value={device.latest_telemetry[awareTelemNames.udf]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rqf]}
                                                      value={device.latest_telemetry[awareTelemNames.rqf]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer
                title={t('technicalpage:overview.title.temperature_setpoints')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.udt]}
                                                      value={device.latest_telemetry[awareTelemNames.udt]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rqt]}
                                                      value={device.latest_telemetry[awareTelemNames.rqt]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.supply_fan')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_1_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_1_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_flow]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_flow]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_pm_1_u_rms]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_pm_1_u_rms]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_pm_1_i_rms]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_pm_1_i_rms]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_1_rpm]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_1_rpm]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_pm_1_p_act]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_pm_1_p_act]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_sup_pm_1_e]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_sup_pm_1_e]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.extraction_fan')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_1_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_1_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_flow]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_flow]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_pm_1_u_rms]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_pm_1_u_rms]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_pm_1_i_rms]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_pm_1_i_rms]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_1_rpm]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_1_rpm]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_pm_1_p_act]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_pm_1_p_act]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.fan_ext_pm_1_e]}
                                                      value={device.latest_telemetry[awareTelemNames.fan_ext_pm_1_e]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.heaters')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.hvac_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.hvac_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.ech_1_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.ech_1_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.ech_2_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.ech_2_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.ech_3_pct]}
                                                      value={device.latest_telemetry[awareTelemNames.ech_3_pct]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.eph_pm_p_act]}
                                                      value={device.latest_telemetry[awareTelemNames.eph_pm_p_act]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.eph_pm_e]}
                                                      value={device.latest_telemetry[awareTelemNames.eph_pm_e]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.dampers')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_inlet_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_inlet_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_main_sup_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_main_sup_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_main_ext_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_main_ext_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_bypass_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_bypass_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_recirc_in_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_recirc_in_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.damper_recirc_out_pos]}
                                                      value={device.latest_telemetry[awareTelemNames.damper_recirc_out_pos]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.air_temperatures')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_ai]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_ai], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_ar]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_ar], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_ae]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_ae], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_ao]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_ao], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_aop]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_aop], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_aio]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_aio], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_aii]}
                                                      value={roundToFractionDigits(device.latest_telemetry[awareTelemNames.t_aii], 1)}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer
                title={t('technicalpage:overview.title.heat_pump_temperatures')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_vto]}
                                                      value={device.latest_telemetry[awareTelemNames.t_vto]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_vic]}
                                                      value={device.latest_telemetry[awareTelemNames.t_vic]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_vti]}
                                                      value={device.latest_telemetry[awareTelemNames.t_vti]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_voc]}
                                                      value={device.latest_telemetry[awareTelemNames.t_voc]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_lbo]}
                                                      value={device.latest_telemetry[awareTelemNames.t_lbo]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_lbi]}
                                                      value={device.latest_telemetry[awareTelemNames.t_lbi]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_lie]}
                                                      value={device.latest_telemetry[awareTelemNames.t_lie]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_loe]}
                                                      value={device.latest_telemetry[awareTelemNames.t_loe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_pci]}
                                                      value={device.latest_telemetry[awareTelemNames.t_pci]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.t_pco]}
                                                      value={device.latest_telemetry[awareTelemNames.t_pco]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.sensors')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.co2_ar]}
                                                      value={device.latest_telemetry[awareTelemNames.co2_ar]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.co2_ae]}
                                                      value={device.latest_telemetry[awareTelemNames.co2_ae]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.co2_ai]}
                                                      value={device.latest_telemetry[awareTelemNames.co2_ai]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.co2_ao]}
                                                      value={device.latest_telemetry[awareTelemNames.co2_ao]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.tvoc_ar]}
                                                      value={device.latest_telemetry[awareTelemNames.tvoc_ar]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.tvoc_ae]}
                                                      value={device.latest_telemetry[awareTelemNames.tvoc_ae]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.tvoc_ai]}
                                                      value={device.latest_telemetry[awareTelemNames.tvoc_ai]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.tvoc_ao]}
                                                      value={device.latest_telemetry[awareTelemNames.tvoc_ao]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rh_ar]}
                                                      value={device.latest_telemetry[awareTelemNames.rh_ar]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rh_ae]}
                                                      value={device.latest_telemetry[awareTelemNames.rh_ae]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rh_ai]}
                                                      value={device.latest_telemetry[awareTelemNames.rh_ai]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.rh_ao]}
                                                      value={device.latest_telemetry[awareTelemNames.rh_ao]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.filter')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.remaining_service_life_percent]}
                                                      value={device.latest_telemetry[awareTelemNames.remaining_service_life_pct]}
                                                      deviceConnected={device.connected} showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.filter_remaining_service_life_days]}
                                                      value={device.latest_telemetry[awareTelemNames.remaining_service_life_days]}
                                                      deviceConnected={device.connected} showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.remaining_service_life_hours]}
                                                      value={device.latest_telemetry[awareTelemNames.remaining_service_life_hrs]}
                                                      deviceConnected={device.connected} showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.condensate_system')}>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_pump]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_pump]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_pump_sup_phe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_pump_sup_phe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_pump_sup_ahe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_pump_sup_ahe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_pump_ext_phe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_pump_ext_phe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_pump_ext_ahe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_pump_ext_ahe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_valve_sup_phe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_valve_sup_phe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_valve_sup_ahe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_valve_sup_ahe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_valve_ext_phe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_valve_ext_phe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={awareTelemSpec[awareTelemNames.cds_valve_ext_ahe]}
                                                      value={device.latest_telemetry[awareTelemNames.cds_valve_ext_ahe]}
                                                      deviceConnected={device.connected}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>
            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.firmware')}>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Firmware Version'}}
                    value={device.device_twin.reported.applicationVersion === null ? "N/A" : device.device_twin.reported.applicationVersion}
                    showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Ethernet MAC'}}
                    value={device.device_twin.reported.macAdr === null ? "N/A" : device.device_twin.reported.macAdr}
                    showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Ethernet Ip'}}
                    value={device.device_twin.reported.ipAdr === null ? "N/A" : device.device_twin.reported.ipAdr}
                    showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={{type: 'manual', id: '', unit: '', name_orig: 'Wlan MAC'}}
                                                      value={device.device_twin.reported.wlanMacAdr === null ? "N/A" : device.device_twin.reported.wlanMacAdr}
                                                      showId={false}/>
                <DeviceTechnicalTelemetryOverviewItem item={{type: 'manual', id: '', unit: '', name_orig: 'Wlan Ip'}}
                                                      value={device.device_twin.reported.wlanIpAdr === null ? "N/A" : device.device_twin.reported.wlanIpAdr}
                                                      showId={false}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

        </div>
    </div>
}

/** Find decimal separator in string of key:value pairs. The one with the most should be decimal separator.
 * @param {string} result
 * @returns {undefined} */
function findDecimalSeparator(result) {
    const commaNum = result.split('').filter(c => c === ',').length
    const dotNum = result.split('').filter(c => c === '.').length
    return dotNum > commaNum ? "." : ","
}

/** transform value to '.' decimal and cut off unnecessary zeroes from end
 * Examples - if decimalSep is ',': 3.000,00 -> 3000 and 2,2 -> 2.2
 * If value cannot be parsed to number in this way, just return it as string
 * @param {string} decimalSep ',' or '.'
 * @param {string} value
 * @returns {string | number | boolean} */
function parseUploadValue(decimalSep, value) {
    const dotDecimalValue = decimalSep === ","
        ? value.trim().replaceAll(".", "").replaceAll(",", ".")
        : value.trim().replaceAll(",", "")
    const number = parseFloat(dotDecimalValue)
    if (isNaN(number)) {
        if (value === "true") return true
        if (value === "false") return false
        return value // string
    }
    return number
}