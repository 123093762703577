/** This file has been generated. If you see errors or missing parameters, don't change this file. Instead, fix scripts/generate_je_parameters_specification_from_xml/generate_je_parameters_specification_from_xml.js and regenerate it. */
export function getDeviceParamsStateStructure() {
    return {
        "selectedParameter": null,
        "hierarchyList": [
            {
                "name": "Operation",
                "type": "category",
                "id": "15f362ad-354f-4fad-8232-d62e3068da89",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "Default Setpoints",
                        "type": "category",
                        "id": "f2bd520f-31db-4e61-b538-b3a52cacbde3",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 3,
                                    "name": "default_airflow",
                                    "name_orig": "Default Airflow",
                                    "desc": "Default airflow, value loaded power up, and eventually during stop (depend on settings)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Default Setpoints"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 4,
                                    "name": "default_temperature",
                                    "name_orig": "Default Temperature",
                                    "desc": "Default temperature, value loaded power up, and eventually during stop (depend on settings)",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 19,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Default Setpoints"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "PIR",
                        "type": "category",
                        "id": "6d4fced0-f7a8-4a85-b0c0-aba07624b537",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 27,
                                    "name": "pir_afterrun_time",
                                    "name_orig": "PIR Afterrun Time",
                                    "desc": "The amount of time the PIR start signal continuous to be active after last precence detection",
                                    "type": "int",
                                    "min": 0,
                                    "max": 1080,
                                    "res": 1,
                                    "default": 30,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "PIR"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 690,
                                    "name": "pir_start_delay",
                                    "name_orig": "PIR Start delay",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 240,
                                    "res": 1,
                                    "default": 3,
                                    "showInTechnical": true,
                                    "unit": "min.",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "PIR"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Humidity",
                        "type": "category",
                        "id": "9c2d0f95-b2e8-4c3e-b1ca-26353234d625",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 49,
                                    "name": "humidity_control_enabled",
                                    "name_orig": "Humidity Control Enabled",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 344,
                                    "name": "hc_barometric_pressure_outside",
                                    "name_orig": "HC Barometric Pressure Outside",
                                    "desc": "Humidity Control barometric pressure outside",
                                    "type": "decimal",
                                    "min": 30,
                                    "max": 110,
                                    "res": 0.1,
                                    "default": 101.3,
                                    "showInTechnical": true,
                                    "unit": "kPa",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 345,
                                    "name": "hc_barometric_pressure_room",
                                    "name_orig": "HC Barometric Pressure Room",
                                    "desc": "Humidity Control barometric pressure room",
                                    "type": "decimal",
                                    "min": 30,
                                    "max": 110,
                                    "res": 0.1,
                                    "default": 101.3,
                                    "showInTechnical": true,
                                    "unit": "kPa",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 346,
                                    "name": "hc_d_ah_min_a",
                                    "name_orig": "HC d_AH_min_A",
                                    "desc": "Delta absolute humidity minimum A-coefficient",
                                    "type": "decimal",
                                    "min": "-9.9990000000000006",
                                    "max": "9.9990000000000006",
                                    "res": 0.001,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 347,
                                    "name": "hc_d_ah_min_b",
                                    "name_orig": "HC d_AH_min_B",
                                    "desc": "Delta absolute humidity minimum B-coefficient",
                                    "type": "decimal",
                                    "min": "-9.9990000000000006",
                                    "max": "9.9990000000000006",
                                    "res": 0.001,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 348,
                                    "name": "hc_d_ah_min_c",
                                    "name_orig": "HC d_AH_min_C",
                                    "desc": "Delta absolute humidity minimum C-coefficient",
                                    "type": "decimal",
                                    "min": -99.99,
                                    "max": 99.99,
                                    "res": 0.01,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 349,
                                    "name": "hc_d_ah_max_a",
                                    "name_orig": "HC d_AH_max_A",
                                    "desc": "Delta absolute humidity maximum A-coefficient",
                                    "type": "decimal",
                                    "min": "-9.9990000000000006",
                                    "max": "9.9990000000000006",
                                    "res": 0.001,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 350,
                                    "name": "hc_d_ah_max_b",
                                    "name_orig": "HC d_AH_max_B",
                                    "desc": "Delta absolute humidity maximum B-coefficient",
                                    "type": "decimal",
                                    "min": "-9.9990000000000006",
                                    "max": "9.9990000000000006",
                                    "res": 0.001,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 351,
                                    "name": "hc_d_ah_max_c",
                                    "name_orig": "HC d_AH_max_C",
                                    "desc": "Delta absolute humidity maximum C-coefficient",
                                    "type": "decimal",
                                    "min": -99.99,
                                    "max": 99.99,
                                    "res": 0.01,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Humidity"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Misc",
                        "type": "category",
                        "id": "610ef7ca-d837-4b50-9e88-f4cb39007228",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 72,
                                    "name": "reboot",
                                    "name_orig": "Reboot",
                                    "desc": "Reboots the unit",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 120,
                                    "name": "total_operating_hours",
                                    "name_orig": "Total Operating Hours",
                                    "desc": "Total Time Counter \n(changing this value requires factory password)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 300000,
                                    "res": 10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "h",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 121,
                                    "name": "operating_hours_since_last_service",
                                    "name_orig": "Operating Hours Since Last Service",
                                    "desc": "Time since last service",
                                    "type": "int",
                                    "min": 0,
                                    "max": 300000,
                                    "res": 10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "h",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 212,
                                    "name": "flow_hourlog",
                                    "name_orig": "Flow Hourlog",
                                    "desc": "Flow compensated hour counter (10 hour interval)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 300000,
                                    "res": 10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "h",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 244,
                                    "name": "halt",
                                    "name_orig": "Halt",
                                    "desc": "Yes: all moving parts inside the unit cease moving and  \"Halted\" is indicated in display\nNo: Unit is in \"normal\" mode",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 249,
                                    "name": "reset_active_errors",
                                    "name_orig": "Reset Active Errors",
                                    "desc": "System flags (bit-field): bit0: reset active errors",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Misc"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Night Cooling",
                        "type": "category",
                        "id": "690a032c-1718-4621-a879-e9b9bf04e822",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "D-BMS",
                                "type": "category",
                                "id": "9a28e8f8-7fb8-41cf-bf60-9df5db81e0da",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 75,
                                            "name": "nc_it",
                                            "name_orig": "NC IT",
                                            "desc": "Night Cooling: Fixed Inlet Temperature during Night Cooling",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 50,
                                            "res": 1,
                                            "default": 16,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "D-BMS"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 80,
                                            "name": "nc_flow",
                                            "name_orig": "NC Flow",
                                            "desc": "Night cooling: Fixed Airflow during Night Cooling",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "D-BMS"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "General",
                                "type": "category",
                                "id": "4a497264-cc59-4eb8-a0b4-b0f732d02032",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 78,
                                            "name": "nc_min_ot",
                                            "name_orig": "NC Min OT",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 50,
                                            "res": 0.5,
                                            "default": 5,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 81,
                                            "name": "nc_high_limit",
                                            "name_orig": "NC High Limit",
                                            "desc": "Night cooling: High limit. Daytime temperature to initiate Night Cooling",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 50,
                                            "res": 1,
                                            "default": 26,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 82,
                                            "name": "nc_low_limit",
                                            "name_orig": "NC Low Limit",
                                            "desc": "Night cooling: Low limit. Daytime temperature to maintain Night Cooling",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 50,
                                            "res": 1,
                                            "default": 23,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 556,
                                            "name": "nc_allow_cc",
                                            "name_orig": "NC Allow CC",
                                            "desc": "Allow Comfort Cooling during Nightcool periode",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ],
                                            "options": {
                                                "0": "No",
                                                "1": "Yes"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 625,
                                            "name": "nc_mode",
                                            "name_orig": "NC Mode",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 3,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ],
                                            "options": {
                                                "0": "NC Normal",
                                                "1": "NC Boost",
                                                "2": "NC Hybrid",
                                                "3": "NC Boost Hybrid"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 626,
                                            "name": "nc_boost_sfv",
                                            "name_orig": "NC Boost SFV",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 627,
                                            "name": "nc_boost_efv",
                                            "name_orig": "NC Boost EFV",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 628,
                                            "name": "nc_rtmin",
                                            "name_orig": "NC RTmin",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 50,
                                            "res": 1,
                                            "default": 16,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 629,
                                            "name": "nc_deltat",
                                            "name_orig": "NC DeltaT",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 50,
                                            "res": 1,
                                            "default": 2,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Night Cooling",
                                                "General"
                                            ]
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Holiday Mode",
                        "type": "category",
                        "id": "bf5041b3-010f-4edf-8614-2381d3e38e01",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 86,
                                    "name": "hm_flow",
                                    "name_orig": "HM Flow",
                                    "desc": "Holiday Airflow Level",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 5,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Holiday Mode"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 87,
                                    "name": "hm_temperature",
                                    "name_orig": "HM Temperature",
                                    "desc": "Holiday Ventilation Temperature",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Holiday Mode"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 358,
                                    "name": "hm_supply_air",
                                    "name_orig": "HM Supply Air",
                                    "desc": "Source of supply air in Holiday mode",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 0,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Holiday Mode"
                                    ],
                                    "options": {
                                        "0": "Fan",
                                        "1": "Window"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Start Priority",
                        "type": "category",
                        "id": "a42cf8c7-7da3-41a6-9e95-def7f83ece5d",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 90,
                                    "name": "start_dependency",
                                    "name_orig": "Start Dependency",
                                    "desc": "Whether the start priorities are independent or dependent",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "Independent",
                                        "1": "Dependent",
                                        "2": "1 Dependent + Independent"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 91,
                                    "name": "start_priority_1",
                                    "name_orig": "Start Priority 1",
                                    "desc": "Start priority 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 3,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 92,
                                    "name": "start_priority_2",
                                    "name_orig": "Start Priority 2",
                                    "desc": "Start priority 2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 93,
                                    "name": "start_priority_3",
                                    "name_orig": "Start Priority 3",
                                    "desc": "Start priority 3",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 94,
                                    "name": "start_priority_4",
                                    "name_orig": "Start Priority 4",
                                    "desc": "Start priority 4",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 95,
                                    "name": "start_priority_5",
                                    "name_orig": "Start Priority 5",
                                    "desc": "Start priority 5",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 7,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 222,
                                    "name": "night_cool_independent",
                                    "name_orig": "Night Cool Independent",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 368,
                                    "name": "start_priority_6",
                                    "name_orig": "Start Priority 6",
                                    "desc": "Start priority 6",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 369,
                                    "name": "start_priority_7",
                                    "name_orig": "Start Priority 7",
                                    "desc": "Start priority 7",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 685,
                                    "name": "start_priority_8",
                                    "name_orig": "Start Priority 8",
                                    "desc": "Start priority 8",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 9,
                                    "res": 1,
                                    "default": 8,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Start Priority"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "External Start",
                                        "2": "BMS",
                                        "3": "PIR",
                                        "4": "0-10V Flow",
                                        "5": "Panel",
                                        "6": "CO2",
                                        "7": "Timer",
                                        "8": "TVOC",
                                        "9": "Room Temperature"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Control Limits",
                        "type": "category",
                        "id": "d88745b4-12c6-4126-812b-f8fe93f590b5",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 101,
                                    "name": "minimum_flow_percent",
                                    "name_orig": "Minimum Flow %",
                                    "desc": "Minimum Airflow",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 40,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Control Limits"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 102,
                                    "name": "maximum_flow_percent",
                                    "name_orig": "Maximum Flow %",
                                    "desc": "Maximum Airflow, can be overrided by internal procedures",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Control Limits"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 104,
                                    "name": "temperature_setpoint_minimum",
                                    "name_orig": "Temperature Setpoint Minimum",
                                    "desc": "Indbl&#230;sningstemperatur min",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 14,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Control Limits"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 105,
                                    "name": "temperature_setpoint_maximum",
                                    "name_orig": "Temperature Setpoint Maximum",
                                    "desc": "Indbl&#230;sningstemperatur max",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 30,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Control Limits"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "CO2",
                        "type": "category",
                        "id": "23ec3a4c-a769-454b-86cf-a30195dd665a",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 118,
                                    "name": "co2_min",
                                    "name_orig": "CO2 Min",
                                    "desc": "CO2 concentration above this level, will cause a positive override of the flow",
                                    "type": "int",
                                    "min": 400,
                                    "max": 5000,
                                    "res": 50,
                                    "default": 500,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 119,
                                    "name": "co2_max",
                                    "name_orig": "CO2 Max",
                                    "desc": "The CO2 level causing maximum air flow",
                                    "type": "int",
                                    "min": 400,
                                    "max": 5000,
                                    "res": 50,
                                    "default": 1000,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 412,
                                    "name": "co2_control_mode",
                                    "name_orig": "CO2 Control Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Level",
                                        "2": "RateOfChange",
                                        "3": "Combined"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 663,
                                    "name": "ambient_pressure",
                                    "name_orig": "Ambient Pressure",
                                    "desc": "",
                                    "type": "int",
                                    "min": 700,
                                    "max": 1200,
                                    "res": 1,
                                    "default": 1013,
                                    "showInTechnical": true,
                                    "unit": "mBar",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 667,
                                    "name": "fco2",
                                    "name_orig": "FCO2",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 14950,
                                    "showInTechnical": true,
                                    "unit": "m3/h / g/h",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 670,
                                    "name": "co2_n_avg_now",
                                    "name_orig": "CO2 n avg now",
                                    "desc": "",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 671,
                                    "name": "co2_n_avg_bw",
                                    "name_orig": "CO2 n avg bw",
                                    "desc": "",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 689,
                                    "name": "co2_start",
                                    "name_orig": "CO2 Start",
                                    "desc": "Start operation for this unit \\n (Default value: 0 ppm) \\n  \\n CO2 Start = 0 ppm \\n Start operation at CO2 Min + 10% \\n  \\n CO2 Start = x ppm \\n Start operation at x ppm",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "CO2"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Boost",
                        "type": "category",
                        "id": "ccca5164-c422-43f3-a919-a1d5b164d8d8",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 359,
                                    "name": "allow_boost",
                                    "name_orig": "Allow Boost",
                                    "desc": "Enable Boost function",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 0,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Boost"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 360,
                                    "name": "boost_max_volt_in",
                                    "name_orig": "Boost Max Volt In",
                                    "desc": "max supply fan voltage when Boost is requested",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Boost"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 361,
                                    "name": "boost_max_volt_out",
                                    "name_orig": "Boost Max Volt Out",
                                    "desc": "max exhaust fan voltage when Boost is requested",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Boost"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 544,
                                    "name": "boost_afterrun_time",
                                    "name_orig": "Boost Afterrun Time",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 1080,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Boost"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 545,
                                    "name": "boost_auto_off_time",
                                    "name_orig": "Boost Auto Off Time",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 1080,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Boost"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Timer",
                        "type": "category",
                        "id": "9478eb22-60f2-4ff9-af59-d3bcd52de60d",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Settings",
                                "type": "category",
                                "id": "ed951af6-ab9c-41ce-b81f-36b16c938a65",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 438,
                                            "name": "timezone___offset_from_utc_in_minutes",
                                            "name_orig": "TimeZone - offset from UTC in minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": -1440,
                                            "max": 1440,
                                            "res": 1,
                                            "default": 60,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 439,
                                            "name": "automatic_daylight_saving_time",
                                            "name_orig": "Automatic Daylight Saving Time",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "No",
                                                "1": "Yes"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 1",
                                "type": "category",
                                "id": "c9f71c8c-d698-4225-9e83-df86923eaf69",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 440,
                                            "name": "timer_1_days",
                                            "name_orig": "Timer 1 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 31,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 441,
                                            "name": "timer_1_flags",
                                            "name_orig": "Timer 1 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 17,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 442,
                                            "name": "timer_1_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 1 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 443,
                                            "name": "timer_1_duration_minutes",
                                            "name_orig": "Timer 1 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 444,
                                            "name": "timer_1_flow_percent",
                                            "name_orig": "Timer 1 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 445,
                                            "name": "timer_1_temperature_c",
                                            "name_orig": "Timer 1 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 1"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 2",
                                "type": "category",
                                "id": "c48011f7-09fb-4cb1-ac49-732b67190ec2",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 446,
                                            "name": "timer_2_days",
                                            "name_orig": "Timer 2 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 31,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 447,
                                            "name": "timer_2_flags",
                                            "name_orig": "Timer 2 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 448,
                                            "name": "timer_2_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 2 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 449,
                                            "name": "timer_2_duration_minutes",
                                            "name_orig": "Timer 2 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 450,
                                            "name": "timer_2_flow_percent",
                                            "name_orig": "Timer 2 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 40,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 451,
                                            "name": "timer_2_temperature_c",
                                            "name_orig": "Timer 2 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 2"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 3",
                                "type": "category",
                                "id": "37b2714b-0f8a-4ab2-b16c-64edfd1ca660",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 452,
                                            "name": "timer_3_days",
                                            "name_orig": "Timer 3 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 96,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 453,
                                            "name": "timer_3_flags",
                                            "name_orig": "Timer 3 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 454,
                                            "name": "timer_3_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 3 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 455,
                                            "name": "timer_3_duration_minutes",
                                            "name_orig": "Timer 3 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 456,
                                            "name": "timer_3_flow_percent",
                                            "name_orig": "Timer 3 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 457,
                                            "name": "timer_3_temperature_c",
                                            "name_orig": "Timer 3 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 3"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 4",
                                "type": "category",
                                "id": "189f07f5-1e1b-4e80-b524-a43a6c8ff24b",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 458,
                                            "name": "timer_4_days",
                                            "name_orig": "Timer 4 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 96,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 459,
                                            "name": "timer_4_flags",
                                            "name_orig": "Timer 4 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 460,
                                            "name": "timer_4_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 4 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 461,
                                            "name": "timer_4_duration_minutes",
                                            "name_orig": "Timer 4 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 462,
                                            "name": "timer_4_flow_percent",
                                            "name_orig": "Timer 4 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 40,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 463,
                                            "name": "timer_4_temperature_c",
                                            "name_orig": "Timer 4 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 4"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 5",
                                "type": "category",
                                "id": "ff1b0a84-6d89-41db-b8ef-65f2278d44e4",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 464,
                                            "name": "timer_5_days",
                                            "name_orig": "Timer 5 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 127,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 465,
                                            "name": "timer_5_flags",
                                            "name_orig": "Timer 5 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 466,
                                            "name": "timer_5_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 5 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 467,
                                            "name": "timer_5_duration_minutes",
                                            "name_orig": "Timer 5 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 468,
                                            "name": "timer_5_flow_percent",
                                            "name_orig": "Timer 5 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 469,
                                            "name": "timer_5_temperature_c",
                                            "name_orig": "Timer 5 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 5"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 6",
                                "type": "category",
                                "id": "e06da55f-cb7f-47d0-8d41-c1e989a9f5cc",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 470,
                                            "name": "timer_6_days",
                                            "name_orig": "Timer 6 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 127,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 471,
                                            "name": "timer_6_flags",
                                            "name_orig": "Timer 6 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 472,
                                            "name": "timer_6_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 6 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 420,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 473,
                                            "name": "timer_6_duration_minutes",
                                            "name_orig": "Timer 6 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 600,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 474,
                                            "name": "timer_6_flow_percent",
                                            "name_orig": "Timer 6 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 40,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 475,
                                            "name": "timer_6_temperature_c",
                                            "name_orig": "Timer 6 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 6"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Schedule 7",
                                "type": "category",
                                "id": "39db99aa-a62f-4b53-97e6-8015e73ace87",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 476,
                                            "name": "timer_7_days",
                                            "name_orig": "Timer 7 days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 127,
                                            "res": 1,
                                            "default": 127,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 477,
                                            "name": "timer_7_flags",
                                            "name_orig": "Timer 7 flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ],
                                            "options": [
                                                {
                                                    "name": "standard_ventilation",
                                                    "name_orig": "Standard Ventilation",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 478,
                                            "name": "timer_7_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 7 start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 479,
                                            "name": "timer_7_duration_minutes",
                                            "name_orig": "Timer 7 duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 1439,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 480,
                                            "name": "timer_7_flow_percent",
                                            "name_orig": "Timer 7 flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 40,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 481,
                                            "name": "timer_7_temperature_c",
                                            "name_orig": "Timer 7 temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 19,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Schedule 7"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Night Cooling",
                                "type": "category",
                                "id": "2c8b7b4b-5b44-4855-b77f-41691132154f",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 482,
                                            "name": "timer_8_night_cool_days",
                                            "name_orig": "Timer 8 (night cool) days",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 127,
                                            "max": 127,
                                            "res": 1,
                                            "default": 127,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ],
                                            "options": [
                                                {
                                                    "name": "monday",
                                                    "name_orig": "Monday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "tuesday",
                                                    "name_orig": "Tuesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "wednesday",
                                                    "name_orig": "wednesday",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "thursday",
                                                    "name_orig": "Thursday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "friday",
                                                    "name_orig": "Friday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "saturday",
                                                    "name_orig": "Saturday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "sunday",
                                                    "name_orig": "Sunday",
                                                    "pos": "Activ",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 483,
                                            "name": "timer_8_night_cool_flags",
                                            "name_orig": "Timer 8 (night cool) flags",
                                            "desc": "",
                                            "type": "bitmask",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 18,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ],
                                            "options": [
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "night_cool",
                                                    "name_orig": "Night Cool",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "egg_timer",
                                                    "name_orig": "Egg Timer",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "enabled",
                                                    "name_orig": "Enabled",
                                                    "pos": "True",
                                                    "neg": "False"
                                                },
                                                {
                                                    "name": "unused",
                                                    "name_orig": "unused",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                },
                                                {
                                                    "name": "internal_flag",
                                                    "name_orig": "internal flag",
                                                    "pos": "Active",
                                                    "neg": "Inactive"
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 484,
                                            "name": "timer_8_night_cool_start_timer_minutes_since_midnight",
                                            "name_orig": "Timer 8 (night cool) start timer minutes since midnight",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 485,
                                            "name": "timer_8_night_cool_duration_minutes",
                                            "name_orig": "Timer 8 (night cool) duration minutes",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 360,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 486,
                                            "name": "timer_8_night_cool_flow_percent",
                                            "name_orig": "Timer 8 (night cool) flow %",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 487,
                                            "name": "timer_8_night_cool_temperature_c",
                                            "name_orig": "Timer 8 (night cool) temperature C",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 8,
                                            "max": 50,
                                            "res": 1,
                                            "default": 16,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Operation",
                                                "Timer",
                                                "Night Cooling"
                                            ]
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Room Details",
                        "type": "category",
                        "id": "ccb2728c-1cb4-4044-aca4-32f9843c1f5a",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 549,
                                    "name": "adaptive_airflow_throw_setpoint",
                                    "name_orig": "Adaptive Airflow Throw Setpoint",
                                    "desc": "The setpoint is typically the distance between the inlet diffuser and the opposite wall.\nThe throw will automatically be adjusted to the setpoint value.",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "m",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Room Details"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 669,
                                    "name": "room_volume",
                                    "name_orig": "Room Volume",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 3000,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "m3",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "Room Details"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "TVOC",
                        "type": "category",
                        "id": "10c58ed0-9e38-446a-bb2f-7d81f05f1bb2",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 664,
                                    "name": "r_tvocmin",
                                    "name_orig": "R_TVOCmin",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 65,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 665,
                                    "name": "r_tvocmax",
                                    "name_orig": "R_TVOCmax",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 220,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 668,
                                    "name": "f_tvoc",
                                    "name_orig": "F_TVOC",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 15360,
                                    "showInTechnical": true,
                                    "unit": "m3/h / mg/h",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 672,
                                    "name": "tvoc_n_avg_now",
                                    "name_orig": "TVOC n avg now",
                                    "desc": "",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 673,
                                    "name": "tvoc_n_avg_bw",
                                    "name_orig": "TVOC n avg bw",
                                    "desc": "",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 675,
                                    "name": "tvoc_control_mode",
                                    "name_orig": "TVOC Control Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Level",
                                        "2": "RateOfChange",
                                        "3": "Combined"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 683,
                                    "name": "tvoc_start",
                                    "name_orig": "TVOC Start",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 220,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Operation",
                                        "TVOC"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Factory",
                "type": "category",
                "id": "f16def67-f07f-4d2d-9ac3-625a28b2c8cc",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "Loop Test",
                        "type": "category",
                        "id": "030dad48-c526-4a00-9e29-6602b2e651de",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 6,
                                    "name": "loop1",
                                    "name_orig": "Loop1",
                                    "desc": "Which output to cycle through in Loop1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ],
                                    "options": {
                                        "0": "Bp",
                                        "1": "ExtP",
                                        "2": "Fan"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 18,
                                    "name": "loop2",
                                    "name_orig": "Loop2",
                                    "desc": "Which output to cycle through in Loop2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ],
                                    "options": {
                                        "0": "Bp",
                                        "1": "ExtP",
                                        "2": "Fan"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 35,
                                    "name": "loop3",
                                    "name_orig": "Loop3",
                                    "desc": "Which output to cycle through in Loop3",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ],
                                    "options": {
                                        "0": "Bp",
                                        "1": "ExtP",
                                        "2": "Fan"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 36,
                                    "name": "measuring_time",
                                    "name_orig": "Measuring Time",
                                    "desc": "Measuring Time (Inclusive setteling time)",
                                    "type": "int",
                                    "min": 1,
                                    "max": 255,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 37,
                                    "name": "bp_initial",
                                    "name_orig": "BP Initial",
                                    "desc": "Initial By-pass position",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 42,
                                    "name": "bp_final",
                                    "name_orig": "BP Final",
                                    "desc": "Final By-pass position (only if number of cycles > 1)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 43,
                                    "name": "bp_cycles",
                                    "name_orig": "BP Cycles",
                                    "desc": "Number of cycles through the By-pass position loop",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 44,
                                    "name": "extp_initial",
                                    "name_orig": "ExtP Initial",
                                    "desc": "Initial External Pressure Actuator Position",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 56,
                                    "name": "extp_final",
                                    "name_orig": "ExtP Final",
                                    "desc": "Final External Pressure Actuator Position (only if number of cycles > 1)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 88,
                                    "name": "extp_cycles",
                                    "name_orig": "ExtP Cycles",
                                    "desc": "Number of cycles through the External Pressure actuator positon loop",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 89,
                                    "name": "fan_initial",
                                    "name_orig": "Fan Initial",
                                    "desc": "Initial Fan % (both fans. % according to max motor voltage)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 97,
                                    "name": "fan_final",
                                    "name_orig": "Fan Final",
                                    "desc": "Final Fan % (only if number of cycles > 1)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 98,
                                    "name": "fan_cycles",
                                    "name_orig": "Fan Cycles",
                                    "desc": "Number of cycles through the Fan % loop",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 499,
                                    "name": "start_looptest",
                                    "name_orig": "Start Looptest",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Loop Test"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "General",
                        "type": "category",
                        "id": "dca5b40d-b221-4de9-a94f-1f85e480f515",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 127,
                                    "name": "test_program_time",
                                    "name_orig": "Test Program Time",
                                    "desc": "Time between steps in the factory test procedure",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 362,
                                    "name": "service_address",
                                    "name_orig": "Service Address",
                                    "desc": "sets the info shown on the AQD-P displays",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 13,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Airmaster AS",
                                        "2": "Airmaster AB",
                                        "3": "Airmaster Ltd.",
                                        "4": "Airmaster AS",
                                        "5": "Helios Ventilateurs",
                                        "6": "Wesco AG",
                                        "7": "Airmaster B.V.",
                                        "8": "SAV Systems Ltd.",
                                        "9": "Airflow Lufttechnik GmbH",
                                        "10": "Johann WERNIG KG",
                                        "11": "Sandviken Center -ventilasjonsservice AS",
                                        "12": "Airmaster AS Norge",
                                        "13": "AT-Air OY"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 437,
                                    "name": "rtc_prescaler",
                                    "name_orig": "RTC Prescaler",
                                    "desc": "RTC-Prescaler Value from Production-Test",
                                    "type": "int",
                                    "min": 500,
                                    "max": 1000,
                                    "res": 1,
                                    "default": 764,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 489,
                                    "name": "rtc_calibration",
                                    "name_orig": "RTC-Calibration",
                                    "desc": "RTC-Calibration Value from Production-Test",
                                    "type": "int",
                                    "min": -32768,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 498,
                                    "name": "start_test_procedure",
                                    "name_orig": "Start Test Procedure",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 576,
                                    "name": "pcb_number",
                                    "name_orig": "PCB Number",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 30000,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "Unknown",
                                        "843": "JE843",
                                        "861": "JE861",
                                        "862": "JE862",
                                        "865": "JE865",
                                        "892": "JE892",
                                        "901": "JE901"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 577,
                                    "name": "pcb_revision",
                                    "name_orig": "PCB Revision",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 20,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "Unknown",
                                        "1": "A",
                                        "2": "B",
                                        "3": "C",
                                        "4": "D",
                                        "5": "E",
                                        "6": "F",
                                        "7": "G",
                                        "8": "H",
                                        "9": "I",
                                        "10": "J",
                                        "11": "K",
                                        "12": "L",
                                        "13": "M",
                                        "14": "N",
                                        "15": "O",
                                        "16": "P",
                                        "17": "Q",
                                        "18": "R",
                                        "19": "S",
                                        "20": "T"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 614,
                                    "name": "screensaver_company_logo_id",
                                    "name_orig": "Screensaver Company Logo ID",
                                    "desc": "Defines which company logo to use in screensaver",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 4,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Airmaster",
                                        "2": "Itho",
                                        "3": "Wernig",
                                        "4": "Airflow"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 662,
                                    "name": "standby_power",
                                    "name_orig": "Standby Power",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 300,
                                    "res": 0.01,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "W",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 688,
                                    "name": "co2_calibration",
                                    "name_orig": "CO2 Calibration",
                                    "desc": "To calibrate digital CO2 sensor, first set to zero, then set to the current CO2 level. Note: Sensirion recommends 6 minutes of runtime before doing calibration",
                                    "type": "int",
                                    "min": 0,
                                    "max": 2000,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "General"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Debug",
                        "type": "category",
                        "id": "3fbd93da-dffb-459e-bd6e-678b782a29da",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 420,
                                    "name": "normal_powerup_count",
                                    "name_orig": "Normal Powerup Count",
                                    "desc": "",
                                    "type": "int",
                                    "min": -32768,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 421,
                                    "name": "watchdog_powerup_count",
                                    "name_orig": "Watchdog Powerup Count",
                                    "desc": "",
                                    "type": "int",
                                    "min": -32768,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 422,
                                    "name": "hardfault_count",
                                    "name_orig": "Hardfault Count",
                                    "desc": "",
                                    "type": "int",
                                    "min": -32768,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 643,
                                    "name": "self_test_tacho_alarm_count",
                                    "name_orig": "Self test tacho alarm count",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 9999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 644,
                                    "name": "self_test_tacho_warning_count",
                                    "name_orig": "Self test tacho warning count",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 9999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 645,
                                    "name": "self_test_differential_pressure_alarm_count",
                                    "name_orig": "Self test differential pressure alarm count",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 9999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 661,
                                    "name": "flash_write_error_count",
                                    "name_orig": "Flash Write error count",
                                    "desc": "",
                                    "type": "int",
                                    "min": -32768,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Factory",
                                        "Debug"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "BMS",
                "type": "category",
                "id": "a7d9c961-502b-4e8c-9350-ca358743e4e0",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "Analog",
                        "type": "category",
                        "id": "26ec8f6e-e76a-44fa-ada7-0d222d8f7690",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 8,
                                    "name": "temp_0_2v",
                                    "name_orig": "Temp 0-2V",
                                    "desc": "CTS &#176;C inlet air 0 – 2 V (&#176;C decided from CTS)",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 1,
                                    "default": 19,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 9,
                                    "name": "temp_2_4v",
                                    "name_orig": "Temp 2-4V",
                                    "desc": "CTS &#176;C inlet air 2 – 4 V (&#176;C decided from CTS)",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 1,
                                    "default": 20,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 10,
                                    "name": "temp_4_6v",
                                    "name_orig": "Temp 4-6V",
                                    "desc": "CTS &#176;C inlet air 4 – 6 V (&#176;C decided from CTS)",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 1,
                                    "default": 21,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 11,
                                    "name": "temp_6_8v",
                                    "name_orig": "Temp 6-8V",
                                    "desc": "CTS &#176;C inlet air 6 – 8 V (&#176;C decided from CTS)",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 1,
                                    "default": 22,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 12,
                                    "name": "temp_8_10v",
                                    "name_orig": "Temp 8-10V",
                                    "desc": "CTS &#176;C inlet air 8 – 10 V (&#176;C decided from CTS)",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 1,
                                    "default": 23,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 413,
                                    "name": "a_bms_defined_temperature",
                                    "name_orig": "A-BMS Defined Temperature",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Analog"
                                    ],
                                    "options": {
                                        "0": "Disabled",
                                        "1": "A-BMS",
                                        "2": "General"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Digital",
                        "type": "category",
                        "id": "b5af6bcf-a19f-41df-8365-170ce224b90c",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 45,
                                    "name": "allow_start_by_external_start",
                                    "name_orig": "Allow Start by External Start",
                                    "desc": "Start by External Start, also requires that External Start is a start signal",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 400,
                                    "name": "d_bms_type",
                                    "name_orig": "D-BMS Type",
                                    "desc": "Digital BMS Type",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 6,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Modbus",
                                        "2": "KNX",
                                        "3": "BACnet MS/TP",
                                        "4": "BACnet IP",
                                        "5": "Lon",
                                        "6": "Ethernet"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 402,
                                    "name": "modbus_address",
                                    "name_orig": "Modbus Address",
                                    "desc": "ModBus Slave Address",
                                    "type": "int",
                                    "min": 1,
                                    "max": 247,
                                    "res": 1,
                                    "default": 3,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 403,
                                    "name": "modbus_baud_rate",
                                    "name_orig": "Modbus Baud Rate",
                                    "desc": "ModBus BaudRate",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 5,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "9600",
                                        "1": "19200",
                                        "2": "38400",
                                        "3": "57600",
                                        "4": "115200",
                                        "5": "230400"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 404,
                                    "name": "modbus_parity",
                                    "name_orig": "Modbus Parity",
                                    "desc": "ModBus Parity",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "None (2 Stop bits)",
                                        "1": "Odd (1 Stop bit)",
                                        "2": "Even (1 Stop bit)"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 405,
                                    "name": "bacnet_mstp_address",
                                    "name_orig": "Bacnet MS/TP Address",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 127,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 406,
                                    "name": "bacnet_mstp_baud_rate",
                                    "name_orig": "Bacnet MS/TP Baud Rate",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "9600",
                                        "1": "19200",
                                        "2": "38400",
                                        "3": "76800"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 414,
                                    "name": "allow_start_by_pir",
                                    "name_orig": "Allow Start by PIR",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 415,
                                    "name": "allow_start_by_co2",
                                    "name_orig": "Allow Start by CO2",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 416,
                                    "name": "allow_start_by_timer",
                                    "name_orig": "Allow Start by Timer",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 417,
                                    "name": "allow_start_by_panel",
                                    "name_orig": "Allow Start by Panel",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 493,
                                    "name": "d_bms_airflow",
                                    "name_orig": "D-BMS airflow",
                                    "desc": "Digital BMS airflow %",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 494,
                                    "name": "d_bms_temperature",
                                    "name_orig": "D-BMS temperature",
                                    "desc": "Digital BMS temperature",
                                    "type": "decimal",
                                    "min": 8,
                                    "max": 50,
                                    "res": 0.1,
                                    "default": 19,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 586,
                                    "name": "ethernet_ip",
                                    "name_orig": "Ethernet IP",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 0,
                                    "default": 0,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "dhcp",
                                        "1": "static"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 597,
                                    "name": "ethernet_iots_gateway_port_number",
                                    "name_orig": "Ethernet IOTS Gateway Port Number",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 65535,
                                    "res": 1,
                                    "default": 55556,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 686,
                                    "name": "allow_start_by_tvoc",
                                    "name_orig": "Allow Start by TVOC",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 693,
                                    "name": "allow_start_by_room_temperatur",
                                    "name_orig": "Allow Start by Room Temperatur",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Digital"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Airlinq",
                        "type": "category",
                        "id": "3f29793f-a78b-4dec-9378-d8804febc596",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 250,
                                    "name": "air_handling_units",
                                    "name_orig": "Air Handling Units",
                                    "desc": "Expected number of ventilation units",
                                    "type": "int",
                                    "min": 1,
                                    "max": 20,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 257,
                                    "name": "communication_id",
                                    "name_orig": "Communication ID",
                                    "desc": "The units id on the internal communication bus, 0 = Master, 1-19 = slave",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 258,
                                    "name": "group_id",
                                    "name_orig": "Group ID",
                                    "desc": "Which Group is this unit member of",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 259,
                                    "name": "group_0_master",
                                    "name_orig": "Group 0 Master",
                                    "desc": "Id of groupmaster for Group 0",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 260,
                                    "name": "group_1_master",
                                    "name_orig": "Group 1 Master",
                                    "desc": "Id of groupmaster for Group 1",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 261,
                                    "name": "group_2_master",
                                    "name_orig": "Group 2 Master",
                                    "desc": "Id of groupmaster for Group 2",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 262,
                                    "name": "group_3_master",
                                    "name_orig": "Group 3 Master",
                                    "desc": "Id of groupmaster for Group 3",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 263,
                                    "name": "group_4_master",
                                    "name_orig": "Group 4 Master",
                                    "desc": "Id of groupmaster for Group 4",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 264,
                                    "name": "group_5_master",
                                    "name_orig": "Group 5 Master",
                                    "desc": "Id of groupmaster for Group 5",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 265,
                                    "name": "group_6_master",
                                    "name_orig": "Group 6 Master",
                                    "desc": "Id of groupmaster for Group 6",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 266,
                                    "name": "group_7_master",
                                    "name_orig": "Group 7 Master",
                                    "desc": "Id of groupmaster for Group 7",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 267,
                                    "name": "group_8_master",
                                    "name_orig": "Group 8 Master",
                                    "desc": "Id of groupmaster for Group 8",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 268,
                                    "name": "group_9_master",
                                    "name_orig": "Group 9 Master",
                                    "desc": "Id of groupmaster for Group 9",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 269,
                                    "name": "group_10_master",
                                    "name_orig": "Group 10 Master",
                                    "desc": "Id of groupmaster for Group 10",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 270,
                                    "name": "group_11_master",
                                    "name_orig": "Group 11 Master",
                                    "desc": "Id of groupmaster for Group 11",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 271,
                                    "name": "group_12_master",
                                    "name_orig": "Group 12 Master",
                                    "desc": "Id of groupmaster for Group 12",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 272,
                                    "name": "group_13_master",
                                    "name_orig": "Group 13 Master",
                                    "desc": "Id of groupmaster for Group 13",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 273,
                                    "name": "group_14_master",
                                    "name_orig": "Group 14 Master",
                                    "desc": "Id of groupmaster for Group 14",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 274,
                                    "name": "group_15_master",
                                    "name_orig": "Group 15 Master",
                                    "desc": "Id of groupmaster for Group 15",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 275,
                                    "name": "group_16_master",
                                    "name_orig": "Group 16 Master",
                                    "desc": "Id of groupmaster for Group 16",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 276,
                                    "name": "group_17_master",
                                    "name_orig": "Group 17 Master",
                                    "desc": "Id of groupmaster for Group 17",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 277,
                                    "name": "group_18_master",
                                    "name_orig": "Group 18 Master",
                                    "desc": "Id of groupmaster for Group 18",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 278,
                                    "name": "group_19_master",
                                    "name_orig": "Group 19 Master",
                                    "desc": "Id of groupmaster for Group 19",
                                    "type": "int",
                                    "min": 0,
                                    "max": 19,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "BMS",
                                        "Airlinq"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Hardware Setup",
                "type": "category",
                "id": "a397114b-9a28-4dc7-becb-26c869e369c6",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "Bypass",
                        "type": "category",
                        "id": "e3c08494-2fe5-430e-93ce-0c56d8a0d750",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 13,
                                    "name": "bp_installed",
                                    "name_orig": "BP Installed",
                                    "desc": "Is a by-pass damper installed on the unit, and what type is it?\n(VC: Voltage Controlled / TC - Time Controlled)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "VC",
                                        "2": "TC"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 14,
                                    "name": "bp_engage",
                                    "name_orig": "BP Engage",
                                    "desc": "Engage Automatic by-pass Control",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 77,
                                    "name": "bp_run_time",
                                    "name_orig": "BP Run Time",
                                    "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 999,
                                    "res": 1,
                                    "default": 60,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 99,
                                    "name": "bp_tc_open",
                                    "name_orig": "BP TC: Open",
                                    "desc": "Open Damper, the direction the actuator rotates, to open the damper",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ],
                                    "options": {
                                        "0": "CW",
                                        "1": "CCW"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 113,
                                    "name": "bp_vc_max_position",
                                    "name_orig": "BP VC: Max Position",
                                    "desc": "Voltage representing max physical by-pass position",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 114,
                                    "name": "bp_tc_cw_rotation",
                                    "name_orig": "BP TC: CW Rotation",
                                    "desc": "Settings to rotate Clock Wise (CW)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 116,
                                    "name": "bp_tc_ccw_rotation",
                                    "name_orig": "BP TC: CCW Rotation",
                                    "desc": "Settings to rotate Counter Clock Wise (CCW)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 126,
                                    "name": "bp_vc_min_position",
                                    "name_orig": "BP VC: Min Position",
                                    "desc": "Voltage representing min physical by-pass position",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 203,
                                    "name": "bp_tc_self_calibration",
                                    "name_orig": "BP TC: Self Calibration",
                                    "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
                                    "type": "int",
                                    "min": 0,
                                    "max": 30000,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 230,
                                    "name": "bp_tc_cw_offset",
                                    "name_orig": "BP TC: CW Offset",
                                    "desc": "TC:  CW Offset [ms]. Calibration of time for CW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
                                    "type": "int",
                                    "min": -999,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ms",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 231,
                                    "name": "bp_tc_ccw_offset",
                                    "name_orig": "BP TC: CCW Offset",
                                    "desc": "TC:  CCW Offset [ms]. Calibration of time for CCW rotation  (Designed for compensation for \"inertia\" in the damper+actuator)",
                                    "type": "int",
                                    "min": -999,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ms",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Bypass"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "CO2",
                        "type": "category",
                        "id": "73da30fd-8397-4e18-a42f-9220f781be27",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 30,
                                    "name": "co2_sensor_installed",
                                    "name_orig": "CO2 Sensor Installed",
                                    "desc": "CO2 sensor installed (Mode for overwriting setpoint)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "CO2"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 122,
                                    "name": "co2_sensor_ppm_range_min",
                                    "name_orig": "CO2 Sensor ppm Range Min",
                                    "desc": "Minimum CO2 concentration for the CO2 sensor",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 50,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 123,
                                    "name": "co2_sensor_ppm_range_max",
                                    "name_orig": "CO2 Sensor ppm Range Max",
                                    "desc": "Maximum CO2 concentration for the CO2 sensor",
                                    "type": "int",
                                    "min": 0,
                                    "max": 5000,
                                    "res": 50,
                                    "default": 2000,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 124,
                                    "name": "co2_sensor_volt_range_min",
                                    "name_orig": "CO2 Sensor Volt Range Min",
                                    "desc": "Mimum Volt for the CO2 sensor",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "CO2"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 125,
                                    "name": "co2_sensor_volt_range_max",
                                    "name_orig": "CO2 Sensor Volt Range Max",
                                    "desc": "Maximum Volt for the CO2 sensor",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "CO2"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Humidity",
                        "type": "category",
                        "id": "3791a3ea-53f4-4046-9187-c77d5b18ea68",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 48,
                                    "name": "humidity_control_installed",
                                    "name_orig": "Humidity Control Installed",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Humidity"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 687,
                                    "name": "rh_room_sensor_installed",
                                    "name_orig": "RH Room Sensor Installed",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Humidity"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Fan",
                        "type": "category",
                        "id": "8d4f5df6-b4b1-4309-88a1-b8861bce5f6b",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 59,
                                    "name": "min_fan_voltage",
                                    "name_orig": "Min Fan Voltage",
                                    "desc": "Minimum allowed fan speed",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 1.2,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 115,
                                    "name": "max_volt_sf",
                                    "name_orig": "Max Volt SF",
                                    "desc": "Supply Fan maximum volt",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 117,
                                    "name": "max_volt_ef",
                                    "name_orig": "Max Volt EF",
                                    "desc": "Exhaust Fan maximum volt",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 204,
                                    "name": "min_volt_sf",
                                    "name_orig": "Min Volt SF",
                                    "desc": "Supply Fan minimum volt",
                                    "type": "decimal",
                                    "min": -10,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 207,
                                    "name": "min_volt_ef",
                                    "name_orig": "Min Volt EF",
                                    "desc": "Exhaust Fan minimum volt",
                                    "type": "decimal",
                                    "min": -10,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 821,
                                    "name": "sf_ppr",
                                    "name_orig": "SF PPR",
                                    "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
                                    "type": "int",
                                    "min": 1,
                                    "max": 99,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "Pulses/Rev.",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 822,
                                    "name": "ef_ppr",
                                    "name_orig": "EF PPR",
                                    "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
                                    "type": "int",
                                    "min": 1,
                                    "max": 99,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "Pulses/Rev.",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 823,
                                    "name": "sf_model",
                                    "name_orig": "SF Model",
                                    "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
                                    "type": "int",
                                    "min": 0,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 824,
                                    "name": "ef_model",
                                    "name_orig": "EF Model",
                                    "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
                                    "type": "int",
                                    "min": 0,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Fan"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Heating Surfaces",
                        "type": "category",
                        "id": "1870845e-ce8d-41a0-9229-d88c3464023c",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 85,
                                    "name": "comfort_heater",
                                    "name_orig": "Comfort Heater",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Heating Surfaces"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Electric",
                                        "2": "Water"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 232,
                                    "name": "pre_heater",
                                    "name_orig": "Pre Heater",
                                    "desc": "Preheater installed/off",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Heating Surfaces"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Electric"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 363,
                                    "name": "vph_options",
                                    "name_orig": "VPH Options",
                                    "desc": "Virtual Pre Heater Options",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Heating Surfaces"
                                    ],
                                    "options": {
                                        "0": "N/A",
                                        "1": "Electric",
                                        "2": "Water",
                                        "3": "Electric or Water"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 676,
                                    "name": "electric_heater_afterrun",
                                    "name_orig": "Electric Heater Afterrun",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 600,
                                    "res": 1,
                                    "default": 60,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Heating Surfaces"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Comfort Cooling",
                        "type": "category",
                        "id": "61f9a116-c495-4e91-8617-20a7a816c72b",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Stepper Valve",
                                "type": "category",
                                "id": "1a0c473c-f8d1-44a5-b592-73394e2b3658",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 134,
                                            "name": "sv_total_steps",
                                            "name_orig": "SV Total Steps",
                                            "desc": "Danfoss ETS 6: Full steps = 240, 1/2 steps = 480, 1/4 steps = 960 etc.",
                                            "type": "int",
                                            "min": 1,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 480,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 135,
                                            "name": "sv_calibration_steps",
                                            "name_orig": "SV Calibration Steps",
                                            "desc": "normally total steps + 10-50steps (Danfoss ETS 6: Full Steps = 255, 1/2 Steps = 510, 1/4 Steps = 1020 etc.)",
                                            "type": "int",
                                            "min": 1,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 510,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 136,
                                            "name": "sv_moves_between_zeroing",
                                            "name_orig": "SV Moves Between Zeroing",
                                            "desc": "0 = disabled",
                                            "type": "int",
                                            "min": 0,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 137,
                                            "name": "sv_steps_per_second",
                                            "name_orig": "SV Steps Per Second",
                                            "desc": "",
                                            "type": "int",
                                            "min": 1,
                                            "max": 1000,
                                            "res": 1,
                                            "default": 30,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 138,
                                            "name": "sv_step_size",
                                            "name_orig": "SV Step Size",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 1,
                                            "max": 8,
                                            "res": 1,
                                            "default": 2,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ],
                                            "options": {
                                                "1": "Full Step",
                                                "2": "1/2 Step",
                                                "3": "1/4 Step",
                                                "4": "8 Microsteps",
                                                "5": "16 Microsteps",
                                                "6": "32 Microsteps",
                                                "7": "Full Step with power off",
                                                "8": "1/2 Step with power off"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 139,
                                            "name": "sv_open_direction_reversed",
                                            "name_orig": "SV Open Direction Reversed",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Stepper Valve"
                                            ],
                                            "options": {
                                                "0": "No",
                                                "1": "Yes"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Settings",
                                "type": "category",
                                "id": "7e4dfc32-0f69-4290-9ac4-abe2f39f58d0",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 335,
                                            "name": "cc_mode",
                                            "name_orig": "CC Mode",
                                            "desc": "Comfort Cool Mode",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 4,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "Off",
                                                "1": "Internal",
                                                "2": "External",
                                                "3": "Inverter",
                                                "4": "Water"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 831,
                                            "name": "cc_condensate_severity",
                                            "name_orig": "CC Condensate Severity",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "Warning",
                                                "1": "Alarm"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "CC Water",
                                "type": "category",
                                "id": "0fa5cbf2-ccf8-4f10-bd64-45f7c2da3771",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 832,
                                            "name": "mode",
                                            "name_orig": "Mode",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ],
                                            "options": {
                                                "0": "TC",
                                                "1": "VC"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 833,
                                            "name": "tc_runtime",
                                            "name_orig": "TC: Runtime",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 999,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 834,
                                            "name": "tc_open",
                                            "name_orig": "TC: Open",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ],
                                            "options": {
                                                "0": "CW",
                                                "1": "CCW"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 835,
                                            "name": "tc_cw_rotation",
                                            "name_orig": "TC: CW Rotation",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 2,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ],
                                            "options": {
                                                "0": "L2",
                                                "1": "L3",
                                                "2": "L2+L3"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 836,
                                            "name": "tc_ccw_rotation",
                                            "name_orig": "TC: CCW Rotation",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 2,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ],
                                            "options": {
                                                "0": "L2",
                                                "1": "L3",
                                                "2": "L2+L3"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 837,
                                            "name": "tc_self_calibration",
                                            "name_orig": "TC: Self Calibration",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 838,
                                            "name": "tc_cw_offset",
                                            "name_orig": "TC: CW Offset",
                                            "desc": "",
                                            "type": "int",
                                            "min": -999,
                                            "max": 999,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 839,
                                            "name": "tc_ccw_offset",
                                            "name_orig": "TC: CCW Offset",
                                            "desc": "",
                                            "type": "int",
                                            "min": -999,
                                            "max": 999,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 840,
                                            "name": "vc_min_position",
                                            "name_orig": "VC: Min Position",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 841,
                                            "name": "vc_max_position",
                                            "name_orig": "VC: Max Position",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 842,
                                            "name": "engage",
                                            "name_orig": "Engage",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Hardware Setup",
                                                "Comfort Cooling",
                                                "CC Water"
                                            ],
                                            "options": {
                                                "0": "False",
                                                "1": "True"
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Main Damper",
                        "type": "category",
                        "id": "113b6d2f-4722-4718-9918-5478342029c2",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 209,
                                    "name": "md_run_time",
                                    "name_orig": "MD Run Time",
                                    "desc": "The time it takes for the MD damper to operate from 0-100%",
                                    "type": "int",
                                    "min": 0,
                                    "max": 999,
                                    "res": 1,
                                    "default": 75,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Main Damper"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 211,
                                    "name": "md_tc_open",
                                    "name_orig": "MD TC: Open",
                                    "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Main Damper"
                                    ],
                                    "options": {
                                        "0": "CW",
                                        "1": "CCW"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 220,
                                    "name": "md_tc_cw_rot",
                                    "name_orig": "MD TC: CW Rot.",
                                    "desc": "Settings to rotate Clock Wise (CW)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Main Damper"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 221,
                                    "name": "md_tc_ccw_rot",
                                    "name_orig": "MD TC: CCW Rot.",
                                    "desc": "Settings to rotate Counter Clock Wise (CCW)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Main Damper"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 233,
                                    "name": "md_mode",
                                    "name_orig": "MD Mode",
                                    "desc": "\"Self Return\" covers both electrical and mechanical \"spring\" return... i.e. it is a \"Power off reverse function\".",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Main Damper"
                                    ],
                                    "options": {
                                        "0": "Off",
                                        "1": "Time Controlled",
                                        "2": "Self Return"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Power Meter",
                        "type": "category",
                        "id": "5955f824-907d-4f99-b6a4-1ed01d9805c2",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 433,
                                    "name": "air_handling_unit_pulseskwh",
                                    "name_orig": "Air Handling Unit, Pulses/kWh",
                                    "desc": "EM 10: 1-phase = 1000 pulses/kWh \nEM 23: 3-phase = 100 pulses/kWh",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 1000,
                                    "showInTechnical": true,
                                    "unit": "Pulses/kWh",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Power Meter"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 435,
                                    "name": "comfort_cooling_pulseskwh",
                                    "name_orig": "Comfort Cooling, Pulses/kWh",
                                    "desc": "",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 1000,
                                    "showInTechnical": true,
                                    "unit": "Pulses/kWh",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Power Meter"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Adaptive Airflow",
                        "type": "category",
                        "id": "febd3f58-9dca-41e6-8180-c82407418829",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 550,
                                    "name": "aa_narrow_a",
                                    "name_orig": "AA Narrow A",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -64992,
                                    "max": 64992,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 551,
                                    "name": "aa_narrow_b",
                                    "name_orig": "AA Narrow B",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -64992,
                                    "max": 64992,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 552,
                                    "name": "aa_wide_a",
                                    "name_orig": "AA Wide A",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -64992,
                                    "max": 64992,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 553,
                                    "name": "aa_wide_b",
                                    "name_orig": "AA Wide B",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -64992,
                                    "max": 64992,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 554,
                                    "name": "aa_wide_position",
                                    "name_orig": "AA Wide Position",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 555,
                                    "name": "aa_narrow_position",
                                    "name_orig": "AA Narrow Position",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Adaptive Airflow"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Status LED",
                        "type": "category",
                        "id": "6b7655ea-a1eb-4af8-9841-f89a17a3422c",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 617,
                                    "name": "sl_enabled_time_periode_start_minutes_since_midnight",
                                    "name_orig": "SL Enabled Time Periode Start (minutes since midnight)",
                                    "desc": "Start time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
                                    "type": "int",
                                    "min": 0,
                                    "max": 1440,
                                    "res": 1,
                                    "default": 420,
                                    "showInTechnical": true,
                                    "unit": "min.",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Status LED"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 618,
                                    "name": "sl_enabled_time_periode_end_minutes_since_midnight",
                                    "name_orig": "SL Enabled Time Periode End (minutes since midnight)",
                                    "desc": "End time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
                                    "type": "int",
                                    "min": 0,
                                    "max": 1440,
                                    "res": 1,
                                    "default": 1320,
                                    "showInTechnical": true,
                                    "unit": "min.",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Status LED"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 619,
                                    "name": "sl_state_during_normal_operation",
                                    "name_orig": "SL State During Normal Operation",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "Status LED"
                                    ],
                                    "options": {
                                        "0": "Off",
                                        "1": "On"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "TVOC",
                        "type": "category",
                        "id": "a6870003-d36b-4bfc-ab4a-17b150869e39",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 684,
                                    "name": "tvoc_sensor_installed",
                                    "name_orig": "TVOC Sensor Installed",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "TVOC"
                                    ],
                                    "options": {
                                        "0": "No",
                                        "1": "Yes"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "PIR",
                        "type": "category",
                        "id": "23e2bc65-af81-4a90-8558-70752c0b051e",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 694,
                                    "name": "pir_1_mode",
                                    "name_orig": "PIR 1 Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "PIR"
                                    ],
                                    "options": {
                                        "0": "Auto",
                                        "1": "Active Low",
                                        "2": "Active High"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 695,
                                    "name": "pir_2_mode",
                                    "name_orig": "PIR 2 Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "PIR"
                                    ],
                                    "options": {
                                        "0": "Auto",
                                        "1": "Active Low",
                                        "2": "Active High"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 696,
                                    "name": "pir_3_mode",
                                    "name_orig": "PIR 3 Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Hardware Setup",
                                        "PIR"
                                    ],
                                    "options": {
                                        "0": "Auto",
                                        "1": "Active Low",
                                        "2": "Active High"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Process Settings",
                "type": "category",
                "id": "a89d44a9-1c4f-4bde-ab12-73120213d15c",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "High Temp",
                        "type": "category",
                        "id": "9352ac7a-959d-4ba1-b153-21e20e62338e",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 15,
                                    "name": "temp_diff",
                                    "name_orig": "Temp Diff",
                                    "desc": "By – pass &#176;C difference  (Temperature difference for starting by-pass)",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.5,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 16,
                                    "name": "min_temp",
                                    "name_orig": "Min Temp",
                                    "desc": "By – pass minimum outside &#176;C (Minimum outside &#176;C to start by-pass)",
                                    "type": "decimal",
                                    "min": -10,
                                    "max": 25,
                                    "res": 0.5,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 17,
                                    "name": "ht_rt_flow_increment",
                                    "name_orig": "HT RT Flow Increment",
                                    "desc": "High Temp Room Temp increment",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 19,
                                    "name": "t_measure",
                                    "name_orig": "T Measure",
                                    "desc": "By - pass mean measuring (Time measuring before acting)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 20,
                                    "name": "interval",
                                    "name_orig": "Interval",
                                    "desc": "By-pass damper interval – open and close",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 21,
                                    "name": "wait",
                                    "name_orig": "Wait",
                                    "desc": "Wait time after regulating by-pass damper",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 15,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 24,
                                    "name": "high_room_temperature_high_limit",
                                    "name_orig": "High Room Temperature, High Limit",
                                    "desc": "High Room Temperature. Increase airflow and lower inlet temperature",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 25,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 25,
                                    "name": "high_room_temperature_low_limit",
                                    "name_orig": "High Room Temperature, Low Limit",
                                    "desc": "Low Room Temperature. Set normal airflow and set normal inlet temperature",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 24,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 26,
                                    "name": "bp_fan_cor",
                                    "name_orig": "BP Fan Cor",
                                    "desc": "",
                                    "type": "int",
                                    "min": -99,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 28,
                                    "name": "rt_timer",
                                    "name_orig": "RT Timer",
                                    "desc": "RT Timer. Wait state when software changes Inlet Temperature",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 30,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 29,
                                    "name": "it_hyst",
                                    "name_orig": "IT Hyst.",
                                    "desc": "Hysteresis for regulating damper up/down due to low/high inlet temperature",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 31,
                                    "name": "rt_diff_high",
                                    "name_orig": "RT Diff High",
                                    "desc": "High temp: Necessary difference between room temp and inlet temp before forcing 100% ventilations level",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 32,
                                    "name": "rt_diff_low",
                                    "name_orig": "RT Diff Low",
                                    "desc": "High temp: Minimum difference between room temp and inlet temp before resetting to normal ventilation level.",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 33,
                                    "name": "ot_rt_diff",
                                    "name_orig": "OT-RT Diff",
                                    "desc": "High temp: Max difference between outside temp and room temp before decreasing by-pass damper opening",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 34,
                                    "name": "exit_timer",
                                    "name_orig": "Exit Timer",
                                    "desc": "High temp: Shutdown timer. Need to run out before closing by-pass damper, when damper level reaches 0V",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 598,
                                    "name": "it_hysteresis_for_cc_re_init",
                                    "name_orig": "IT Hysteresis for CC re-init",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 620,
                                    "name": "bp_fan_cor_a_coefficient",
                                    "name_orig": "BP Fan Cor A Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 621,
                                    "name": "bp_fan_cor_b_coefficient",
                                    "name_orig": "BP Fan Cor B Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 622,
                                    "name": "bp_fan_cor_c_coefficient",
                                    "name_orig": "BP Fan Cor C Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 623,
                                    "name": "bp_fan_cor_low_limit",
                                    "name_orig": "BP Fan Cor Low Limit",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 624,
                                    "name": "bp_fan_cor_high_limit",
                                    "name_orig": "BP Fan Cor High Limit",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 60,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 674,
                                    "name": "bp_location",
                                    "name_orig": "BP Location",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ],
                                    "options": {
                                        "0": "Supply",
                                        "1": "Extraction"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 691,
                                    "name": "rt_start_offset",
                                    "name_orig": "RT Start Offset",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "High Temp"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Comfort Cooling",
                        "type": "category",
                        "id": "1e7ef4cd-cf96-4abf-81f4-a23ed1f6643c",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Settings",
                                "type": "category",
                                "id": "07225ec9-d199-41c1-864e-2c3622a05c1f",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 23,
                                            "name": "cool_flow",
                                            "name_orig": "Cool Flow",
                                            "desc": "Minimum flow when activating cooling unit",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 336,
                                            "name": "cc_defrost_exit_temp",
                                            "name_orig": "CC Defrost Exit Temp",
                                            "desc": "Temperature that causes comfort cool to exit defrost mode",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 50,
                                            "res": 1,
                                            "default": 5,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 337,
                                            "name": "cc_min_ot",
                                            "name_orig": "CC Min OT",
                                            "desc": "Comfort Cool minimum outside temperature",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 50,
                                            "res": 1,
                                            "default": 16,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 338,
                                            "name": "cc_cdt_alarm_temp",
                                            "name_orig": "CC CDT Alarm Temp",
                                            "desc": "Comfort Cool Condenser alarm temperature",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 100,
                                            "res": 1,
                                            "default": 60,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 339,
                                            "name": "cc_cdt_warning_temp",
                                            "name_orig": "CC CDT Warning Temp",
                                            "desc": "Comfort Cool Condenser warning temperature",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 100,
                                            "res": 1,
                                            "default": 58,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 341,
                                            "name": "cc_defrost_warning_time",
                                            "name_orig": "CC Defrost Warning Time",
                                            "desc": "Comfort Cool defrost time before setting warning",
                                            "type": "int",
                                            "min": 0,
                                            "max": 480,
                                            "res": 1,
                                            "default": 120,
                                            "showInTechnical": true,
                                            "unit": "min",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 342,
                                            "name": "cc_compressor_wait_time",
                                            "name_orig": "CC Compressor Wait Time",
                                            "desc": "Comfort Cool time to wait between stop and start of compressor",
                                            "type": "int",
                                            "min": 0,
                                            "max": 30,
                                            "res": 1,
                                            "default": 3,
                                            "showInTechnical": true,
                                            "unit": "min",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 343,
                                            "name": "cc_condensation_time",
                                            "name_orig": "CC Condensation Time",
                                            "desc": "Comfort Cool time delay before setting condensation warning",
                                            "type": "int",
                                            "min": 0,
                                            "max": 600,
                                            "res": 1,
                                            "default": 60,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 542,
                                            "name": "cc_compressor_max_time_between_master_messages",
                                            "name_orig": "CC Compressor Max Time Between Master Messages",
                                            "desc": "",
                                            "type": "int",
                                            "min": 2,
                                            "max": 300,
                                            "res": 1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 640,
                                            "name": "control_algorithm_cooling",
                                            "name_orig": "Control Algorithm, Cooling",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Comfort Cooling",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "PID0",
                                                "1": "PID1"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Inverter",
                                "type": "category",
                                "id": "9484afc6-5b21-4fca-adf3-39d087728e11",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "name": "Settings",
                                        "type": "category",
                                        "id": "1b738dea-f1ac-4f53-afee-755f26099ede",
                                        "isCollapsed": true,
                                        "isVisibleToTechnicalBasic": true,
                                        "children": [
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 130,
                                                    "name": "cc_icc_otv_rqt_temp_difference",
                                                    "name_orig": "CC ICC OTV RQT Temp Difference",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 10,
                                                    "res": 0.1,
                                                    "default": 2,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 131,
                                                    "name": "cc_icc_min_cooling_time",
                                                    "name_orig": "CC ICC Min Cooling Time",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 240,
                                                    "res": 1,
                                                    "default": 2,
                                                    "showInTechnical": true,
                                                    "unit": "min",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 132,
                                                    "name": "cc_icc_temperature_timer",
                                                    "name_orig": "CC ICC Temperature Timer",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 240,
                                                    "res": 1,
                                                    "default": 60,
                                                    "showInTechnical": true,
                                                    "unit": "min",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 161,
                                                    "name": "cc_icc_mode",
                                                    "name_orig": "CC ICC Mode",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling Mode",
                                                    "type": "enum",
                                                    "min": 0,
                                                    "max": 1,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ],
                                                    "options": {
                                                        "0": "Volt",
                                                        "1": "Hz"
                                                    }
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 162,
                                                    "name": "cc_icc_inverter_min_voltage",
                                                    "name_orig": "CC ICC Inverter Min Voltage",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 10,
                                                    "res": 0.1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "V",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 163,
                                                    "name": "cc_icc_inverter_max_voltage",
                                                    "name_orig": "CC ICC Inverter Max Voltage",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 10,
                                                    "res": 0.1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "V",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 164,
                                                    "name": "cc_icc_inverter_min_frequency",
                                                    "name_orig": "CC ICC Inverter Min Frequency",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 10,
                                                    "max": 1000,
                                                    "res": 1,
                                                    "default": 42,
                                                    "showInTechnical": true,
                                                    "unit": "Hz",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 165,
                                                    "name": "cc_icc_inverter_max_frequency",
                                                    "name_orig": "CC ICC Inverter Max Frequency",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 10,
                                                    "max": 1000,
                                                    "res": 1,
                                                    "default": 160,
                                                    "showInTechnical": true,
                                                    "unit": "Hz",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 541,
                                                    "name": "cc_icc_subtemp",
                                                    "name_orig": "CC ICC SubTemp",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 10,
                                                    "res": 0.1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 578,
                                                    "name": "cc_icc_standby_wait_on",
                                                    "name_orig": "CC ICC Standby Wait On",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 600,
                                                    "res": 1,
                                                    "default": 30,
                                                    "showInTechnical": true,
                                                    "unit": "sec",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 579,
                                                    "name": "cc_icc_standby_wait_off",
                                                    "name_orig": "CC ICC Standby Wait Off",
                                                    "desc": "",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 600,
                                                    "res": 1,
                                                    "default": 180,
                                                    "showInTechnical": true,
                                                    "unit": "sec",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Settings"
                                                    ]
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Hot Gas",
                                        "type": "category",
                                        "id": "a5c87a69-845d-4e0f-8046-768d0cad640d",
                                        "isCollapsed": true,
                                        "isVisibleToTechnicalBasic": true,
                                        "children": [
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 133,
                                                    "name": "cc_hg_temperature_max",
                                                    "name_orig": "CC HG Temperature Max",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 100,
                                                    "res": 0.1,
                                                    "default": 60,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 153,
                                                    "name": "cc_hg_pid_kp",
                                                    "name_orig": "CC HG PID KP",
                                                    "desc": "Cooling Controller HotGas, Proportional Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 154,
                                                    "name": "cc_hg_pid_ki",
                                                    "name_orig": "CC HG PID KI",
                                                    "desc": "Cooling Controller HotGas, Integral Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 155,
                                                    "name": "cc_hg_pid_kd",
                                                    "name_orig": "CC HG PID KD",
                                                    "desc": "Cooling Controller HotGas, Differential Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 156,
                                                    "name": "cc_hg_pid_ka",
                                                    "name_orig": "CC HG PID KA",
                                                    "desc": "Cooling Controller HotGas, Anti Windup Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 166,
                                                    "name": "cc_hg_pid_max_out",
                                                    "name_orig": "CC HG PID Max Out",
                                                    "desc": "Cooling Controller HotGas, PID max out",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 100,
                                                    "res": 0.1,
                                                    "default": 10,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 612,
                                                    "name": "hgt_a_coeff",
                                                    "name_orig": "HGT_A_coeff",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": -999.9,
                                                    "max": 999.9,
                                                    "res": 0.1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 613,
                                                    "name": "hgt_b_coeff",
                                                    "name_orig": "HGT_B_coeff",
                                                    "desc": "",
                                                    "type": "decimal",
                                                    "min": -999.9,
                                                    "max": 999.9,
                                                    "res": 0.1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 632,
                                                    "name": "cc_hg_pid_ke",
                                                    "name_orig": "CC HG PID KE",
                                                    "desc": "",
                                                    "type": "enum",
                                                    "min": 0,
                                                    "max": 1,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Hot Gas"
                                                    ],
                                                    "options": {
                                                        "0": "1",
                                                        "1": "RQF/100"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Valve",
                                        "type": "category",
                                        "id": "8faf451d-cb19-4a15-bb5a-3f2eb5603b77",
                                        "isCollapsed": true,
                                        "isVisibleToTechnicalBasic": true,
                                        "children": [
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 140,
                                                    "name": "cc_valve_pid_kp",
                                                    "name_orig": "CC Valve PID KP",
                                                    "desc": "Cooling Controller Valve, Proportional Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 141,
                                                    "name": "cc_valve_pid_ki",
                                                    "name_orig": "CC Valve PID KI",
                                                    "desc": "Cooling Controller Valve, Integral Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 142,
                                                    "name": "cc_valve_pid_kd",
                                                    "name_orig": "CC Valve PID KD",
                                                    "desc": "Cooling Controller Valve, Differential Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 143,
                                                    "name": "cc_valve_pid_ka",
                                                    "name_orig": "CC Valve PID KA",
                                                    "desc": "Cooling Controller Valve, Anti Windup Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 144,
                                                    "name": "cc_valve_pid_min_out",
                                                    "name_orig": "CC Valve PID Min Out",
                                                    "desc": "Cooling Controller Valve, minimum output",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 100,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "%",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 145,
                                                    "name": "cc_valve_pid_max_out",
                                                    "name_orig": "CC Valve PID Max Out",
                                                    "desc": "Cooling Controller Valve, maximum output",
                                                    "type": "int",
                                                    "min": 0,
                                                    "max": 100,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "%",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 146,
                                                    "name": "cc_valve_pid_sample_time",
                                                    "name_orig": "CC Valve PID Sample Time",
                                                    "desc": "Cooling Controller Valve, sample time",
                                                    "type": "int",
                                                    "min": 1,
                                                    "max": 10000,
                                                    "res": 1,
                                                    "default": 10,
                                                    "showInTechnical": true,
                                                    "unit": "ms",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 147,
                                                    "name": "cc_valve_pid_reference",
                                                    "name_orig": "CC Valve PID Reference",
                                                    "desc": "Cooling Controller Valve, reference",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 50,
                                                    "res": 0.1,
                                                    "default": 3,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 630,
                                                    "name": "cc_valve_pid_ke",
                                                    "name_orig": "CC Valve PID KE",
                                                    "desc": "",
                                                    "type": "enum",
                                                    "min": 0,
                                                    "max": 1,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Valve"
                                                    ],
                                                    "options": {
                                                        "0": "1",
                                                        "1": "RQF/100"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Cooling",
                                        "type": "category",
                                        "id": "5a77c3be-54e9-4eb6-b525-286a1975ad5e",
                                        "isCollapsed": true,
                                        "isVisibleToTechnicalBasic": true,
                                        "children": [
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 148,
                                                    "name": "cc_icc_pid_kp",
                                                    "name_orig": "CC ICC PID KP",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling, Proportional Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 149,
                                                    "name": "cc_icc_pid_ki",
                                                    "name_orig": "CC ICC PID KI",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling, Integral Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 150,
                                                    "name": "cc_icc_pid_kd",
                                                    "name_orig": "CC ICC PID KD",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling, Differential Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 151,
                                                    "name": "cc_icc_pid_ka",
                                                    "name_orig": "CC ICC PID KA",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling, Anti Windup Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 152,
                                                    "name": "cc_icc_pid_sample_time",
                                                    "name_orig": "CC ICC PID Sample Time",
                                                    "desc": "Cooling Controller Inverter Controlled Cooling, Sample Time",
                                                    "type": "int",
                                                    "min": 1,
                                                    "max": 10000,
                                                    "res": 1,
                                                    "default": 10,
                                                    "showInTechnical": true,
                                                    "unit": "ms",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 631,
                                                    "name": "cc_icc_pid_ke",
                                                    "name_orig": "CC ICC PID KE",
                                                    "desc": "",
                                                    "type": "enum",
                                                    "min": 0,
                                                    "max": 1,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Cooling"
                                                    ],
                                                    "options": {
                                                        "0": "1",
                                                        "1": "RQF/100"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Evaporator",
                                        "type": "category",
                                        "id": "08505fa9-5b8d-49f3-bf93-12196ab01eb5",
                                        "isCollapsed": true,
                                        "isVisibleToTechnicalBasic": true,
                                        "children": [
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 157,
                                                    "name": "cc_evt_pid_kp",
                                                    "name_orig": "CC EVT PID KP",
                                                    "desc": "Cooling Controller Evaporator Temp, Proportional Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 158,
                                                    "name": "cc_evt_pid_ki",
                                                    "name_orig": "CC EVT PID KI",
                                                    "desc": "Cooling Controller Evaporator Temp, Integral Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 159,
                                                    "name": "cc_evt_pid_kd",
                                                    "name_orig": "CC EVT PID KD",
                                                    "desc": "Cooling Controller Evaporator Temp, Differential Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 160,
                                                    "name": "cc_evt_pid_ka",
                                                    "name_orig": "CC EVT PID KA",
                                                    "desc": "Cooling Controller Evaporator Temp, Anti Windup Gain",
                                                    "type": "decimal",
                                                    "min": -1000,
                                                    "max": 1000,
                                                    "res": 1e-10,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 167,
                                                    "name": "cc_evt_pid_max_out",
                                                    "name_orig": "CC EVT PID Max Out",
                                                    "desc": "Cooling Controller Evaporator, PID max out",
                                                    "type": "decimal",
                                                    "min": 0,
                                                    "max": 100,
                                                    "res": 0.1,
                                                    "default": 10,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 340,
                                                    "name": "cc_evt_min___defrost_temp",
                                                    "name_orig": "CC EVT min - Defrost Temp",
                                                    "desc": "Comfort Cool defrost activation temperature",
                                                    "type": "decimal",
                                                    "min": -20,
                                                    "max": 50,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "°C",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ]
                                                }
                                            },
                                            {
                                                "type": "parameter",
                                                "isVisibleToTechnicalBasic": true,
                                                "data": {
                                                    "id": 633,
                                                    "name": "cc_evt_pid_ke",
                                                    "name_orig": "CC EVT PID KE",
                                                    "desc": "",
                                                    "type": "enum",
                                                    "min": 0,
                                                    "max": 1,
                                                    "res": 1,
                                                    "default": 0,
                                                    "showInTechnical": true,
                                                    "unit": "",
                                                    "guiGroupNameList": [
                                                        "Process Settings",
                                                        "Comfort Cooling",
                                                        "Inverter",
                                                        "Evaporator"
                                                    ],
                                                    "options": {
                                                        "0": "1",
                                                        "1": "RQF/100"
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Condensation",
                        "type": "category",
                        "id": "08ca69b9-83ae-4336-8106-39ca8d2ba896",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 38,
                                    "name": "t_evaporate",
                                    "name_orig": "T Evaporate",
                                    "desc": "Evaporation Time between condensate tests",
                                    "type": "int",
                                    "min": 0,
                                    "max": 60,
                                    "res": 1,
                                    "default": 20,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 39,
                                    "name": "supply_airflow_reduction",
                                    "name_orig": "Supply Airflow Reduction",
                                    "desc": "Condens sensor: % reducing intake airflow (% reducing intake airflow)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 40,
                                    "name": "t_soft_start",
                                    "name_orig": "T Soft Start",
                                    "desc": "Condens sensor: waiting for normal airflow (Time to go before fan 1 run at normal speed after there is no longer condens signal)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 128,
                                    "name": "retries",
                                    "name_orig": "Retries",
                                    "desc": "Number of retries with the interval  \"T Evaporate\"",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 557,
                                    "name": "condensate_frost_protection_on_temperature",
                                    "name_orig": "Condensate Frost Protection On Temperature",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -20,
                                    "max": 20,
                                    "res": 0.1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 558,
                                    "name": "condensate_frost_protection_hysteresis",
                                    "name_orig": "Condensate Frost Protection Hysteresis",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 20,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Condensation"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Filter",
                        "type": "category",
                        "id": "8939478e-1fa0-4825-822b-a8ba11993433",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Settings",
                                "type": "category",
                                "id": "7ca75c83-5758-4d57-9bdc-ab2508292757",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 51,
                                            "name": "filter_test_mode",
                                            "name_orig": "Filter Test Mode",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 4,
                                            "res": 1,
                                            "default": 3,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "Off",
                                                "1": "Timer",
                                                "2": "Tacho",
                                                "3": "Timer And Tacho",
                                                "4": "DP-switch only"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 52,
                                            "name": "life_span_warning",
                                            "name_orig": "Life Span Warning",
                                            "desc": "Time before filter Warning in hours",
                                            "type": "int",
                                            "min": 0,
                                            "max": 8760,
                                            "res": 1,
                                            "default": 1500,
                                            "showInTechnical": true,
                                            "unit": "hour",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 54,
                                            "name": "current_filter_state",
                                            "name_orig": "Current Filter State",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 2,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "green",
                                                "1": "yellow",
                                                "2": "red"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 55,
                                            "name": "life_span_alarm",
                                            "name_orig": "Life Span Alarm",
                                            "desc": "Time before filter Alarm in hours",
                                            "type": "int",
                                            "min": 0,
                                            "max": 8760,
                                            "res": 1,
                                            "default": 2000,
                                            "showInTechnical": true,
                                            "unit": "hour",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 70,
                                            "name": "flow_alarm",
                                            "name_orig": "Flow Alarm",
                                            "desc": "Alarm flow difference (Difference betweed set and actual airflow before flow alarm is set)",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 5,
                                            "default": 20,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 432,
                                            "name": "filter_max_life_time_alarm",
                                            "name_orig": "Filter Max Life Time, Alarm",
                                            "desc": "Alarm is activated when the filter is \"Filter Max Life Time, Alarm\" old. (0 months disables the Filter Max Life Time Alarm)",
                                            "type": "int",
                                            "min": 0,
                                            "max": 48,
                                            "res": 1,
                                            "default": 14,
                                            "showInTechnical": true,
                                            "unit": "month(s)",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 496,
                                            "name": "reset_filter_status",
                                            "name_orig": "Reset Filter Status",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "No",
                                                "1": "Yes"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 497,
                                            "name": "run_filter_calibration",
                                            "name_orig": "Run Filter Calibration",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ],
                                            "options": {
                                                "0": "No",
                                                "1": "Yes"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 543,
                                            "name": "filter_max_life_time_warning",
                                            "name_orig": "Filter Max Life Time, Warning",
                                            "desc": "Warning period in months prior to the alarm.",
                                            "type": "int",
                                            "min": 0,
                                            "max": 12,
                                            "res": 1,
                                            "default": 2,
                                            "showInTechnical": true,
                                            "unit": "month(s)",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Settings"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Self Test",
                                "type": "category",
                                "id": "2b637bc2-eaa5-4852-859c-dcdac1447edd",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 53,
                                            "name": "st_filter_test_max_difference",
                                            "name_orig": "ST Filter Test Max Difference",
                                            "desc": "Max difference in tacho measurement compared with last test",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 20,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 73,
                                            "name": "st_settling_time",
                                            "name_orig": "ST Settling Time",
                                            "desc": "Time for the flow to settle during the filter test",
                                            "type": "int",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 60,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 205,
                                            "name": "st_meas_time",
                                            "name_orig": "ST Meas Time",
                                            "desc": "Time, over which the Tacho pulses are measured",
                                            "type": "int",
                                            "min": 1,
                                            "max": 255,
                                            "res": 1,
                                            "default": 60,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 206,
                                            "name": "st_sfstdtacho",
                                            "name_orig": "ST SFstdTacho",
                                            "desc": "The Supply Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
                                            "type": "int",
                                            "min": 0,
                                            "max": 9999,
                                            "res": 1,
                                            "default": 1800,
                                            "showInTechnical": true,
                                            "unit": "rpm",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 208,
                                            "name": "st_force_interval",
                                            "name_orig": "ST Force Interval",
                                            "desc": "Forced Filter Test Interval",
                                            "type": "int",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 24,
                                            "showInTechnical": true,
                                            "unit": "h",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 213,
                                            "name": "st_test_flow",
                                            "name_orig": "ST Test Flow",
                                            "desc": "The Flow at which the test is conducted",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 70,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 214,
                                            "name": "st_efstdtacho",
                                            "name_orig": "ST EFstdTacho",
                                            "desc": "The Extraction Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
                                            "type": "int",
                                            "min": 0,
                                            "max": 9999,
                                            "res": 1,
                                            "default": 1800,
                                            "showInTechnical": true,
                                            "unit": "rpm",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 215,
                                            "name": "st_start_time",
                                            "name_orig": "ST Start Time",
                                            "desc": "The time where filter test is run, if the unit is running, otherwise a Filter test is forced at the next startup",
                                            "type": "int",
                                            "min": 0,
                                            "max": 1439,
                                            "res": 1,
                                            "default": 720,
                                            "showInTechnical": true,
                                            "unit": "min",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 537,
                                            "name": "st_supply_fan_voltage",
                                            "name_orig": "ST Supply Fan Voltage",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 538,
                                            "name": "st_extraction_fan_voltage",
                                            "name_orig": "ST Extraction Fan Voltage",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "V",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 539,
                                            "name": "st_supply_fan_dp_reference",
                                            "name_orig": "ST Supply Fan DP Reference",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 550,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "Pa",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 540,
                                            "name": "st_extraction_fan_dp_reference",
                                            "name_orig": "ST Extraction Fan DP Reference",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 550,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "Pa",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 585,
                                            "name": "auto_calibration_time",
                                            "name_orig": "Auto calibration time",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 250,
                                            "res": 1,
                                            "default": 25,
                                            "showInTechnical": true,
                                            "unit": "hour",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Filter",
                                                "Self Test"
                                            ]
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Low Temp Alarm",
                        "type": "category",
                        "id": "e0c640dc-1b9f-4ba9-8cc5-3dc6dfc2bf1c",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 57,
                                    "name": "disable_low_temp_alarm",
                                    "name_orig": "Disable Low Temp Alarm",
                                    "desc": "Disable the Low Temp Alarm for X minutes after leaving the Start Procedure",
                                    "type": "int",
                                    "min": 0,
                                    "max": 60,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "min",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp Alarm"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 58,
                                    "name": "retry_low_temp_alarm",
                                    "name_orig": "Retry Low Temp Alarm",
                                    "desc": "Time Between Automated Start Attemps if unit was stopped due to Low Temp Alarm",
                                    "type": "int",
                                    "min": 0,
                                    "max": 18,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "h",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp Alarm"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 83,
                                    "name": "min_flow",
                                    "name_orig": "Min Flow",
                                    "desc": "Minimum fan 1 speed when decreasing speed due to low temperature",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp Alarm"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 659,
                                    "name": "ch_water_protection_otv",
                                    "name_orig": "CH Water Protection OTV",
                                    "desc": "To prevent damage caused by freezing, due to low outside temperature.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp Alarm"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 660,
                                    "name": "ch_water_protection_rt__it",
                                    "name_orig": "CH Water Protection RT / IT",
                                    "desc": "To prevent damage caused by freezing, due to low RT or IT.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp Alarm"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Low Temp",
                        "type": "category",
                        "id": "31599b73-9f0c-488d-b296-060ffc099105",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 60,
                                    "name": "low_inlet",
                                    "name_orig": "Low Inlet",
                                    "desc": "Low temp: &#176;C difference for reducing fan level (&#176;C difference between decided and actual &#176;C before the fan level is reduced)",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 60,
                                    "res": 0.5,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 61,
                                    "name": "intake_airflow_reduction",
                                    "name_orig": "Intake Airflow Reduction",
                                    "desc": "Low temp: % reducing intake airflow (% the intake airflow is reduced with when the inlet  &#176;C is low)",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 62,
                                    "name": "min_outlet",
                                    "name_orig": "Min Outlet",
                                    "desc": "Low temp: Minimum outlet (Lowest accepted &#176;C outlet air before the unit is stopped and alarm is set)",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 0.5,
                                    "default": -2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 63,
                                    "name": "wait_low",
                                    "name_orig": "Wait Low",
                                    "desc": "Wait time in low inlet: General wait time in low inlet process",
                                    "type": "int",
                                    "min": 0,
                                    "max": 60,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 64,
                                    "name": "low_outlet",
                                    "name_orig": "Low Outlet",
                                    "desc": "Low temp: Low outlet temperature. Decrease Vent 1 speed",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 0.5,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 65,
                                    "name": "inlet_increment",
                                    "name_orig": "Inlet Increment",
                                    "desc": "Low temp: Inlet temp. rising. Increase Vent 1 speed",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 60,
                                    "res": 0.5,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 66,
                                    "name": "outlet_increment",
                                    "name_orig": "Outlet Increment",
                                    "desc": "Low temp: Outlet temp. rising. Increase Vent 1 speed",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 0.5,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 69,
                                    "name": "min_inlet",
                                    "name_orig": "Min Inlet",
                                    "desc": "Low temp : Minimum inlet &#176;C (Lowest accepted &#176;C inlet air before the unit is stopped and alarm is set)",
                                    "type": "decimal",
                                    "min": -30,
                                    "max": 50,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 825,
                                    "name": "enthalpy_a_coefficient",
                                    "name_orig": "Enthalpy A Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 826,
                                    "name": "enthalpy_b_coefficient",
                                    "name_orig": "Enthalpy B Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 827,
                                    "name": "enthalpy_c_coefficient",
                                    "name_orig": "Enthalpy C Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 828,
                                    "name": "enthalpy_d_coefficient",
                                    "name_orig": "Enthalpy D Coefficient",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 829,
                                    "name": "enthalpy_safety_margin",
                                    "name_orig": "Enthalpy Safety Margin",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 16384,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 830,
                                    "name": "enthalpy_threshold",
                                    "name_orig": "Enthalpy Threshold",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": -1000,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 15360,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Low Temp"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Heating",
                        "type": "category",
                        "id": "0af3e6e1-7283-45ff-9b4f-0cfed559b14f",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Preheater",
                                "type": "category",
                                "id": "f6b900a5-f7cb-42ab-bc40-5fdcbe55d201",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 67,
                                            "name": "ph__max_etv",
                                            "name_orig": "PH  Max ETV",
                                            "desc": "Heating surface: Preheat temperature. Activate pre-heating surface below this temperature",
                                            "type": "decimal",
                                            "min": -20,
                                            "max": 50,
                                            "res": 0.5,
                                            "default": 4,
                                            "showInTechnical": true,
                                            "unit": "°C",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 68,
                                            "name": "ph_sf_reduction",
                                            "name_orig": "PH SF Reduction",
                                            "desc": "Heating surface: Preheat airflow. Vent 1 has to be decreased to this airflow before activating pre-heating surface.",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 90,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 559,
                                            "name": "ph_pid_kp",
                                            "name_orig": "PH PID KP",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 560,
                                            "name": "ph_pid_ki",
                                            "name_orig": "PH PID KI",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 561,
                                            "name": "ph_pid_kd",
                                            "name_orig": "PH PID KD",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 562,
                                            "name": "ph_pid_ka",
                                            "name_orig": "PH PID KA",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 563,
                                            "name": "ph_pid_min",
                                            "name_orig": "PH PID Min",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 564,
                                            "name": "ph_pid_max",
                                            "name_orig": "PH PID Max",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 565,
                                            "name": "ph_pid_sample_time",
                                            "name_orig": "PH PID Sample Time",
                                            "desc": "",
                                            "type": "int",
                                            "min": 10,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 573,
                                            "name": "ph_pwm_period",
                                            "name_orig": "PH PWM Period",
                                            "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
                                            "type": "int",
                                            "min": 1000,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 1000,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 638,
                                            "name": "ph_pid_ke",
                                            "name_orig": "PH PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Preheater"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Comfort Heater",
                                "type": "category",
                                "id": "b8420894-d7fa-4d59-a74e-b1630bd523e9",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 79,
                                            "name": "ch_water_soft_start",
                                            "name_orig": "CH Water Soft Start",
                                            "desc": "Case a water comfort heater is installed, start gradually over this period",
                                            "type": "int",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 180,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 566,
                                            "name": "ch_pid_kp",
                                            "name_orig": "CH PID KP",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 567,
                                            "name": "ch_pid_ki",
                                            "name_orig": "CH PID KI",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 568,
                                            "name": "ch_pid_kd",
                                            "name_orig": "CH PID KD",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 569,
                                            "name": "ch_pid_ka",
                                            "name_orig": "CH PID KA",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 570,
                                            "name": "ch_pid_min",
                                            "name_orig": "CH PID Min",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 571,
                                            "name": "ch_pid_max",
                                            "name_orig": "CH PID Max",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 572,
                                            "name": "ch_pid_sample_time",
                                            "name_orig": "CH PID Sample Time",
                                            "desc": "",
                                            "type": "int",
                                            "min": 10,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 574,
                                            "name": "ch_pwm_period",
                                            "name_orig": "CH PWM Period",
                                            "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
                                            "type": "int",
                                            "min": 1000,
                                            "max": 30000,
                                            "res": 1,
                                            "default": 1000,
                                            "showInTechnical": true,
                                            "unit": "ms",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 639,
                                            "name": "ch_pid_ke",
                                            "name_orig": "CH PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 843,
                                            "name": "ch_pid_adaptive_max_flow_pct_low",
                                            "name_orig": "CH PID Adaptive Max Flow pct Low",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 844,
                                            "name": "ch_pid_adaptive_max_flow_pct_high",
                                            "name_orig": "CH PID Adaptive Max Flow pct High",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 845,
                                            "name": "ch_pid_adaptive_max_ch_pct_low",
                                            "name_orig": "CH PID Adaptive Max CH pct Low",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 846,
                                            "name": "ch_pid_adaptive_max_ch_pct_high",
                                            "name_orig": "CH PID Adaptive Max CH pct High",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Comfort Heater"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Virtual Preheat",
                                "type": "category",
                                "id": "ec02dd08-3b6b-4089-a365-ba8ef90878d4",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 108,
                                            "name": "vph_mode",
                                            "name_orig": "VPH Mode",
                                            "desc": "Virtual Pre Heater Mode",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 2,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Virtual Preheat"
                                            ],
                                            "options": {
                                                "0": "OFF",
                                                "1": "Green",
                                                "2": "Comfort"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 110,
                                            "name": "vph_max",
                                            "name_orig": "VPH Max",
                                            "desc": "Virtual Pre Heater, maximum opening of By-Pass",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 40,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Virtual Preheat"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 112,
                                            "name": "vph_step_size",
                                            "name_orig": "VPH Step Size",
                                            "desc": "Virtual Pre Heater, Step Size",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "Virtual Preheat"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "General",
                                "type": "category",
                                "id": "b4bb2c1b-428c-4ab7-9587-888bf89aef2b",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 234,
                                            "name": "high_temperature_delay",
                                            "name_orig": "High Temperature Delay",
                                            "desc": "High Temperature Delay (prevent HT after heating and startup)",
                                            "type": "int",
                                            "min": 0,
                                            "max": 60,
                                            "res": 1,
                                            "default": 5,
                                            "showInTechnical": true,
                                            "unit": "min",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 235,
                                            "name": "electrical_heater_delay",
                                            "name_orig": "Electrical Heater Delay",
                                            "desc": "Electrical Heater, Delay for returning to \"normal flow\" after usage",
                                            "type": "int",
                                            "min": 0,
                                            "max": 255,
                                            "res": 1,
                                            "default": 30,
                                            "showInTechnical": true,
                                            "unit": "min",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 236,
                                            "name": "electrical_heater_increase_flow",
                                            "name_orig": "Electrical Heater Increase Flow",
                                            "desc": "Increase Air Flow, case it is to low for Electrical Heating.",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 237,
                                            "name": "electrical_heater_min_flow",
                                            "name_orig": "Electrical Heater Min Flow",
                                            "desc": "Stop Electrical Heaters if Supply Flow is less than this",
                                            "type": "int",
                                            "min": 0,
                                            "max": 9999,
                                            "res": 1,
                                            "default": 100,
                                            "showInTechnical": true,
                                            "unit": "m³/h",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 238,
                                            "name": "electrical_heater_min_tacho",
                                            "name_orig": "Electrical Heater Min Tacho",
                                            "desc": "Stop Electrical Heaters if Supply Fan Tacho is less than this",
                                            "type": "int",
                                            "min": 0,
                                            "max": 9999,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "rpm",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 641,
                                            "name": "control_algorithm_heating",
                                            "name_orig": "Control Algorithm, Heating",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Heating",
                                                "General"
                                            ],
                                            "options": {
                                                "0": "PID0",
                                                "1": "PID1"
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Displacement",
                        "type": "category",
                        "id": "07249eb4-07e1-417c-a04c-6beb02f80e4d",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 106,
                                    "name": "displacement",
                                    "name_orig": "Displacement",
                                    "desc": "Displacement Temperature offset (set to 'off' for mixing units)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 6,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Displacement"
                                    ],
                                    "options": {
                                        "0": "OFF",
                                        "1": "0",
                                        "2": "-1",
                                        "3": "-2",
                                        "4": "-3",
                                        "5": "-4",
                                        "6": "-5"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 190,
                                    "name": "fixed_displacement",
                                    "name_orig": "Fixed Displacement",
                                    "desc": "Fixed Displacement - requires \"Displacement != off\", and will fix the IT-Setpoint Displacement degrees below the measured RT.",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Displacement"
                                    ],
                                    "options": {
                                        "0": "off",
                                        "1": "on"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 584,
                                    "name": "rtmin",
                                    "name_orig": "Rtmin",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 50,
                                    "res": 1,
                                    "default": 18,
                                    "showInTechnical": true,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Displacement"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Flow",
                        "type": "category",
                        "id": "ee3cebca-3348-4041-b224-caa30234be1f",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Control",
                                "type": "category",
                                "id": "c4019ebb-8bb2-44e4-9ab5-a8ad77d04f9c",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 129,
                                            "name": "pid_sample_t",
                                            "name_orig": "PID Sample T",
                                            "desc": "PID Sample Time",
                                            "type": "decimal",
                                            "min": 0.01,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0.5,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 216,
                                            "name": "qsa_pid_kp",
                                            "name_orig": "QSA PID KP",
                                            "desc": "Supply Fan PID, Proportional Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 217,
                                            "name": "qsa_pid_ki",
                                            "name_orig": "QSA PID KI",
                                            "desc": "Supply Air Flow PID, Integral Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0.2,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 218,
                                            "name": "qsa_pid_kd",
                                            "name_orig": "QSA PID KD",
                                            "desc": "Supply Air Flow PID, Differential Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 219,
                                            "name": "qsa_pid_ka",
                                            "name_orig": "QSA PID KA",
                                            "desc": "Supply Air Flow PID, Anti Windup Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 223,
                                            "name": "qea_pid_kp",
                                            "name_orig": "QEA PID KP",
                                            "desc": "Extraction Air Flow PID, Proportional Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 224,
                                            "name": "qea_pid_ki",
                                            "name_orig": "QEA PID KI",
                                            "desc": "Extraction Air Flow PID, Integral Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0.2,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 225,
                                            "name": "qea_pid_kd",
                                            "name_orig": "QEA PID KD",
                                            "desc": "Extraction Air Flow PID, Differential Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 226,
                                            "name": "qea_pid_ka",
                                            "name_orig": "QEA PID KA",
                                            "desc": "Extraction Flow PID, Anti Windup Gain",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 229,
                                            "name": "nominal_max_flow",
                                            "name_orig": "Nominal Max Flow",
                                            "desc": "Nominal Max Flow for this unit \\n Convert: \\n Flow [m&#179;/h] / 3,6 = Flow [l/s] \\n E.g.,      360 m&#179;/h / 3,6       = 100 l/s \\n \\n Flow [l/s] * 3,6 = Flow [m&#179;/h] \\n E.g.,      100 l/s * 3,6         = 360 m&#179;/h",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 9999,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "m³/h",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 239,
                                            "name": "mode",
                                            "name_orig": "Mode",
                                            "desc": "Select Flow Mode: Ventilator voltage percentage / PID flow controller",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 2,
                                            "res": 1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "Volt Direct",
                                                "1": "Flow Control",
                                                "2": "Volt Control"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 423,
                                            "name": "sf_pid_kp",
                                            "name_orig": "SF PID KP",
                                            "desc": "Supply Fan Voltage PID, Proportional Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 424,
                                            "name": "sf_pid_ki",
                                            "name_orig": "SF PID KI",
                                            "desc": "Supply Fan Voltage PID, Integral Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 425,
                                            "name": "sf_pid_kd",
                                            "name_orig": "SF PID KD",
                                            "desc": "Supply Fan Voltage PID, Differential Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 426,
                                            "name": "sf_pid_ka",
                                            "name_orig": "SF PID KA",
                                            "desc": "Supply Fan Voltage PID, Anti Windup Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 427,
                                            "name": "ef_pid_kp",
                                            "name_orig": "EF PID KP",
                                            "desc": "Extraction Fan Voltage PID, Proportional Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 428,
                                            "name": "ef_pid_ki",
                                            "name_orig": "EF PID KI",
                                            "desc": "Extraction Fan Voltage PID, Integral Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 429,
                                            "name": "ef_pid_kd",
                                            "name_orig": "EF PID KD",
                                            "desc": "Extraction Fan Voltage PID, Differential Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 430,
                                            "name": "ef_pid_ka",
                                            "name_orig": "EF PID KA",
                                            "desc": "Extraction Fan Voltage PID, Anti Windup Gain",
                                            "type": "decimal",
                                            "min": -1000,
                                            "max": 1000,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 431,
                                            "name": "fan_voltage_control_pid_sample_t",
                                            "name_orig": "Fan Voltage Control PID Sample T",
                                            "desc": "Fan Voltage Control PID Sample Time seconds",
                                            "type": "decimal",
                                            "min": 0.01,
                                            "max": 10,
                                            "res": 0.01,
                                            "default": 0.5,
                                            "showInTechnical": true,
                                            "unit": "sec",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 634,
                                            "name": "qsa_pid_ke",
                                            "name_orig": "QSA PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 635,
                                            "name": "qea_pid_ke",
                                            "name_orig": "QEA PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 636,
                                            "name": "sf_pid_ke",
                                            "name_orig": "SF PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 637,
                                            "name": "ef_pid_ke",
                                            "name_orig": "EF PID KE",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "1",
                                                "1": "RQF/100"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 642,
                                            "name": "control_algorithm_flow_control",
                                            "name_orig": "Control Algorithm, Flow Control",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Control"
                                            ],
                                            "options": {
                                                "0": "PID0",
                                                "1": "PID1"
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                "name": "Measure",
                                "type": "category",
                                "id": "9b1dfc7c-0582-4cc6-8331-e4931e0c398c",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 210,
                                            "name": "flow_measurements_to_average",
                                            "name_orig": "Flow Measurements to Average",
                                            "desc": "Number of flowmeasurements for moving average",
                                            "type": "int",
                                            "min": 1,
                                            "max": 100,
                                            "res": 1,
                                            "default": 10,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 513,
                                            "name": "sf_a_coefficient",
                                            "name_orig": "SF A Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 514,
                                            "name": "sf_b_coefficient",
                                            "name_orig": "SF B Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 515,
                                            "name": "sf_c_coefficient",
                                            "name_orig": "SF C Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 516,
                                            "name": "sf_d_coefficient",
                                            "name_orig": "SF D Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 517,
                                            "name": "sf_e_coefficient",
                                            "name_orig": "SF E Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 518,
                                            "name": "sf_f_coefficient",
                                            "name_orig": "SF F Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 519,
                                            "name": "sf_g_coefficient",
                                            "name_orig": "SF G Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 520,
                                            "name": "sf_h_coefficient",
                                            "name_orig": "SF H Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 521,
                                            "name": "sf_i_coefficient",
                                            "name_orig": "SF I Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 522,
                                            "name": "ef_a_coefficient",
                                            "name_orig": "EF A Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 523,
                                            "name": "ef_b_coefficient",
                                            "name_orig": "EF B Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 524,
                                            "name": "ef_c_coefficient",
                                            "name_orig": "EF C Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 525,
                                            "name": "ef_d_coefficient",
                                            "name_orig": "EF D Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 526,
                                            "name": "ef_e_coefficient",
                                            "name_orig": "EF E Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 527,
                                            "name": "ef_f_coefficient",
                                            "name_orig": "EF F Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 528,
                                            "name": "ef_g_coefficient",
                                            "name_orig": "EF G Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 529,
                                            "name": "ef_h_coefficient",
                                            "name_orig": "EF H Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 530,
                                            "name": "ef_i_coefficient",
                                            "name_orig": "EF I Coefficient",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -64992,
                                            "max": 64992,
                                            "res": 1e-10,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 531,
                                            "name": "sf_x1x2_calculation",
                                            "name_orig": "SF X1/X2 Calculation",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 15,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ],
                                            "options": {
                                                "0": "X1: 1' | X2: 1'",
                                                "1": "X1: 1' | X2: ln'",
                                                "2": "X1: 1' | X2: 1/'",
                                                "3": "X1: 1' | X2: 1/ln'",
                                                "4": "X1: ln' | X2: 1'",
                                                "5": "X1: ln' | X2: ln'",
                                                "6": "X1: ln' | X2: 1/'",
                                                "7": "X1: ln' | X2: 1/ln'",
                                                "8": "X1: 1/' | X2: 1'",
                                                "9": "X1: 1/' | X2: ln'",
                                                "10": "X1: 1/' | X2: 1/'",
                                                "11": "X1: 1/' | X2: 1/ln'",
                                                "12": "X1: 1/ln' | X2: 1'",
                                                "13": "X1: 1/ln' | X2: ln'",
                                                "14": "X1: 1/ln' | X2: 1/'",
                                                "15": "X1: 1/ln' | X2: 1/ln'"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 532,
                                            "name": "ef_x1x2_calculation",
                                            "name_orig": "EF X1/X2 Calculation",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 15,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ],
                                            "options": {
                                                "0": "X1: 1' | X2: 1'",
                                                "1": "X1: 1' | X2: ln'",
                                                "2": "X1: 1' | X2: 1/'",
                                                "3": "X1: 1' | X2: 1/ln'",
                                                "4": "X1: ln' | X2: 1'",
                                                "5": "X1: ln' | X2: ln'",
                                                "6": "X1: ln' | X2: 1/'",
                                                "7": "X1: ln' | X2: 1/ln'",
                                                "8": "X1: 1/' | X2: 1'",
                                                "9": "X1: 1/' | X2: ln'",
                                                "10": "X1: 1/' | X2: 1/'",
                                                "11": "X1: 1/' | X2: 1/ln'",
                                                "12": "X1: 1/ln' | X2: 1'",
                                                "13": "X1: 1/ln' | X2: ln'",
                                                "14": "X1: 1/ln' | X2: 1/'",
                                                "15": "X1: 1/ln' | X2: 1/ln'"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 533,
                                            "name": "sf_bypass_opening",
                                            "name_orig": "SF Bypass Opening",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 534,
                                            "name": "ef_bypass_opening",
                                            "name_orig": "EF Bypass Opening",
                                            "desc": "",
                                            "type": "int",
                                            "min": 0,
                                            "max": 100,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 535,
                                            "name": "sf_bypass_correction",
                                            "name_orig": "SF Bypass Correction",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -100,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 536,
                                            "name": "ef_bypass_correction",
                                            "name_orig": "EF Bypass Correction",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -100,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 547,
                                            "name": "sf_calibration",
                                            "name_orig": "SF Calibration",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -100,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 548,
                                            "name": "ef_calibration",
                                            "name_orig": "EF Calibration",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": -100,
                                            "max": 100,
                                            "res": 0.1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "%",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 580,
                                            "name": "dp_min",
                                            "name_orig": "DP Min",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0,
                                            "max": 550,
                                            "res": 0.1,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "Pa",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 581,
                                            "name": "x2_input",
                                            "name_orig": "X2 Input",
                                            "desc": "",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 1,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ],
                                            "options": {
                                                "0": "dP_ref",
                                                "1": "tac"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 582,
                                            "name": "dp_scale",
                                            "name_orig": "DP Scale",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0.01,
                                            "max": 100,
                                            "res": 0.01,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 583,
                                            "name": "tacho_scale",
                                            "name_orig": "Tacho Scale",
                                            "desc": "",
                                            "type": "decimal",
                                            "min": 0.01,
                                            "max": 100,
                                            "res": 0.01,
                                            "default": 1,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "Process Settings",
                                                "Flow",
                                                "Measure"
                                            ]
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Flow Distribution",
                        "type": "category",
                        "id": "b5e32cc7-25b5-4890-b483-c6ff6c8a39f7",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 174,
                                    "name": "room_1_activation",
                                    "name_orig": "Room 1 Activation",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Boost",
                                        "2": "PIR",
                                        "3": "Ext Start"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 175,
                                    "name": "room_2_activation",
                                    "name_orig": "Room 2 Activation",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Boost",
                                        "2": "PIR",
                                        "3": "Ext Start"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 176,
                                    "name": "valve_position_neutral",
                                    "name_orig": "Valve Position Neutral",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 5,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 177,
                                    "name": "valve_position_room_1",
                                    "name_orig": "Valve Position Room 1",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 178,
                                    "name": "valve_position_room_2",
                                    "name_orig": "Valve Position Room 2",
                                    "desc": "",
                                    "type": "decimal",
                                    "min": 0,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 8,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 599,
                                    "name": "tc_valve_position_neutral",
                                    "name_orig": "TC: Valve Position Neutral",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 600,
                                    "name": "tc_valve_position_room_1",
                                    "name_orig": "TC: Valve Position Room 1",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 20,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 601,
                                    "name": "tc_valve_position_room_2",
                                    "name_orig": "TC: Valve Position Room 2",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 80,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 602,
                                    "name": "tc_run_time",
                                    "name_orig": "TC: Run Time",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 999,
                                    "res": 1,
                                    "default": 75,
                                    "showInTechnical": true,
                                    "unit": "sec",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 603,
                                    "name": "tc_cw_rotation",
                                    "name_orig": "TC: CW Rotation",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 604,
                                    "name": "tc_ccw_rotation",
                                    "name_orig": "TC: CCW Rotation",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "L2",
                                        "1": "L3",
                                        "2": "L2+L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 605,
                                    "name": "tc_0percent_position",
                                    "name_orig": "TC: 0% Position",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "CW",
                                        "1": "CCW"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 606,
                                    "name": "tc_calibration_position",
                                    "name_orig": "TC: Calibration Position",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "0%",
                                        "1": "100%"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 607,
                                    "name": "tc_self_calibration",
                                    "name_orig": "TC: Self Calibration",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 30000,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 608,
                                    "name": "tc_cw_offset",
                                    "name_orig": "TC: CW Offset",
                                    "desc": "",
                                    "type": "int",
                                    "min": -999,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ms",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 609,
                                    "name": "tc_ccw_offset",
                                    "name_orig": "TC: CCW Offset",
                                    "desc": "",
                                    "type": "int",
                                    "min": -999,
                                    "max": 999,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "ms",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 610,
                                    "name": "mode",
                                    "name_orig": "Mode",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ],
                                    "options": {
                                        "0": "Off",
                                        "1": "VC",
                                        "2": "TC"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 611,
                                    "name": "off_position",
                                    "name_orig": "Off Position",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 100,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "Flow Distribution"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "External Stop",
                        "type": "category",
                        "id": "ddc9c34c-e366-4612-9182-b434afd944d1",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 546,
                                    "name": "external_stop",
                                    "name_orig": "External Stop",
                                    "desc": "Note: requires external stop input setup in I/O Config menu",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Process Settings",
                                        "External Stop"
                                    ],
                                    "options": {
                                        "0": "Group",
                                        "1": "All"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Datalog",
                "type": "category",
                "id": "2954b2d4-f876-4329-8c94-b7e7c66b830f",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 22,
                            "name": "log_mode",
                            "name_orig": "Log Mode",
                            "desc": "Log Mode (Normal = Runtime, Survey = Unit Survey)\nN.B.: Unit Survey empty log, and log until end of survey",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "Norm.",
                                "2": "Start Survey",
                                "3": "Survey Running"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 41,
                            "name": "pid_log",
                            "name_orig": "PID Log",
                            "desc": "PID Log on/off",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog"
                            ],
                            "options": {
                                "0": "OFF",
                                "1": "ON"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 71,
                            "name": "normal_interval",
                            "name_orig": "Normal Interval",
                            "desc": "Runtime Log Interval",
                            "type": "int",
                            "min": 1,
                            "max": 9999,
                            "res": 1,
                            "default": 300,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 74,
                            "name": "unit_survey_period",
                            "name_orig": "Unit Survey Period",
                            "desc": "Unit Survey Log Time Period, Count Down, to show remaining time.\nShown as h up to 240 h above shown in days.",
                            "type": "int",
                            "min": 1,
                            "max": 32300,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 76,
                            "name": "unit_survey_interval",
                            "name_orig": "Unit Survey Interval",
                            "desc": "Unit Survey Log Interval",
                            "type": "int",
                            "min": 1,
                            "max": 3600,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "name": "Thresholds For Online Log",
                        "type": "category",
                        "id": "76573de0-c0fd-44b7-96d7-502b4cc92c7b",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 700,
                                    "name": "threshold_room_temperature",
                                    "name_orig": "Threshold Room Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 701,
                                    "name": "threshold_inlet_temperature",
                                    "name_orig": "Threshold Inlet Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 702,
                                    "name": "threshold_outside_temperature",
                                    "name_orig": "Threshold Outside Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 703,
                                    "name": "threshold_general_purpose_temperature",
                                    "name_orig": "Threshold General Purpose Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 704,
                                    "name": "threshold_evaporator_temperature",
                                    "name_orig": "Threshold Evaporator Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 705,
                                    "name": "threshold_condenser_temperature",
                                    "name_orig": "Threshold Condenser Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 706,
                                    "name": "threshold_outside_temperature_ventilation",
                                    "name_orig": "Threshold Outside Temperature Ventilation",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 707,
                                    "name": "threshold_exhaust_temperature_ventilation",
                                    "name_orig": "Threshold Exhaust Temperature Ventilation",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 708,
                                    "name": "threshold_a_bms_temperature",
                                    "name_orig": "Threshold A-BMS Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 709,
                                    "name": "threshold_co2_level_room",
                                    "name_orig": "Threshold CO2 Level Room",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 710,
                                    "name": "threshold_internal_co2_sensor",
                                    "name_orig": "Threshold Internal CO2 Sensor",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 711,
                                    "name": "threshold_a_co2_1",
                                    "name_orig": "Threshold A-CO2 #1",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 712,
                                    "name": "threshold_a_co2_2",
                                    "name_orig": "Threshold A-CO2 #2",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 713,
                                    "name": "threshold_a_co2_3",
                                    "name_orig": "Threshold A-CO2 #3",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 5000,
                                    "res": 1,
                                    "default": 500,
                                    "showInTechnical": false,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 714,
                                    "name": "threshold_a_bms_flow_percent",
                                    "name_orig": "Threshold A-BMS Flow Percent",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 715,
                                    "name": "threshold_comfort_heater_percent",
                                    "name_orig": "Threshold Comfort Heater Percent",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 716,
                                    "name": "threshold_bypass_damper_percent",
                                    "name_orig": "Threshold Bypass Damper Percent",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 717,
                                    "name": "threshold_adaptive_airflow",
                                    "name_orig": "Threshold Adaptive Airflow",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.1,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 718,
                                    "name": "threshold_cooling_percent",
                                    "name_orig": "Threshold Cooling Percent",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 719,
                                    "name": "threshold_pre_heater_percent",
                                    "name_orig": "Threshold Pre Heater Percent",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 720,
                                    "name": "threshold_supply_fan_voltage",
                                    "name_orig": "Threshold Supply Fan Voltage",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.2,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 721,
                                    "name": "threshold_extraction_fan_voltage",
                                    "name_orig": "Threshold Extraction Fan Voltage",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.2,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 722,
                                    "name": "threshold_recirculation_fan_voltage",
                                    "name_orig": "Threshold Recirculation Fan Voltage",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 10,
                                    "res": 0.1,
                                    "default": 0.2,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 723,
                                    "name": "threshold_supply_flow",
                                    "name_orig": "Threshold Supply Flow",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "m³/h",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 724,
                                    "name": "threshold_extraction_flow",
                                    "name_orig": "Threshold Extraction Flow",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "m³/h",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 725,
                                    "name": "threshold_supply_flow_1",
                                    "name_orig": "Threshold Supply Flow #1",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 10000,
                                    "showInTechnical": false,
                                    "unit": "m³/h",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 726,
                                    "name": "threshold_supply_flow_2",
                                    "name_orig": "Threshold Supply Flow #2",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 10000,
                                    "showInTechnical": false,
                                    "unit": "m³/h",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 727,
                                    "name": "threshold_supply_fan_rpm",
                                    "name_orig": "Threshold Supply Fan RPM",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": false,
                                    "unit": "rpm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 728,
                                    "name": "threshold_extraction_fan_rpm",
                                    "name_orig": "Threshold Extraction Fan RPM",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": false,
                                    "unit": "rpm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 729,
                                    "name": "threshold_recirculation_fan_rpm",
                                    "name_orig": "Threshold Recirculation Fan RPM",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 10000,
                                    "res": 1,
                                    "default": 50,
                                    "showInTechnical": false,
                                    "unit": "rpm",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 730,
                                    "name": "threshold_requested_temperature",
                                    "name_orig": "Threshold Requested Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 731,
                                    "name": "threshold_requested_flow",
                                    "name_orig": "Threshold Requested Flow",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 732,
                                    "name": "threshold_relative_humidity_supply_air",
                                    "name_orig": "Threshold Relative Humidity Supply Air",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 733,
                                    "name": "threshold_relative_humidity_extraction_air",
                                    "name_orig": "Threshold Relative Humidity Extraction Air",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 734,
                                    "name": "threshold_system_mode",
                                    "name_orig": "Threshold System Mode",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 735,
                                    "name": "threshold_system_condition",
                                    "name_orig": "Threshold System Condition:",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 736,
                                    "name": "threshold_system_alarm",
                                    "name_orig": "Threshold System Alarm:",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 737,
                                    "name": "threshold_am_float_switch",
                                    "name_orig": "Threshold AM Float Switch",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 738,
                                    "name": "threshold_manuel_start",
                                    "name_orig": "Threshold Manuel Start",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 739,
                                    "name": "threshold_cc_float_switch",
                                    "name_orig": "Threshold CC Float switch",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 740,
                                    "name": "threshold_a_bms_start",
                                    "name_orig": "Threshold A-BMS Start",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 741,
                                    "name": "threshold_pir",
                                    "name_orig": "Threshold PIR",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 742,
                                    "name": "threshold_alarm_input",
                                    "name_orig": "Threshold Alarm Input",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 743,
                                    "name": "threshold_recirculation_damper",
                                    "name_orig": "Threshold Recirculation Damper",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 744,
                                    "name": "threshold_main_air_damper",
                                    "name_orig": "Threshold Main Air Damper",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 745,
                                    "name": "threshold_general_warning_id_0_15",
                                    "name_orig": "Threshold General warning id 0-15",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 746,
                                    "name": "threshold_general_warning_id_16_31",
                                    "name_orig": "Threshold General warning id 16-31",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 747,
                                    "name": "threshold_general_alarm_id_0_15",
                                    "name_orig": "Threshold General alarm id 0-15",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 748,
                                    "name": "threshold_general_alarm_id_16_31",
                                    "name_orig": "Threshold General alarm id 16-31",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 749,
                                    "name": "threshold_filter_remaining_service_life_days",
                                    "name_orig": "Threshold Filter, remaining service life [days]",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1000,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "days",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 750,
                                    "name": "threshold_air_handling_unit_power_consumption",
                                    "name_orig": "Threshold Air Handling Unit Power Consumption",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "kWh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 751,
                                    "name": "threshold_air_handling_unit_power_consumption_decimal_points",
                                    "name_orig": "Threshold Air Handling Unit Power Consumption decimal points",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.001,
                                    "max": 0.999,
                                    "res": 0.001,
                                    "default": 0.1,
                                    "showInTechnical": false,
                                    "unit": "kWh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 752,
                                    "name": "threshold_utc_time_in_seconds",
                                    "name_orig": "Threshold UTC Time in seconds",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 3600,
                                    "showInTechnical": false,
                                    "unit": "s",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 753,
                                    "name": "threshold_air_handling_unit_power_consumption",
                                    "name_orig": "Threshold Air Handling Unit Power Consumption",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": false,
                                    "unit": "Wh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 754,
                                    "name": "threshold_remaining_service_life_percent",
                                    "name_orig": "Threshold Remaining Service Life [%]",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 101,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 755,
                                    "name": "threshold_remaining_service_life_hours",
                                    "name_orig": "Threshold Remaining Service Life [Hours]",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "h",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 756,
                                    "name": "threshold_evaporator_in_temperature",
                                    "name_orig": "Threshold Evaporator In Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 757,
                                    "name": "threshold_evaporator_out_temperature",
                                    "name_orig": "Threshold Evaporator Out Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 758,
                                    "name": "threshold_hotgas_temperature",
                                    "name_orig": "Threshold Hotgas Temperature",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 0.5,
                                    "showInTechnical": false,
                                    "unit": "°C",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 759,
                                    "name": "threshold_stepper_valve_opening",
                                    "name_orig": "Threshold Stepper Valve Opening",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 1,
                                    "max": 100,
                                    "res": 0.1,
                                    "default": 100,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 760,
                                    "name": "threshold_analog_in_1",
                                    "name_orig": "Threshold Analog in 1",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.01,
                                    "max": 10,
                                    "res": 0.01,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 761,
                                    "name": "threshold_analog_in_2",
                                    "name_orig": "Threshold Analog in 2",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.01,
                                    "max": 10,
                                    "res": 0.01,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 762,
                                    "name": "threshold_analog_in_3",
                                    "name_orig": "Threshold Analog in 3",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.01,
                                    "max": 10,
                                    "res": 0.01,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "V",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 763,
                                    "name": "threshold_hardware_errors",
                                    "name_orig": "Threshold Hardware errors",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 764,
                                    "name": "threshold_bacnet_device_id",
                                    "name_orig": "Threshold Bacnet Device Id",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 765,
                                    "name": "threshold_cooling_unit_power_consumption",
                                    "name_orig": "Threshold Cooling Unit Power Consumption",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "kWh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 766,
                                    "name": "threshold_cooling_unit_power_consumption_decimal_points",
                                    "name_orig": "Threshold Cooling Unit Power Consumption decimal points",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.001,
                                    "max": 0.999,
                                    "res": 0.001,
                                    "default": 0.1,
                                    "showInTechnical": false,
                                    "unit": "kWh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 767,
                                    "name": "threshold_cooling_unit_power_consumption",
                                    "name_orig": "Threshold Cooling Unit Power Consumption",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 100,
                                    "showInTechnical": false,
                                    "unit": "Wh",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 768,
                                    "name": "threshold_supply_air_pressure_difference",
                                    "name_orig": "Threshold Supply air pressure difference",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 550,
                                    "res": 0.1,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "Pa",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 769,
                                    "name": "threshold_extracton_air_pressure_difference",
                                    "name_orig": "Threshold Extracton air pressure difference",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.1,
                                    "max": 550,
                                    "res": 0.1,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "Pa",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 770,
                                    "name": "threshold_simplified_operating_mode",
                                    "name_orig": "Threshold Simplified Operating Mode",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 771,
                                    "name": "threshold_hardware_errors_lsb",
                                    "name_orig": "Threshold Hardware Errors LSB",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 772,
                                    "name": "threshold_hardware_errors_msb",
                                    "name_orig": "Threshold Hardware Errors MSB",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 773,
                                    "name": "threshold_air_handling_unit_current_power_consumption",
                                    "name_orig": "Threshold Air Handling Unit Current Power Consumption",
                                    "desc": "threshold for online logging",
                                    "type": "decimal",
                                    "min": 0.0099945068359375,
                                    "max": 1000,
                                    "res": 1e-10,
                                    "default": 18688,
                                    "showInTechnical": false,
                                    "unit": "W",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 774,
                                    "name": "threshold_air_quality_tvoc",
                                    "name_orig": "Threshold Air quality TVOC",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 10,
                                    "showInTechnical": false,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 775,
                                    "name": "threshold_air_quality_raw_signal",
                                    "name_orig": "Threshold Air quality RAW signal",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 32767,
                                    "res": 1,
                                    "default": 32767,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 776,
                                    "name": "threshold_rqf_prodco2",
                                    "name_orig": "Threshold RQF_ProdCO2",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 777,
                                    "name": "threshold_rqf_prodtvoc",
                                    "name_orig": "Threshold RQF_ProdTVOC",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 100,
                                    "res": 1,
                                    "default": 5,
                                    "showInTechnical": false,
                                    "unit": "%",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": false,
                                "data": {
                                    "id": 778,
                                    "name": "threshold_started_by",
                                    "name_orig": "Threshold Started By",
                                    "desc": "threshold for online logging",
                                    "type": "int",
                                    "min": 1,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": false,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Datalog",
                                        "Thresholds For Online Log"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Airlinq Panel",
                "type": "category",
                "id": "28f990f5-a66f-499b-b6a7-488100d45383",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "General",
                        "type": "category",
                        "id": "bd5e16ce-443b-4968-a9ec-9920c71b8512",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 50,
                                    "name": "manual_override_time",
                                    "name_orig": "Manual Override Time",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 18,
                                    "res": 1,
                                    "default": 12,
                                    "showInTechnical": true,
                                    "unit": "hour",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 333,
                                    "name": "panel_flow_function",
                                    "name_orig": "Panel Flow Function",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 2,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "Direct",
                                        "1": "None",
                                        "2": "D-BMS"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 334,
                                    "name": "panel_off_allowed",
                                    "name_orig": "Panel OFF Allowed",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "General"
                                    ],
                                    "options": {
                                        "0": "All",
                                        "1": "None",
                                        "2": "CID 160",
                                        "3": "CID 161",
                                        "4": "CID 162",
                                        "5": "CID 163",
                                        "6": "CID 164",
                                        "7": "CID 165",
                                        "8": "CID 166",
                                        "9": "CID 167",
                                        "10": "CID 168",
                                        "11": "CID 169",
                                        "12": "CID 170",
                                        "13": "CID 171",
                                        "14": "CID 172",
                                        "15": "CID 173",
                                        "16": "CID 174",
                                        "17": "CID 175",
                                        "18": "CID 176",
                                        "19": "CID 177",
                                        "20": "CID 178",
                                        "21": "CID 179"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 352,
                                    "name": "panel_start_timeout",
                                    "name_orig": "Panel Start Timeout",
                                    "desc": "Time from panel start request to automatic return to standby, 0 = no timeout, remains started until user stop.",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 4,
                                    "showInTechnical": true,
                                    "unit": "hour",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "General"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 353,
                                    "name": "panel_off_timeout",
                                    "name_orig": "Panel Off Timeout",
                                    "desc": "Time from panel standby request, to shift from off to standby mode",
                                    "type": "int",
                                    "min": 0,
                                    "max": 255,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "hour",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "General"
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "name": "Orbit",
                        "type": "category",
                        "id": "876b35f1-4169-4157-ae01-cd2bd05409ce",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 84,
                                    "name": "language",
                                    "name_orig": "Language",
                                    "desc": "1=English, 2=Danish, 3=Dutch, 4=German, 5=Swedish, 6=French, 7=Norwegian, 8=Finnish",
                                    "type": "enum",
                                    "min": 1,
                                    "max": 8,
                                    "res": 1,
                                    "default": 2,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "1": "English",
                                        "2": "Danish",
                                        "3": "Dutch",
                                        "4": "German",
                                        "5": "Swedish",
                                        "6": "French",
                                        "7": "Norwegian",
                                        "8": "Finnish"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 252,
                                    "name": "show_startup_guide",
                                    "name_orig": "Show Startup Guide",
                                    "desc": "Indicates whether startup guide should be shown on next startup",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "0": "Yes",
                                        "1": "No"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 366,
                                    "name": "co2_low",
                                    "name_orig": "CO2 Low",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 50,
                                    "default": 900,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 367,
                                    "name": "co2_mid",
                                    "name_orig": "CO2 Mid",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 50,
                                    "default": 1200,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 575,
                                    "name": "group_master_na_warning",
                                    "name_orig": "Group Master NA Warning",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "0": "Off",
                                        "1": "On"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 615,
                                    "name": "screensaver_airquality_mode",
                                    "name_orig": "Screensaver Airquality Mode",
                                    "desc": "Defines how the Airquality information is shown",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "0": "Disabled",
                                        "1": "Graph",
                                        "2": "Graph + Text",
                                        "3": "Graph + Text (Large)"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 616,
                                    "name": "screensaver_logo",
                                    "name_orig": "Screensaver Logo",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 0,
                                    "default": 1,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Company"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 677,
                                    "name": "co2_high",
                                    "name_orig": "CO2 High",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 50,
                                    "default": 2000,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 678,
                                    "name": "co2_warning",
                                    "name_orig": "CO2 Warning",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 50,
                                    "default": 5000,
                                    "showInTechnical": true,
                                    "unit": "ppm",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 679,
                                    "name": "tvoc_low",
                                    "name_orig": "TVOC Low",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 1,
                                    "default": 65,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 680,
                                    "name": "tvoc_mid",
                                    "name_orig": "TVOC Mid",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 1,
                                    "default": 220,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 681,
                                    "name": "tvoc_high",
                                    "name_orig": "TVOC High",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 1,
                                    "default": 660,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 682,
                                    "name": "tvoc_warning",
                                    "name_orig": "TVOC Warning",
                                    "desc": "",
                                    "type": "int",
                                    "min": 0,
                                    "max": 32000,
                                    "res": 1,
                                    "default": 2200,
                                    "showInTechnical": true,
                                    "unit": "ppb",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ]
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 692,
                                    "name": "screensaver_show_flow",
                                    "name_orig": "Screensaver Show Flow",
                                    "desc": "",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 1,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "Airlinq Panel",
                                        "Orbit"
                                    ],
                                    "options": {
                                        "0": "Off",
                                        "1": "On"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "I/O Config",
                "type": "category",
                "id": "38f6d4d7-8376-4973-8a3a-6601a72d9ae9",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "name": "Cooling Controller",
                        "type": "category",
                        "id": "f462649f-9cab-4b4f-809b-442e611b2ae0",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "name": "Temperature",
                                "type": "category",
                                "id": "cbd78fbf-d946-40e8-8077-ad52bdf295a1",
                                "isCollapsed": true,
                                "isVisibleToTechnicalBasic": true,
                                "children": [
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 168,
                                            "name": "cc_t1",
                                            "name_orig": "CC T1",
                                            "desc": "Input function for cooling controller input temperature 1",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 10,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "I/O Config",
                                                "Cooling Controller",
                                                "Temperature"
                                            ],
                                            "options": {
                                                "0": "None",
                                                "1": "Inlet Temp",
                                                "2": "Room Temp",
                                                "3": "Exhaust Temp Ventilation Unit",
                                                "4": "Outside Temp",
                                                "5": "Evaporator Temp",
                                                "6": "Condenser Temp",
                                                "7": "Outside Temp Ventilation Unit",
                                                "8": "Evaporator Temp In",
                                                "9": "Evaporator Temp Out",
                                                "10": "General Purpose Temp"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 169,
                                            "name": "cc_t2",
                                            "name_orig": "CC T2",
                                            "desc": "Input function for cooling controller input temperature 2",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 10,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "I/O Config",
                                                "Cooling Controller",
                                                "Temperature"
                                            ],
                                            "options": {
                                                "0": "None",
                                                "1": "Inlet Temp",
                                                "2": "Room Temp",
                                                "3": "Exhaust Temp Ventilation Unit",
                                                "4": "Outside Temp",
                                                "5": "Evaporator Temp",
                                                "6": "Condenser Temp",
                                                "7": "Outside Temp Ventilation Unit",
                                                "8": "Evaporator Temp In",
                                                "9": "Evaporator Temp Out",
                                                "10": "General Purpose Temp"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 170,
                                            "name": "cc_t3",
                                            "name_orig": "CC T3",
                                            "desc": "Input function for cooling controller input temperature 3",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 10,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "I/O Config",
                                                "Cooling Controller",
                                                "Temperature"
                                            ],
                                            "options": {
                                                "0": "None",
                                                "1": "Inlet Temp",
                                                "2": "Room Temp",
                                                "3": "Exhaust Temp Ventilation Unit",
                                                "4": "Outside Temp",
                                                "5": "Evaporator Temp",
                                                "6": "Condenser Temp",
                                                "7": "Outside Temp Ventilation Unit",
                                                "8": "Evaporator Temp In",
                                                "9": "Evaporator Temp Out",
                                                "10": "General Purpose Temp"
                                            }
                                        }
                                    },
                                    {
                                        "type": "parameter",
                                        "isVisibleToTechnicalBasic": true,
                                        "data": {
                                            "id": 171,
                                            "name": "cc_t4",
                                            "name_orig": "CC T4",
                                            "desc": "Input function for cooling controller input temperature 4",
                                            "type": "enum",
                                            "min": 0,
                                            "max": 10,
                                            "res": 1,
                                            "default": 0,
                                            "showInTechnical": true,
                                            "unit": "",
                                            "guiGroupNameList": [
                                                "I/O Config",
                                                "Cooling Controller",
                                                "Temperature"
                                            ],
                                            "options": {
                                                "0": "None",
                                                "1": "Inlet Temp",
                                                "2": "Room Temp",
                                                "3": "Exhaust Temp Ventilation Unit",
                                                "4": "Outside Temp",
                                                "5": "Evaporator Temp",
                                                "6": "Condenser Temp",
                                                "7": "Outside Temp Ventilation Unit",
                                                "8": "Evaporator Temp In",
                                                "9": "Evaporator Temp Out",
                                                "10": "General Purpose Temp"
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Temperature",
                        "type": "category",
                        "id": "2a2bc3d5-9c6b-4e21-bd69-264e11870dbf",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 179,
                                    "name": "humidity_sensor_1_temperature",
                                    "name_orig": "Humidity Sensor 1 Temperature",
                                    "desc": "Input function for humidity sensor 1 temperature",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 180,
                                    "name": "humidity_sensor_2_temperature",
                                    "name_orig": "Humidity Sensor 2 Temperature",
                                    "desc": "Input function for humidity sensor 2 temperature",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 280,
                                    "name": "t1",
                                    "name_orig": "T1",
                                    "desc": "Input function for input temperature 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 281,
                                    "name": "t2",
                                    "name_orig": "T2",
                                    "desc": "Input function for input temperature 2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 282,
                                    "name": "t3",
                                    "name_orig": "T3",
                                    "desc": "Input function for input temperature 3",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 283,
                                    "name": "t4",
                                    "name_orig": "T4",
                                    "desc": "Input function for input temperature 4",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 284,
                                    "name": "t5",
                                    "name_orig": "T5",
                                    "desc": "Input function for input temperature 5\n (NA for AQC-LT)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 285,
                                    "name": "t6",
                                    "name_orig": "T6",
                                    "desc": "Input function for temperature input 5\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 286,
                                    "name": "t7",
                                    "name_orig": "T7",
                                    "desc": "Input function for temperature input 7\n (NA for AQC-L and AQC-P pcb rev. 5)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 287,
                                    "name": "t8",
                                    "name_orig": "T8",
                                    "desc": "Input function for temperature input 8\n (NA for AQC-L and AQC-P pcb rev. 5)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 10,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Temperature"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Inlet Temp",
                                        "2": "Room Temp",
                                        "3": "Exhaust Temp Ventilation Unit",
                                        "4": "Outside Temp",
                                        "5": "Evaporator Temp",
                                        "6": "Condenser Temp",
                                        "7": "Outside Temp Ventilation Unit",
                                        "8": "Evaporator Temp In",
                                        "9": "Evaporator Temp Out",
                                        "10": "General Purpose Temp}"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Analog inputs",
                        "type": "category",
                        "id": "787e11d7-ef52-4cf9-bbf5-089614ffa9b9",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 290,
                                    "name": "ai_1",
                                    "name_orig": "AI 1",
                                    "desc": "Input function for input voltage (0-10V) 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "CO2 Sensor 1",
                                        "2": "CO2 Sensor 2",
                                        "3": "CO2 Sensor 3",
                                        "4": "A-BMS Flow SetPoint",
                                        "5": "A-BMS Temperature SetPoint",
                                        "6": "0-10V Flow SetPoint",
                                        "7": "Humidity Indoor",
                                        "8": "Humidity Outdoor",
                                        "9": "Ext Start",
                                        "10": "A-BMS Start",
                                        "11": "PIR 1",
                                        "12": "Ventilation Floatswitch",
                                        "13": "Comfort Cooling Error",
                                        "14": "External Stop",
                                        "15": "Boost",
                                        "16": "Reset Filter Status",
                                        "17": "PIR 2",
                                        "18": "PIR 3",
                                        "19": "Differential Pressure Switch (NO)",
                                        "20": "Combined CO2 + TVOC + RH Room Sensor",
                                        "21": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 291,
                                    "name": "ai_2",
                                    "name_orig": "AI 2",
                                    "desc": "Input function for input voltage (0-10V) 2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "CO2 Sensor 1",
                                        "2": "CO2 Sensor 2",
                                        "3": "CO2 Sensor 3",
                                        "4": "A-BMS Flow SetPoint",
                                        "5": "A-BMS Temperature SetPoint",
                                        "6": "0-10V Flow SetPoint",
                                        "7": "Humidity Indoor",
                                        "8": "Humidity Outdoor",
                                        "9": "Ext Start",
                                        "10": "A-BMS Start",
                                        "11": "PIR 1",
                                        "12": "Ventilation Floatswitch",
                                        "13": "Comfort Cooling Error",
                                        "14": "External Stop",
                                        "15": "Boost",
                                        "16": "Reset Filter Status",
                                        "17": "PIR 2",
                                        "18": "PIR 3",
                                        "19": "Differential Pressure Switch (NO)",
                                        "20": "Combined CO2 + TVOC + RH Room Sensor",
                                        "21": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 292,
                                    "name": "ai_3",
                                    "name_orig": "AI 3",
                                    "desc": "Input function for voltage input (0-10V) 3\n (NA for AQC-L pcb rev. < 8 )",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "CO2 Sensor 1",
                                        "2": "CO2 Sensor 2",
                                        "3": "CO2 Sensor 3",
                                        "4": "A-BMS Flow SetPoint",
                                        "5": "A-BMS Temperature SetPoint",
                                        "6": "0-10V Flow SetPoint",
                                        "7": "Humidity Indoor",
                                        "8": "Humidity Outdoor",
                                        "9": "Ext Start",
                                        "10": "A-BMS Start",
                                        "11": "PIR 1",
                                        "12": "Ventilation Floatswitch",
                                        "13": "Comfort Cooling Error",
                                        "14": "External Stop",
                                        "15": "Boost",
                                        "16": "Reset Filter Status",
                                        "17": "PIR 2",
                                        "18": "PIR 3",
                                        "19": "Differential Pressure Switch (NO)",
                                        "20": "Combined CO2 + TVOC + RH Room Sensor",
                                        "21": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 293,
                                    "name": "ai_4",
                                    "name_orig": "AI 4",
                                    "desc": "Input function for voltage input (0-10V) 4\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "CO2 Sensor 1",
                                        "2": "CO2 Sensor 2",
                                        "3": "CO2 Sensor 3",
                                        "4": "A-BMS Flow SetPoint",
                                        "5": "A-BMS Temperature SetPoint",
                                        "6": "0-10V Flow SetPoint",
                                        "7": "Humidity Indoor",
                                        "8": "Humidity Outdoor",
                                        "9": "Ext Start",
                                        "10": "A-BMS Start",
                                        "11": "PIR 1",
                                        "12": "Ventilation Floatswitch",
                                        "13": "Comfort Cooling Error",
                                        "14": "External Stop",
                                        "15": "Boost",
                                        "16": "Reset Filter Status",
                                        "17": "PIR 2",
                                        "18": "PIR 3",
                                        "19": "Differential Pressure Switch (NO)",
                                        "20": "Combined CO2 + TVOC + RH Room Sensor",
                                        "21": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 294,
                                    "name": "ai_5",
                                    "name_orig": "AI 5",
                                    "desc": "Input function for input voltage (0-10V) 5\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 21,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "CO2 Sensor 1",
                                        "2": "CO2 Sensor 2",
                                        "3": "CO2 Sensor 3",
                                        "4": "A-BMS Flow SetPoint",
                                        "5": "A-BMS Temperature SetPoint",
                                        "6": "0-10V Flow SetPoint",
                                        "7": "Humidity Indoor",
                                        "8": "Humidity Outdoor",
                                        "9": "Ext Start",
                                        "10": "A-BMS Start",
                                        "11": "PIR 1",
                                        "12": "Ventilation Floatswitch",
                                        "13": "Comfort Cooling Error",
                                        "14": "External Stop",
                                        "15": "Boost",
                                        "16": "Reset Filter Status",
                                        "17": "PIR 2",
                                        "18": "PIR 3",
                                        "19": "Differential Pressure Switch (NO)",
                                        "20": "Combined CO2 + TVOC + RH Room Sensor",
                                        "21": "Comfort Cooling Floatswitch"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Digital inputs",
                        "type": "category",
                        "id": "91e11ce7-27ad-41fc-9341-7c2f1873036e",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 296,
                                    "name": "di_1",
                                    "name_orig": "DI 1",
                                    "desc": "Input function for digital input 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 297,
                                    "name": "di_2",
                                    "name_orig": "DI 2",
                                    "desc": "Input function for digital input 2\n (special input on AQC-L, see electrical documentation)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 298,
                                    "name": "di_3",
                                    "name_orig": "DI 3",
                                    "desc": "Input function for digital input 3\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 299,
                                    "name": "di_4",
                                    "name_orig": "DI 4",
                                    "desc": "Input function for digital input 4\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 300,
                                    "name": "di_5",
                                    "name_orig": "DI 5",
                                    "desc": "Input function for digital input 5\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 301,
                                    "name": "di_6",
                                    "name_orig": "DI 6",
                                    "desc": "Input function for digital input 6\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 12,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Ext Start",
                                        "2": "A-BMS Start",
                                        "3": "PIR 1",
                                        "4": "Ventilation Floatswitch",
                                        "5": "Comfort Cooling Error",
                                        "6": "External Stop",
                                        "7": "Boost",
                                        "8": "Reset Filter Status",
                                        "9": "PIR 2",
                                        "10": "PIR 3",
                                        "11": "Differential Pressure Switch (NO)",
                                        "12": "Comfort Cooling Floatswitch"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Digital outputs",
                        "type": "category",
                        "id": "2ecf3b3e-4b1c-4a7b-9373-1474fcc5fcdc",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 303,
                                    "name": "do_1_open_collector",
                                    "name_orig": "DO 1 (Open Collector)",
                                    "desc": "Output function for open-collector output 1\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 304,
                                    "name": "do_2_open_collector",
                                    "name_orig": "DO 2 (Open Collector)",
                                    "desc": "Output function for open-collector output 2\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 305,
                                    "name": "do_3_open_collector",
                                    "name_orig": "DO 3 (Open Collector)",
                                    "desc": "Output function for open-collector output 3\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 306,
                                    "name": "do_4_open_collector",
                                    "name_orig": "DO 4 (Open Collector)",
                                    "desc": "Output function for open-collector output 4\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 307,
                                    "name": "do_5_open_collector",
                                    "name_orig": "DO 5 (Open Collector)",
                                    "desc": "Output function for open-collector output 5\n (NA for AQC-L and AQC-P pcb rev. 5)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 308,
                                    "name": "do_k1_relay",
                                    "name_orig": "DO K1 (Relay)",
                                    "desc": "Output function for relay K1\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 309,
                                    "name": "do_k2_relay",
                                    "name_orig": "DO K2 (Relay)",
                                    "desc": "Output function for relay K2\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 310,
                                    "name": "do_k3_relay",
                                    "name_orig": "DO K3 (Relay)",
                                    "desc": "Output function for relay K3\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 311,
                                    "name": "do_k4_relay",
                                    "name_orig": "DO K4 (Relay)",
                                    "desc": "Output function for relay K4\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 312,
                                    "name": "do_k5_relay",
                                    "name_orig": "DO K5 (Relay)",
                                    "desc": "Output function for relay K5\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 313,
                                    "name": "do_k6_relay",
                                    "name_orig": "DO K6 (Relay)",
                                    "desc": "Output function for relay K6\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 314,
                                    "name": "do_k7_relay",
                                    "name_orig": "DO K7 (Relay)",
                                    "desc": "Output function for relay K7\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 315,
                                    "name": "do_k8_relay",
                                    "name_orig": "DO K8 (Relay)",
                                    "desc": "Output function for relay K8\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 316,
                                    "name": "do_k9_relay",
                                    "name_orig": "DO K9 (Relay)",
                                    "desc": "Output function for relay K9\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 317,
                                    "name": "do_k10_relay",
                                    "name_orig": "DO K10 (Relay)",
                                    "desc": "Output function for relay K10\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 318,
                                    "name": "do_k11_relay",
                                    "name_orig": "DO K11 (Relay)",
                                    "desc": "Output function for relay K11\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 319,
                                    "name": "do_k12_relay",
                                    "name_orig": "DO K12 (Relay)",
                                    "desc": "Output function for relay K12\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 320,
                                    "name": "do_led_1",
                                    "name_orig": "DO LED 1",
                                    "desc": "Output function for LED 1\n (NA for AQC-P pcb rev. 5)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 321,
                                    "name": "do_led_2",
                                    "name_orig": "DO LED 2",
                                    "desc": "Output function for LED 2\n (NA for AQC-P pcb rev. 5)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 24,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Digital outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Main Damper - Time Controlled L2",
                                        "2": "Main Damper - Time Controlled L3",
                                        "3": "Main Damper - Spring Self Return",
                                        "4": "Bypass Damper - Time Controlled L2",
                                        "5": "Bypass Damper - Time Controlled L3",
                                        "6": "Comfort Heater - Water",
                                        "7": "Comfort Heater - Electric",
                                        "8": "Comfort Cooling",
                                        "9": "PreHeater",
                                        "10": "Alarm",
                                        "11": "LED - Debug",
                                        "12": "LED - Filter Alarm",
                                        "13": "LED - Filter Warning",
                                        "14": "LED - Filter OK",
                                        "15": "LED - Indicator",
                                        "16": "Standby Relay",
                                        "17": "CC Standby Relay",
                                        "18": "Condensate Frost Protection",
                                        "19": "Flow Distribution Valve - Time Controlled L2",
                                        "20": "Flow Distribution Valve - Time Controlled L3",
                                        "21": "LED - Status",
                                        "22": "Window",
                                        "23": "CC Water - Time Controlled L2",
                                        "24": "CC Water - Time Controlled L3"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Analog outputs",
                        "type": "category",
                        "id": "f87f11b8-9004-4bb9-ac12-5c55814a1163",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 322,
                                    "name": "ao_1",
                                    "name_orig": "AO 1",
                                    "desc": "Output function for Analog out 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 323,
                                    "name": "ao_2",
                                    "name_orig": "AO 2",
                                    "desc": "Output function for Analog out 2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 324,
                                    "name": "ao_3",
                                    "name_orig": "AO 3",
                                    "desc": "Output function for Analog out 3",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 325,
                                    "name": "ao_4",
                                    "name_orig": "AO 4",
                                    "desc": "Output function for Analog out 4\n (NA for AQC-L pcb rev. < 8 )",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 326,
                                    "name": "ao_5",
                                    "name_orig": "AO 5",
                                    "desc": "Output function for Analog out 5\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 327,
                                    "name": "ao_6",
                                    "name_orig": "AO 6",
                                    "desc": "Output function for Analog out 6\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 328,
                                    "name": "ao_7",
                                    "name_orig": "AO 7",
                                    "desc": "Output function for Analog out 7\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 35,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Analog outputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan",
                                        "4": "Bypass Damper",
                                        "5": "Inlet Damper",
                                        "6": "LoopTest Ext. Pressure",
                                        "7": "Test Ramp",
                                        "8": "Main Damper - Time Controlled L2",
                                        "9": "Main Damper - Time Controlled L3",
                                        "10": "Main Damper - Spring Self Return",
                                        "11": "Bypass Damper - Time Controlled L2",
                                        "12": "Bypass Damper - Time Controlled L3",
                                        "13": "Comfort Heater - Water",
                                        "14": "Comfort Heater - Electric",
                                        "15": "Comfort Cooling On/Off",
                                        "16": "PreHeater",
                                        "17": "Alarm",
                                        "18": "LED - Debug",
                                        "19": "LED - Filter Alarm",
                                        "20": "LED - Filter Warning",
                                        "21": "LED - Filter OK",
                                        "22": "Window",
                                        "23": "LED - Indicator",
                                        "24": "Cooling Inverter",
                                        "25": "Flow Distribution Valve",
                                        "26": "Standby Relay",
                                        "27": "CC Standby Relay",
                                        "28": "Adaptive Inlet",
                                        "29": "Condensate Frost Protection",
                                        "30": "Flow Distribution Valve - Time Controlled L2",
                                        "31": "Flow Distribution Valve - Time Controlled L3",
                                        "32": "LED - Status",
                                        "33": "CC Water - Time Controlled L2",
                                        "34": "CC Water - Time Controlled L3",
                                        "35": "Comfort Cooling 0-10V"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        "name": "Tacho inputs",
                        "type": "category",
                        "id": "29a31293-77df-4196-9e28-c841db7f096d",
                        "isCollapsed": true,
                        "isVisibleToTechnicalBasic": true,
                        "children": [
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 329,
                                    "name": "tacho_input_1",
                                    "name_orig": "Tacho Input 1",
                                    "desc": "Function of Tacho input 1",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Tacho inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 330,
                                    "name": "tacho_input_2",
                                    "name_orig": "Tacho Input 2",
                                    "desc": "Function of Tacho input 2",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Tacho inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan"
                                    }
                                }
                            },
                            {
                                "type": "parameter",
                                "isVisibleToTechnicalBasic": true,
                                "data": {
                                    "id": 331,
                                    "name": "tacho_input_3",
                                    "name_orig": "Tacho Input 3",
                                    "desc": "Function of Tacho input 3\n (NA for AQC-L)",
                                    "type": "enum",
                                    "min": 0,
                                    "max": 3,
                                    "res": 1,
                                    "default": 0,
                                    "showInTechnical": true,
                                    "unit": "",
                                    "guiGroupNameList": [
                                        "I/O Config",
                                        "Tacho inputs"
                                    ],
                                    "options": {
                                        "0": "None",
                                        "1": "Supply Fan",
                                        "2": "Extraction Fan",
                                        "3": "Recirculating Fan"
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Simulation",
                "type": "category",
                "id": "4b6e0957-7ae0-4261-bf7a-23c81530a186",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 370,
                            "name": "simulate_mode",
                            "name_orig": "Simulate Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 371,
                            "name": "simulated_it",
                            "name_orig": "Simulated IT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 372,
                            "name": "simulated_rt",
                            "name_orig": "Simulated RT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 373,
                            "name": "simulated_gpt",
                            "name_orig": "Simulated GPT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 374,
                            "name": "simulated_ot",
                            "name_orig": "Simulated OT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 375,
                            "name": "simulated_evt",
                            "name_orig": "Simulated EVT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 376,
                            "name": "simulated_cdt",
                            "name_orig": "Simulated CdT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 377,
                            "name": "simulated_otv",
                            "name_orig": "Simulated OTV",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 378,
                            "name": "simulated_etv",
                            "name_orig": "Simulated ETV",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 379,
                            "name": "simulated_am_float_switch",
                            "name_orig": "Simulated AM Float Switch",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 380,
                            "name": "simulated_cc_float_switch",
                            "name_orig": "Simulated CC Float Switch",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 381,
                            "name": "simulated_external_start_input",
                            "name_orig": "Simulated External Start Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 382,
                            "name": "simulated_abms_start",
                            "name_orig": "Simulated ABMS Start",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 383,
                            "name": "simulated_pir_input",
                            "name_orig": "Simulated Pir Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 384,
                            "name": "simulated_external_stop_input",
                            "name_orig": "Simulated External Stop Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 385,
                            "name": "simulated_abms_temperature_setpoint",
                            "name_orig": "Simulated ABMS Temperature Setpoint",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 386,
                            "name": "simulated_abms_flow_setpoint",
                            "name_orig": "Simulated ABMS Flow Setpoint",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 387,
                            "name": "simulated_internal_co2",
                            "name_orig": "Simulated Internal CO2",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 800,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 388,
                            "name": "simulated_analog_co2_1",
                            "name_orig": "Simulated Analog CO2 1",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 389,
                            "name": "simulated_analog_co2_2",
                            "name_orig": "Simulated Analog CO2 2",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 390,
                            "name": "simulated_analog_co2_3",
                            "name_orig": "Simulated Analog CO2 3",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 391,
                            "name": "simulated_0_10v_flow",
                            "name_orig": "Simulated 0-10V Flow",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 392,
                            "name": "simulated_supply_fan",
                            "name_orig": "Simulated Supply Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 393,
                            "name": "simulated_extraction_fan",
                            "name_orig": "Simulated Extraction Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 394,
                            "name": "simulated_recirculating_fan",
                            "name_orig": "Simulated Recirculating Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 395,
                            "name": "simulated_supply_airflow",
                            "name_orig": "Simulated Supply Airflow",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 397,
                            "name": "simulated_extraction_airflow",
                            "name_orig": "Simulated Extraction Airflow",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 398,
                            "name": "simulated_relative_humidity_supply",
                            "name_orig": "Simulated Relative Humidity Supply",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 399,
                            "name": "simulated_relative_humidity_extraction",
                            "name_orig": "Simulated Relative Humidity Extraction",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 407,
                            "name": "simulated_evt_in",
                            "name_orig": "Simulated EVT_IN",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 408,
                            "name": "simulated_evt_out",
                            "name_orig": "Simulated EVT_OUT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 409,
                            "name": "simulated_hotgas_temperature",
                            "name_orig": "Simulated Hotgas Temperature",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 50,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 666,
                            "name": "simulated_airquality",
                            "name_orig": "Simulated Airquality",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 200,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    }
                ]
            },
            {
                "name": "Performance Test Results",
                "type": "category",
                "id": "8234029d-e537-4519-9890-57520c2534eb",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 646,
                            "name": "p_stop",
                            "name_orig": "P stop",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 300,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 647,
                            "name": "p_sf_total",
                            "name_orig": "P SF total",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 648,
                            "name": "p_sf",
                            "name_orig": "P SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 649,
                            "name": "p_ef_total",
                            "name_orig": "P EF total",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 650,
                            "name": "p_ef",
                            "name_orig": "P EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 651,
                            "name": "p_fans",
                            "name_orig": "P fans",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 652,
                            "name": "dp_sf",
                            "name_orig": "dP SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 653,
                            "name": "q_sf",
                            "name_orig": "Q SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 3200,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 654,
                            "name": "q_sf_deviation",
                            "name_orig": "Q SF deviation",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 655,
                            "name": "dp_ef",
                            "name_orig": "dP EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 656,
                            "name": "q_ef",
                            "name_orig": "Q EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 3200,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 657,
                            "name": "q_ef_deviation",
                            "name_orig": "Q EF deviation",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 658,
                            "name": "sfp",
                            "name_orig": "SFP",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "J/m³",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    }
                ]
            },
            {
                "name": "All Parameters",
                "type": "category",
                "id": "5fa389d5-dcc3-48f0-adc7-c4c15215a2f0",
                "isCollapsed": true,
                "isVisibleToTechnicalBasic": true,
                "children": [
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 1,
                            "name": "rqf",
                            "name_orig": "RQF",
                            "desc": "Requested Flow (Calculated from all flow inputs)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 2,
                            "name": "rqt",
                            "name_orig": "RQT",
                            "desc": "Requested Temperature (Calculated from all temperature inputs)\nThis value does not contain \"DISPLACEMENT\" offset (see parameter 254)",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 0.1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 3,
                            "name": "default_airflow",
                            "name_orig": "Default Airflow",
                            "desc": "Default airflow, value loaded power up, and eventually during stop (depend on settings)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Operation",
                                "Default Setpoints"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 4,
                            "name": "default_temperature",
                            "name_orig": "Default Temperature",
                            "desc": "Default temperature, value loaded power up, and eventually during stop (depend on settings)",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Default Setpoints"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 6,
                            "name": "loop1",
                            "name_orig": "Loop1",
                            "desc": "Which output to cycle through in Loop1",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ],
                            "options": {
                                "0": "Bp",
                                "1": "ExtP",
                                "2": "Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 7,
                            "name": "user_tool_access_level",
                            "name_orig": "User Tool Access level",
                            "desc": "Used by Viva and Orbit displays only",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "Group",
                                "1": "All"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 8,
                            "name": "temp_0_2v",
                            "name_orig": "Temp 0-2V",
                            "desc": "CTS &#176;C inlet air 0 – 2 V (&#176;C decided from CTS)",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 9,
                            "name": "temp_2_4v",
                            "name_orig": "Temp 2-4V",
                            "desc": "CTS &#176;C inlet air 2 – 4 V (&#176;C decided from CTS)",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 10,
                            "name": "temp_4_6v",
                            "name_orig": "Temp 4-6V",
                            "desc": "CTS &#176;C inlet air 4 – 6 V (&#176;C decided from CTS)",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 21,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 11,
                            "name": "temp_6_8v",
                            "name_orig": "Temp 6-8V",
                            "desc": "CTS &#176;C inlet air 6 – 8 V (&#176;C decided from CTS)",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 22,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 12,
                            "name": "temp_8_10v",
                            "name_orig": "Temp 8-10V",
                            "desc": "CTS &#176;C inlet air 8 – 10 V (&#176;C decided from CTS)",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 23,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 13,
                            "name": "bp_installed",
                            "name_orig": "BP Installed",
                            "desc": "Is a by-pass damper installed on the unit, and what type is it?\n(VC: Voltage Controlled / TC - Time Controlled)",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ],
                            "options": {
                                "0": "No",
                                "1": "VC",
                                "2": "TC"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 14,
                            "name": "bp_engage",
                            "name_orig": "BP Engage",
                            "desc": "Engage Automatic by-pass Control",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 15,
                            "name": "temp_diff",
                            "name_orig": "Temp Diff",
                            "desc": "By – pass &#176;C difference  (Temperature difference for starting by-pass)",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.5,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 16,
                            "name": "min_temp",
                            "name_orig": "Min Temp",
                            "desc": "By – pass minimum outside &#176;C (Minimum outside &#176;C to start by-pass)",
                            "type": "decimal",
                            "min": -10,
                            "max": 25,
                            "res": 0.5,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 17,
                            "name": "ht_rt_flow_increment",
                            "name_orig": "HT RT Flow Increment",
                            "desc": "High Temp Room Temp increment",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 18,
                            "name": "loop2",
                            "name_orig": "Loop2",
                            "desc": "Which output to cycle through in Loop2",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ],
                            "options": {
                                "0": "Bp",
                                "1": "ExtP",
                                "2": "Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 19,
                            "name": "t_measure",
                            "name_orig": "T Measure",
                            "desc": "By - pass mean measuring (Time measuring before acting)",
                            "type": "int",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 20,
                            "name": "interval",
                            "name_orig": "Interval",
                            "desc": "By-pass damper interval – open and close",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 21,
                            "name": "wait",
                            "name_orig": "Wait",
                            "desc": "Wait time after regulating by-pass damper",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 15,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 22,
                            "name": "log_mode",
                            "name_orig": "Log Mode",
                            "desc": "Log Mode (Normal = Runtime, Survey = Unit Survey)\nN.B.: Unit Survey empty log, and log until end of survey",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "Norm.",
                                "2": "Start Survey",
                                "3": "Survey Running"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 23,
                            "name": "cool_flow",
                            "name_orig": "Cool Flow",
                            "desc": "Minimum flow when activating cooling unit",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 24,
                            "name": "high_room_temperature_high_limit",
                            "name_orig": "High Room Temperature, High Limit",
                            "desc": "High Room Temperature. Increase airflow and lower inlet temperature",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 25,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 25,
                            "name": "high_room_temperature_low_limit",
                            "name_orig": "High Room Temperature, Low Limit",
                            "desc": "Low Room Temperature. Set normal airflow and set normal inlet temperature",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 24,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 26,
                            "name": "bp_fan_cor",
                            "name_orig": "BP Fan Cor",
                            "desc": "",
                            "type": "int",
                            "min": -99,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 27,
                            "name": "pir_afterrun_time",
                            "name_orig": "PIR Afterrun Time",
                            "desc": "The amount of time the PIR start signal continuous to be active after last precence detection",
                            "type": "int",
                            "min": 0,
                            "max": 1080,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Operation",
                                "PIR"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 28,
                            "name": "rt_timer",
                            "name_orig": "RT Timer",
                            "desc": "RT Timer. Wait state when software changes Inlet Temperature",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 29,
                            "name": "it_hyst",
                            "name_orig": "IT Hyst.",
                            "desc": "Hysteresis for regulating damper up/down due to low/high inlet temperature",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 30,
                            "name": "co2_sensor_installed",
                            "name_orig": "CO2 Sensor Installed",
                            "desc": "CO2 sensor installed (Mode for overwriting setpoint)",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "CO2"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 31,
                            "name": "rt_diff_high",
                            "name_orig": "RT Diff High",
                            "desc": "High temp: Necessary difference between room temp and inlet temp before forcing 100% ventilations level",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 32,
                            "name": "rt_diff_low",
                            "name_orig": "RT Diff Low",
                            "desc": "High temp: Minimum difference between room temp and inlet temp before resetting to normal ventilation level.",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 33,
                            "name": "ot_rt_diff",
                            "name_orig": "OT-RT Diff",
                            "desc": "High temp: Max difference between outside temp and room temp before decreasing by-pass damper opening",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 34,
                            "name": "exit_timer",
                            "name_orig": "Exit Timer",
                            "desc": "High temp: Shutdown timer. Need to run out before closing by-pass damper, when damper level reaches 0V",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 35,
                            "name": "loop3",
                            "name_orig": "Loop3",
                            "desc": "Which output to cycle through in Loop3",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ],
                            "options": {
                                "0": "Bp",
                                "1": "ExtP",
                                "2": "Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 36,
                            "name": "measuring_time",
                            "name_orig": "Measuring Time",
                            "desc": "Measuring Time (Inclusive setteling time)",
                            "type": "int",
                            "min": 1,
                            "max": 255,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 37,
                            "name": "bp_initial",
                            "name_orig": "BP Initial",
                            "desc": "Initial By-pass position",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 38,
                            "name": "t_evaporate",
                            "name_orig": "T Evaporate",
                            "desc": "Evaporation Time between condensate tests",
                            "type": "int",
                            "min": 0,
                            "max": 60,
                            "res": 1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 39,
                            "name": "supply_airflow_reduction",
                            "name_orig": "Supply Airflow Reduction",
                            "desc": "Condens sensor: % reducing intake airflow (% reducing intake airflow)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 40,
                            "name": "t_soft_start",
                            "name_orig": "T Soft Start",
                            "desc": "Condens sensor: waiting for normal airflow (Time to go before fan 1 run at normal speed after there is no longer condens signal)",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 41,
                            "name": "pid_log",
                            "name_orig": "PID Log",
                            "desc": "PID Log on/off",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog"
                            ],
                            "options": {
                                "0": "OFF",
                                "1": "ON"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 42,
                            "name": "bp_final",
                            "name_orig": "BP Final",
                            "desc": "Final By-pass position (only if number of cycles > 1)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 43,
                            "name": "bp_cycles",
                            "name_orig": "BP Cycles",
                            "desc": "Number of cycles through the By-pass position loop",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 44,
                            "name": "extp_initial",
                            "name_orig": "ExtP Initial",
                            "desc": "Initial External Pressure Actuator Position",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 45,
                            "name": "allow_start_by_external_start",
                            "name_orig": "Allow Start by External Start",
                            "desc": "Start by External Start, also requires that External Start is a start signal",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 46,
                            "name": "not_in_use",
                            "name_orig": "Not In Use",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 0,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 47,
                            "name": "not_in_use",
                            "name_orig": "Not In Use",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 0,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 48,
                            "name": "humidity_control_installed",
                            "name_orig": "Humidity Control Installed",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Humidity"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 49,
                            "name": "humidity_control_enabled",
                            "name_orig": "Humidity Control Enabled",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 50,
                            "name": "manual_override_time",
                            "name_orig": "Manual Override Time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 18,
                            "res": 1,
                            "default": 12,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 51,
                            "name": "filter_test_mode",
                            "name_orig": "Filter Test Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 4,
                            "res": 1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "Timer",
                                "2": "Tacho",
                                "3": "Timer And Tacho",
                                "4": "DP-switch only"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 52,
                            "name": "life_span_warning",
                            "name_orig": "Life Span Warning",
                            "desc": "Time before filter Warning in hours",
                            "type": "int",
                            "min": 0,
                            "max": 8760,
                            "res": 1,
                            "default": 1500,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 53,
                            "name": "st_filter_test_max_difference",
                            "name_orig": "ST Filter Test Max Difference",
                            "desc": "Max difference in tacho measurement compared with last test",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 54,
                            "name": "current_filter_state",
                            "name_orig": "Current Filter State",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ],
                            "options": {
                                "0": "green",
                                "1": "yellow",
                                "2": "red"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 55,
                            "name": "life_span_alarm",
                            "name_orig": "Life Span Alarm",
                            "desc": "Time before filter Alarm in hours",
                            "type": "int",
                            "min": 0,
                            "max": 8760,
                            "res": 1,
                            "default": 2000,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 56,
                            "name": "extp_final",
                            "name_orig": "ExtP Final",
                            "desc": "Final External Pressure Actuator Position (only if number of cycles > 1)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 57,
                            "name": "disable_low_temp_alarm",
                            "name_orig": "Disable Low Temp Alarm",
                            "desc": "Disable the Low Temp Alarm for X minutes after leaving the Start Procedure",
                            "type": "int",
                            "min": 0,
                            "max": 60,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp Alarm"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 58,
                            "name": "retry_low_temp_alarm",
                            "name_orig": "Retry Low Temp Alarm",
                            "desc": "Time Between Automated Start Attemps if unit was stopped due to Low Temp Alarm",
                            "type": "int",
                            "min": 0,
                            "max": 18,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp Alarm"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 59,
                            "name": "min_fan_voltage",
                            "name_orig": "Min Fan Voltage",
                            "desc": "Minimum allowed fan speed",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 1.2,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 60,
                            "name": "low_inlet",
                            "name_orig": "Low Inlet",
                            "desc": "Low temp: &#176;C difference for reducing fan level (&#176;C difference between decided and actual &#176;C before the fan level is reduced)",
                            "type": "decimal",
                            "min": 0,
                            "max": 60,
                            "res": 0.5,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 61,
                            "name": "intake_airflow_reduction",
                            "name_orig": "Intake Airflow Reduction",
                            "desc": "Low temp: % reducing intake airflow (% the intake airflow is reduced with when the inlet  &#176;C is low)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 62,
                            "name": "min_outlet",
                            "name_orig": "Min Outlet",
                            "desc": "Low temp: Minimum outlet (Lowest accepted &#176;C outlet air before the unit is stopped and alarm is set)",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 0.5,
                            "default": -2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 63,
                            "name": "wait_low",
                            "name_orig": "Wait Low",
                            "desc": "Wait time in low inlet: General wait time in low inlet process",
                            "type": "int",
                            "min": 0,
                            "max": 60,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 64,
                            "name": "low_outlet",
                            "name_orig": "Low Outlet",
                            "desc": "Low temp: Low outlet temperature. Decrease Vent 1 speed",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 0.5,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 65,
                            "name": "inlet_increment",
                            "name_orig": "Inlet Increment",
                            "desc": "Low temp: Inlet temp. rising. Increase Vent 1 speed",
                            "type": "decimal",
                            "min": 0,
                            "max": 60,
                            "res": 0.5,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 66,
                            "name": "outlet_increment",
                            "name_orig": "Outlet Increment",
                            "desc": "Low temp: Outlet temp. rising. Increase Vent 1 speed",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 0.5,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 67,
                            "name": "ph__max_etv",
                            "name_orig": "PH  Max ETV",
                            "desc": "Heating surface: Preheat temperature. Activate pre-heating surface below this temperature",
                            "type": "decimal",
                            "min": -20,
                            "max": 50,
                            "res": 0.5,
                            "default": 4,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 68,
                            "name": "ph_sf_reduction",
                            "name_orig": "PH SF Reduction",
                            "desc": "Heating surface: Preheat airflow. Vent 1 has to be decreased to this airflow before activating pre-heating surface.",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 90,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 69,
                            "name": "min_inlet",
                            "name_orig": "Min Inlet",
                            "desc": "Low temp : Minimum inlet &#176;C (Lowest accepted &#176;C inlet air before the unit is stopped and alarm is set)",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 70,
                            "name": "flow_alarm",
                            "name_orig": "Flow Alarm",
                            "desc": "Alarm flow difference (Difference betweed set and actual airflow before flow alarm is set)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 5,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 71,
                            "name": "normal_interval",
                            "name_orig": "Normal Interval",
                            "desc": "Runtime Log Interval",
                            "type": "int",
                            "min": 1,
                            "max": 9999,
                            "res": 1,
                            "default": 300,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 72,
                            "name": "reboot",
                            "name_orig": "Reboot",
                            "desc": "Reboots the unit",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 73,
                            "name": "st_settling_time",
                            "name_orig": "ST Settling Time",
                            "desc": "Time for the flow to settle during the filter test",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 74,
                            "name": "unit_survey_period",
                            "name_orig": "Unit Survey Period",
                            "desc": "Unit Survey Log Time Period, Count Down, to show remaining time.\nShown as h up to 240 h above shown in days.",
                            "type": "int",
                            "min": 1,
                            "max": 32300,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 75,
                            "name": "nc_it",
                            "name_orig": "NC IT",
                            "desc": "Night Cooling: Fixed Inlet Temperature during Night Cooling",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 16,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "D-BMS"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 76,
                            "name": "unit_survey_interval",
                            "name_orig": "Unit Survey Interval",
                            "desc": "Unit Survey Log Interval",
                            "type": "int",
                            "min": 1,
                            "max": 3600,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Datalog"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 77,
                            "name": "bp_run_time",
                            "name_orig": "BP Run Time",
                            "desc": "RunTime, is the time it takes the actuator to move from end2end (both Voltage and Time Controlled)",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 78,
                            "name": "nc_min_ot",
                            "name_orig": "NC Min OT",
                            "desc": "",
                            "type": "decimal",
                            "min": -20,
                            "max": 50,
                            "res": 0.5,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 79,
                            "name": "ch_water_soft_start",
                            "name_orig": "CH Water Soft Start",
                            "desc": "Case a water comfort heater is installed, start gradually over this period",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 180,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 80,
                            "name": "nc_flow",
                            "name_orig": "NC Flow",
                            "desc": "Night cooling: Fixed Airflow during Night Cooling",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "D-BMS"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 81,
                            "name": "nc_high_limit",
                            "name_orig": "NC High Limit",
                            "desc": "Night cooling: High limit. Daytime temperature to initiate Night Cooling",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 26,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 82,
                            "name": "nc_low_limit",
                            "name_orig": "NC Low Limit",
                            "desc": "Night cooling: Low limit. Daytime temperature to maintain Night Cooling",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 23,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 83,
                            "name": "min_flow",
                            "name_orig": "Min Flow",
                            "desc": "Minimum fan 1 speed when decreasing speed due to low temperature",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp Alarm"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 84,
                            "name": "language",
                            "name_orig": "Language",
                            "desc": "1=English, 2=Danish, 3=Dutch, 4=German, 5=Swedish, 6=French, 7=Norwegian, 8=Finnish",
                            "type": "enum",
                            "min": 1,
                            "max": 8,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "1": "English",
                                "2": "Danish",
                                "3": "Dutch",
                                "4": "German",
                                "5": "Swedish",
                                "6": "French",
                                "7": "Norwegian",
                                "8": "Finnish"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 85,
                            "name": "comfort_heater",
                            "name_orig": "Comfort Heater",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Heating Surfaces"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Electric",
                                "2": "Water"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 86,
                            "name": "hm_flow",
                            "name_orig": "HM Flow",
                            "desc": "Holiday Airflow Level",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 5,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Operation",
                                "Holiday Mode"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 87,
                            "name": "hm_temperature",
                            "name_orig": "HM Temperature",
                            "desc": "Holiday Ventilation Temperature",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Holiday Mode"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 88,
                            "name": "extp_cycles",
                            "name_orig": "ExtP Cycles",
                            "desc": "Number of cycles through the External Pressure actuator positon loop",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 89,
                            "name": "fan_initial",
                            "name_orig": "Fan Initial",
                            "desc": "Initial Fan % (both fans. % according to max motor voltage)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 90,
                            "name": "start_dependency",
                            "name_orig": "Start Dependency",
                            "desc": "Whether the start priorities are independent or dependent",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "Independent",
                                "1": "Dependent",
                                "2": "1 Dependent + Independent"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 91,
                            "name": "start_priority_1",
                            "name_orig": "Start Priority 1",
                            "desc": "Start priority 1",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 92,
                            "name": "start_priority_2",
                            "name_orig": "Start Priority 2",
                            "desc": "Start priority 2",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 93,
                            "name": "start_priority_3",
                            "name_orig": "Start Priority 3",
                            "desc": "Start priority 3",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 94,
                            "name": "start_priority_4",
                            "name_orig": "Start Priority 4",
                            "desc": "Start priority 4",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 95,
                            "name": "start_priority_5",
                            "name_orig": "Start Priority 5",
                            "desc": "Start priority 5",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 7,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 96,
                            "name": "started_by",
                            "name_orig": "Started By",
                            "desc": "Started By: Contains the Highest active start priority",
                            "type": "enum",
                            "min": 0,
                            "max": 14,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "off",
                                "1": "A-BMS",
                                "2": "Panel",
                                "3": "PIR",
                                "4": "External start",
                                "5": "CO2",
                                "6": "Dependent",
                                "7": "Halted",
                                "8": "0-10V",
                                "9": "D-BMS",
                                "10": "Timer",
                                "11": "Boost",
                                "12": "TVOC",
                                "13": "Room temperature",
                                "14": "Airlinq Online"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 97,
                            "name": "fan_final",
                            "name_orig": "Fan Final",
                            "desc": "Final Fan % (only if number of cycles > 1)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 98,
                            "name": "fan_cycles",
                            "name_orig": "Fan Cycles",
                            "desc": "Number of cycles through the Fan % loop",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 99,
                            "name": "bp_tc_open",
                            "name_orig": "BP TC: Open",
                            "desc": "Open Damper, the direction the actuator rotates, to open the damper",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ],
                            "options": {
                                "0": "CW",
                                "1": "CCW"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 100,
                            "name": "udf",
                            "name_orig": "UDF",
                            "desc": "User Defined Air Flow (flow menu)",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 101,
                            "name": "minimum_flow_percent",
                            "name_orig": "Minimum Flow %",
                            "desc": "Minimum Airflow",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Operation",
                                "Control Limits"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 102,
                            "name": "maximum_flow_percent",
                            "name_orig": "Maximum Flow %",
                            "desc": "Maximum Airflow, can be overrided by internal procedures",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Operation",
                                "Control Limits"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 103,
                            "name": "udt",
                            "name_orig": "UDT",
                            "desc": "User Defined Inlet Temperature (temperature menu)",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 0.1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 104,
                            "name": "temperature_setpoint_minimum",
                            "name_orig": "Temperature Setpoint Minimum",
                            "desc": "Indbl&#230;sningstemperatur min",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 14,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Control Limits"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 105,
                            "name": "temperature_setpoint_maximum",
                            "name_orig": "Temperature Setpoint Maximum",
                            "desc": "Indbl&#230;sningstemperatur max",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Control Limits"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 106,
                            "name": "displacement",
                            "name_orig": "Displacement",
                            "desc": "Displacement Temperature offset (set to 'off' for mixing units)",
                            "type": "enum",
                            "min": 0,
                            "max": 6,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Displacement"
                            ],
                            "options": {
                                "0": "OFF",
                                "1": "0",
                                "2": "-1",
                                "3": "-2",
                                "4": "-3",
                                "5": "-4",
                                "6": "-5"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 107,
                            "name": "normal_ventilation_request",
                            "name_orig": "Normal ventilation (Request)",
                            "desc": "Normal ventilation (Request)",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "OFF",
                                "1": "ON"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 108,
                            "name": "vph_mode",
                            "name_orig": "VPH Mode",
                            "desc": "Virtual Pre Heater Mode",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Virtual Preheat"
                            ],
                            "options": {
                                "0": "OFF",
                                "1": "Green",
                                "2": "Comfort"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 109,
                            "name": "night_cool_request",
                            "name_orig": "Night cool (Request)",
                            "desc": "Night cool (Request)",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "OFF",
                                "1": "ON"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 110,
                            "name": "vph_max",
                            "name_orig": "VPH Max",
                            "desc": "Virtual Pre Heater, maximum opening of By-Pass",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Virtual Preheat"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 111,
                            "name": "holiday_moderequest",
                            "name_orig": "Holiday mode(Request)",
                            "desc": "Starttid baggrundsventilation (Request)",
                            "type": "int",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 112,
                            "name": "vph_step_size",
                            "name_orig": "VPH Step Size",
                            "desc": "Virtual Pre Heater, Step Size",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Virtual Preheat"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 113,
                            "name": "bp_vc_max_position",
                            "name_orig": "BP VC: Max Position",
                            "desc": "Voltage representing max physical by-pass position",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 114,
                            "name": "bp_tc_cw_rotation",
                            "name_orig": "BP TC: CW Rotation",
                            "desc": "Settings to rotate Clock Wise (CW)",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 115,
                            "name": "max_volt_sf",
                            "name_orig": "Max Volt SF",
                            "desc": "Supply Fan maximum volt",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 116,
                            "name": "bp_tc_ccw_rotation",
                            "name_orig": "BP TC: CCW Rotation",
                            "desc": "Settings to rotate Counter Clock Wise (CCW)",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 117,
                            "name": "max_volt_ef",
                            "name_orig": "Max Volt EF",
                            "desc": "Exhaust Fan maximum volt",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 118,
                            "name": "co2_min",
                            "name_orig": "CO2 Min",
                            "desc": "CO2 concentration above this level, will cause a positive override of the flow",
                            "type": "int",
                            "min": 400,
                            "max": 5000,
                            "res": 50,
                            "default": 500,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 119,
                            "name": "co2_max",
                            "name_orig": "CO2 Max",
                            "desc": "The CO2 level causing maximum air flow",
                            "type": "int",
                            "min": 400,
                            "max": 5000,
                            "res": 50,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 120,
                            "name": "total_operating_hours",
                            "name_orig": "Total Operating Hours",
                            "desc": "Total Time Counter \n(changing this value requires factory password)",
                            "type": "int",
                            "min": 0,
                            "max": 300000,
                            "res": 10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 121,
                            "name": "operating_hours_since_last_service",
                            "name_orig": "Operating Hours Since Last Service",
                            "desc": "Time since last service",
                            "type": "int",
                            "min": 0,
                            "max": 300000,
                            "res": 10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 122,
                            "name": "co2_sensor_ppm_range_min",
                            "name_orig": "CO2 Sensor ppm Range Min",
                            "desc": "Minimum CO2 concentration for the CO2 sensor",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 50,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 123,
                            "name": "co2_sensor_ppm_range_max",
                            "name_orig": "CO2 Sensor ppm Range Max",
                            "desc": "Maximum CO2 concentration for the CO2 sensor",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 50,
                            "default": 2000,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 124,
                            "name": "co2_sensor_volt_range_min",
                            "name_orig": "CO2 Sensor Volt Range Min",
                            "desc": "Mimum Volt for the CO2 sensor",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 125,
                            "name": "co2_sensor_volt_range_max",
                            "name_orig": "CO2 Sensor Volt Range Max",
                            "desc": "Maximum Volt for the CO2 sensor",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 126,
                            "name": "bp_vc_min_position",
                            "name_orig": "BP VC: Min Position",
                            "desc": "Voltage representing min physical by-pass position",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 127,
                            "name": "test_program_time",
                            "name_orig": "Test Program Time",
                            "desc": "Time between steps in the factory test procedure",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 128,
                            "name": "retries",
                            "name_orig": "Retries",
                            "desc": "Number of retries with the interval  \"T Evaporate\"",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 129,
                            "name": "pid_sample_t",
                            "name_orig": "PID Sample T",
                            "desc": "PID Sample Time",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 10,
                            "res": 0.01,
                            "default": 0.5,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 130,
                            "name": "cc_icc_otv_rqt_temp_difference",
                            "name_orig": "CC ICC OTV RQT Temp Difference",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 131,
                            "name": "cc_icc_min_cooling_time",
                            "name_orig": "CC ICC Min Cooling Time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 240,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 132,
                            "name": "cc_icc_temperature_timer",
                            "name_orig": "CC ICC Temperature Timer",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 240,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 133,
                            "name": "cc_hg_temperature_max",
                            "name_orig": "CC HG Temperature Max",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 134,
                            "name": "sv_total_steps",
                            "name_orig": "SV Total Steps",
                            "desc": "Danfoss ETS 6: Full steps = 240, 1/2 steps = 480, 1/4 steps = 960 etc.",
                            "type": "int",
                            "min": 1,
                            "max": 30000,
                            "res": 1,
                            "default": 480,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 135,
                            "name": "sv_calibration_steps",
                            "name_orig": "SV Calibration Steps",
                            "desc": "normally total steps + 10-50steps (Danfoss ETS 6: Full Steps = 255, 1/2 Steps = 510, 1/4 Steps = 1020 etc.)",
                            "type": "int",
                            "min": 1,
                            "max": 30000,
                            "res": 1,
                            "default": 510,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 136,
                            "name": "sv_moves_between_zeroing",
                            "name_orig": "SV Moves Between Zeroing",
                            "desc": "0 = disabled",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 137,
                            "name": "sv_steps_per_second",
                            "name_orig": "SV Steps Per Second",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 1000,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 138,
                            "name": "sv_step_size",
                            "name_orig": "SV Step Size",
                            "desc": "",
                            "type": "enum",
                            "min": 1,
                            "max": 8,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ],
                            "options": {
                                "1": "Full Step",
                                "2": "1/2 Step",
                                "3": "1/4 Step",
                                "4": "8 Microsteps",
                                "5": "16 Microsteps",
                                "6": "32 Microsteps",
                                "7": "Full Step with power off",
                                "8": "1/2 Step with power off"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 139,
                            "name": "sv_open_direction_reversed",
                            "name_orig": "SV Open Direction Reversed",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Stepper Valve"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 140,
                            "name": "cc_valve_pid_kp",
                            "name_orig": "CC Valve PID KP",
                            "desc": "Cooling Controller Valve, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 141,
                            "name": "cc_valve_pid_ki",
                            "name_orig": "CC Valve PID KI",
                            "desc": "Cooling Controller Valve, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 142,
                            "name": "cc_valve_pid_kd",
                            "name_orig": "CC Valve PID KD",
                            "desc": "Cooling Controller Valve, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 143,
                            "name": "cc_valve_pid_ka",
                            "name_orig": "CC Valve PID KA",
                            "desc": "Cooling Controller Valve, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 144,
                            "name": "cc_valve_pid_min_out",
                            "name_orig": "CC Valve PID Min Out",
                            "desc": "Cooling Controller Valve, minimum output",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 145,
                            "name": "cc_valve_pid_max_out",
                            "name_orig": "CC Valve PID Max Out",
                            "desc": "Cooling Controller Valve, maximum output",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 146,
                            "name": "cc_valve_pid_sample_time",
                            "name_orig": "CC Valve PID Sample Time",
                            "desc": "Cooling Controller Valve, sample time",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 147,
                            "name": "cc_valve_pid_reference",
                            "name_orig": "CC Valve PID Reference",
                            "desc": "Cooling Controller Valve, reference",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 0.1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 148,
                            "name": "cc_icc_pid_kp",
                            "name_orig": "CC ICC PID KP",
                            "desc": "Cooling Controller Inverter Controlled Cooling, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 149,
                            "name": "cc_icc_pid_ki",
                            "name_orig": "CC ICC PID KI",
                            "desc": "Cooling Controller Inverter Controlled Cooling, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 150,
                            "name": "cc_icc_pid_kd",
                            "name_orig": "CC ICC PID KD",
                            "desc": "Cooling Controller Inverter Controlled Cooling, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 151,
                            "name": "cc_icc_pid_ka",
                            "name_orig": "CC ICC PID KA",
                            "desc": "Cooling Controller Inverter Controlled Cooling, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 152,
                            "name": "cc_icc_pid_sample_time",
                            "name_orig": "CC ICC PID Sample Time",
                            "desc": "Cooling Controller Inverter Controlled Cooling, Sample Time",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 153,
                            "name": "cc_hg_pid_kp",
                            "name_orig": "CC HG PID KP",
                            "desc": "Cooling Controller HotGas, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 154,
                            "name": "cc_hg_pid_ki",
                            "name_orig": "CC HG PID KI",
                            "desc": "Cooling Controller HotGas, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 155,
                            "name": "cc_hg_pid_kd",
                            "name_orig": "CC HG PID KD",
                            "desc": "Cooling Controller HotGas, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 156,
                            "name": "cc_hg_pid_ka",
                            "name_orig": "CC HG PID KA",
                            "desc": "Cooling Controller HotGas, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 157,
                            "name": "cc_evt_pid_kp",
                            "name_orig": "CC EVT PID KP",
                            "desc": "Cooling Controller Evaporator Temp, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 158,
                            "name": "cc_evt_pid_ki",
                            "name_orig": "CC EVT PID KI",
                            "desc": "Cooling Controller Evaporator Temp, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 159,
                            "name": "cc_evt_pid_kd",
                            "name_orig": "CC EVT PID KD",
                            "desc": "Cooling Controller Evaporator Temp, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 160,
                            "name": "cc_evt_pid_ka",
                            "name_orig": "CC EVT PID KA",
                            "desc": "Cooling Controller Evaporator Temp, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 161,
                            "name": "cc_icc_mode",
                            "name_orig": "CC ICC Mode",
                            "desc": "Cooling Controller Inverter Controlled Cooling Mode",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ],
                            "options": {
                                "0": "Volt",
                                "1": "Hz"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 162,
                            "name": "cc_icc_inverter_min_voltage",
                            "name_orig": "CC ICC Inverter Min Voltage",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 163,
                            "name": "cc_icc_inverter_max_voltage",
                            "name_orig": "CC ICC Inverter Max Voltage",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 164,
                            "name": "cc_icc_inverter_min_frequency",
                            "name_orig": "CC ICC Inverter Min Frequency",
                            "desc": "",
                            "type": "int",
                            "min": 10,
                            "max": 1000,
                            "res": 1,
                            "default": 42,
                            "showInTechnical": true,
                            "unit": "Hz",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 165,
                            "name": "cc_icc_inverter_max_frequency",
                            "name_orig": "CC ICC Inverter Max Frequency",
                            "desc": "",
                            "type": "int",
                            "min": 10,
                            "max": 1000,
                            "res": 1,
                            "default": 160,
                            "showInTechnical": true,
                            "unit": "Hz",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 166,
                            "name": "cc_hg_pid_max_out",
                            "name_orig": "CC HG PID Max Out",
                            "desc": "Cooling Controller HotGas, PID max out",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 167,
                            "name": "cc_evt_pid_max_out",
                            "name_orig": "CC EVT PID Max Out",
                            "desc": "Cooling Controller Evaporator, PID max out",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 168,
                            "name": "cc_t1",
                            "name_orig": "CC T1",
                            "desc": "Input function for cooling controller input temperature 1",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Cooling Controller",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 169,
                            "name": "cc_t2",
                            "name_orig": "CC T2",
                            "desc": "Input function for cooling controller input temperature 2",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Cooling Controller",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 170,
                            "name": "cc_t3",
                            "name_orig": "CC T3",
                            "desc": "Input function for cooling controller input temperature 3",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Cooling Controller",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 171,
                            "name": "cc_t4",
                            "name_orig": "CC T4",
                            "desc": "Input function for cooling controller input temperature 4",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Cooling Controller",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 174,
                            "name": "room_1_activation",
                            "name_orig": "Room 1 Activation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Boost",
                                "2": "PIR",
                                "3": "Ext Start"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 175,
                            "name": "room_2_activation",
                            "name_orig": "Room 2 Activation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Boost",
                                "2": "PIR",
                                "3": "Ext Start"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 176,
                            "name": "valve_position_neutral",
                            "name_orig": "Valve Position Neutral",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 177,
                            "name": "valve_position_room_1",
                            "name_orig": "Valve Position Room 1",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 178,
                            "name": "valve_position_room_2",
                            "name_orig": "Valve Position Room 2",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 8,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 179,
                            "name": "humidity_sensor_1_temperature",
                            "name_orig": "Humidity Sensor 1 Temperature",
                            "desc": "Input function for humidity sensor 1 temperature",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 180,
                            "name": "humidity_sensor_2_temperature",
                            "name_orig": "Humidity Sensor 2 Temperature",
                            "desc": "Input function for humidity sensor 2 temperature",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 190,
                            "name": "fixed_displacement",
                            "name_orig": "Fixed Displacement",
                            "desc": "Fixed Displacement - requires \"Displacement != off\", and will fix the IT-Setpoint Displacement degrees below the measured RT.",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Displacement"
                            ],
                            "options": {
                                "0": "off",
                                "1": "on"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 197,
                            "name": "state_bitfield",
                            "name_orig": "State (bitfield)",
                            "desc": "State BitField: {b0=HighTemp, b1=Defrost, b2=LowTemp, b3=Condens, b4=NightCool, b5=BackgroundVentilation, b6=Running, b7=Starting, b8=Stopping, b9=Stopped, b10=Test, b11=LoopTest, b12=FilterTest, b13=HighRoomTemp}",
                            "type": "int",
                            "min": 0,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 198,
                            "name": "masterslave_startstop",
                            "name_orig": "Master/slave (start//stop)",
                            "desc": "Master/slave (start//stop)",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "off",
                                "1": "on"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 199,
                            "name": "unit_alarm_status_bitfield",
                            "name_orig": "Unit Alarm Status (bitfield)",
                            "desc": "Unit Alarm Status (bitfield)",
                            "type": "bitmask",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": [
                                {
                                    "name": "condensation_alarm",
                                    "name_orig": "Condensation Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "temperature_sensor_alarm",
                                    "name_orig": "Temperature Sensor Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "low_temperature_alarm",
                                    "name_orig": "Low Temperature Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "supply_fan_alarm",
                                    "name_orig": "Supply Fan Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "filteralarm",
                                    "name_orig": "FilterAlarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "extraction_fan_alarm",
                                    "name_orig": "Extraction Fan Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "cc_condensation_alarm",
                                    "name_orig": "CC Condensation Alarm",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 203,
                            "name": "bp_tc_self_calibration",
                            "name_orig": "BP TC: Self Calibration",
                            "desc": "The number of actuator moves allowed, before the actuator performs a self-calibration",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 204,
                            "name": "min_volt_sf",
                            "name_orig": "Min Volt SF",
                            "desc": "Supply Fan minimum volt",
                            "type": "decimal",
                            "min": -10,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 205,
                            "name": "st_meas_time",
                            "name_orig": "ST Meas Time",
                            "desc": "Time, over which the Tacho pulses are measured",
                            "type": "int",
                            "min": 1,
                            "max": 255,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 206,
                            "name": "st_sfstdtacho",
                            "name_orig": "ST SFstdTacho",
                            "desc": "The Supply Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1800,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 207,
                            "name": "min_volt_ef",
                            "name_orig": "Min Volt EF",
                            "desc": "Exhaust Fan minimum volt",
                            "type": "decimal",
                            "min": -10,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 208,
                            "name": "st_force_interval",
                            "name_orig": "ST Force Interval",
                            "desc": "Forced Filter Test Interval",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 24,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 209,
                            "name": "md_run_time",
                            "name_orig": "MD Run Time",
                            "desc": "The time it takes for the MD damper to operate from 0-100%",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 75,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Main Damper"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 210,
                            "name": "flow_measurements_to_average",
                            "name_orig": "Flow Measurements to Average",
                            "desc": "Number of flowmeasurements for moving average",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 211,
                            "name": "md_tc_open",
                            "name_orig": "MD TC: Open",
                            "desc": "Open Main Damper, the direction the actuator rotates, to open the damper",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Main Damper"
                            ],
                            "options": {
                                "0": "CW",
                                "1": "CCW"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 212,
                            "name": "flow_hourlog",
                            "name_orig": "Flow Hourlog",
                            "desc": "Flow compensated hour counter (10 hour interval)",
                            "type": "int",
                            "min": 0,
                            "max": 300000,
                            "res": 10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 213,
                            "name": "st_test_flow",
                            "name_orig": "ST Test Flow",
                            "desc": "The Flow at which the test is conducted",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 70,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 214,
                            "name": "st_efstdtacho",
                            "name_orig": "ST EFstdTacho",
                            "desc": "The Extraction Fan tacho signal for installed unit with clean filters [Pulses Per Minute]",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1800,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 215,
                            "name": "st_start_time",
                            "name_orig": "ST Start Time",
                            "desc": "The time where filter test is run, if the unit is running, otherwise a Filter test is forced at the next startup",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 720,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 216,
                            "name": "qsa_pid_kp",
                            "name_orig": "QSA PID KP",
                            "desc": "Supply Fan PID, Proportional Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 217,
                            "name": "qsa_pid_ki",
                            "name_orig": "QSA PID KI",
                            "desc": "Supply Air Flow PID, Integral Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0.2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 218,
                            "name": "qsa_pid_kd",
                            "name_orig": "QSA PID KD",
                            "desc": "Supply Air Flow PID, Differential Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 219,
                            "name": "qsa_pid_ka",
                            "name_orig": "QSA PID KA",
                            "desc": "Supply Air Flow PID, Anti Windup Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 220,
                            "name": "md_tc_cw_rot",
                            "name_orig": "MD TC: CW Rot.",
                            "desc": "Settings to rotate Clock Wise (CW)",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Main Damper"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 221,
                            "name": "md_tc_ccw_rot",
                            "name_orig": "MD TC: CCW Rot.",
                            "desc": "Settings to rotate Counter Clock Wise (CCW)",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Main Damper"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 222,
                            "name": "night_cool_independent",
                            "name_orig": "Night Cool Independent",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 223,
                            "name": "qea_pid_kp",
                            "name_orig": "QEA PID KP",
                            "desc": "Extraction Air Flow PID, Proportional Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 224,
                            "name": "qea_pid_ki",
                            "name_orig": "QEA PID KI",
                            "desc": "Extraction Air Flow PID, Integral Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0.2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 225,
                            "name": "qea_pid_kd",
                            "name_orig": "QEA PID KD",
                            "desc": "Extraction Air Flow PID, Differential Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 226,
                            "name": "qea_pid_ka",
                            "name_orig": "QEA PID KA",
                            "desc": "Extraction Flow PID, Anti Windup Gain",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 227,
                            "name": "supply_fan_tacho_signal_at_last_\"filter_test\"",
                            "name_orig": "Supply Fan Tacho Signal at last \"Filter Test\"",
                            "desc": "Supply Fan Tacho Signal at last \"Filter Test\"",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 228,
                            "name": "extraction_fan_tacho_signal_at_last_\"filter_test\"",
                            "name_orig": "Extraction Fan Tacho Signal at last \"Filter Test\"",
                            "desc": "Extraction Fan Tacho Signal at last \"Filter Test\"",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 229,
                            "name": "nominal_max_flow",
                            "name_orig": "Nominal Max Flow",
                            "desc": "Nominal Max Flow for this unit \\n Convert: \\n Flow [m&#179;/h] / 3,6 = Flow [l/s] \\n E.g.,      360 m&#179;/h / 3,6       = 100 l/s \\n \\n Flow [l/s] * 3,6 = Flow [m&#179;/h] \\n E.g.,      100 l/s * 3,6         = 360 m&#179;/h",
                            "type": "decimal",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 230,
                            "name": "bp_tc_cw_offset",
                            "name_orig": "BP TC: CW Offset",
                            "desc": "TC:  CW Offset [ms]. Calibration of time for CW rotation (Designed for compensation for \"inertia\" in the damper+actuator)",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 231,
                            "name": "bp_tc_ccw_offset",
                            "name_orig": "BP TC: CCW Offset",
                            "desc": "TC:  CCW Offset [ms]. Calibration of time for CCW rotation  (Designed for compensation for \"inertia\" in the damper+actuator)",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Bypass"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 232,
                            "name": "pre_heater",
                            "name_orig": "Pre Heater",
                            "desc": "Preheater installed/off",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Heating Surfaces"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Electric"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 233,
                            "name": "md_mode",
                            "name_orig": "MD Mode",
                            "desc": "\"Self Return\" covers both electrical and mechanical \"spring\" return... i.e. it is a \"Power off reverse function\".",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Main Damper"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "Time Controlled",
                                "2": "Self Return"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 234,
                            "name": "high_temperature_delay",
                            "name_orig": "High Temperature Delay",
                            "desc": "High Temperature Delay (prevent HT after heating and startup)",
                            "type": "int",
                            "min": 0,
                            "max": 60,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 235,
                            "name": "electrical_heater_delay",
                            "name_orig": "Electrical Heater Delay",
                            "desc": "Electrical Heater, Delay for returning to \"normal flow\" after usage",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 236,
                            "name": "electrical_heater_increase_flow",
                            "name_orig": "Electrical Heater Increase Flow",
                            "desc": "Increase Air Flow, case it is to low for Electrical Heating.",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 237,
                            "name": "electrical_heater_min_flow",
                            "name_orig": "Electrical Heater Min Flow",
                            "desc": "Stop Electrical Heaters if Supply Flow is less than this",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 238,
                            "name": "electrical_heater_min_tacho",
                            "name_orig": "Electrical Heater Min Tacho",
                            "desc": "Stop Electrical Heaters if Supply Fan Tacho is less than this",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 239,
                            "name": "mode",
                            "name_orig": "Mode",
                            "desc": "Select Flow Mode: Ventilator voltage percentage / PID flow controller",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "Volt Direct",
                                "1": "Flow Control",
                                "2": "Volt Control"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 240,
                            "name": "errorcodes_0_3",
                            "name_orig": "ErrorCodes [0-3]",
                            "desc": "Alarm[0-3]: 4xNibbles {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 241,
                            "name": "errorcodes_4_7",
                            "name_orig": "ErrorCodes [4-7]",
                            "desc": "Alarm[4-7]: 4xNibbles {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 242,
                            "name": "errorcodes_8_11",
                            "name_orig": "ErrorCodes [8-11]",
                            "desc": "Alarm[8-11]: 4xNibbles  {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 243,
                            "name": "errorcodes_12_15",
                            "name_orig": "ErrorCodes [12-15]",
                            "desc": "Alarm[12-15]: 4xNibbles  {0=[Condensation Alarm|Inactive|Active], 1=[Temperature Sensor Alarm|Inactive|Active], 2=[Low Temperature Alarm|Inactive|Active], 3=[FilterAlarm|Inactive|Active]}",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 244,
                            "name": "halt",
                            "name_orig": "Halt",
                            "desc": "Yes: all moving parts inside the unit cease moving and  \"Halted\" is indicated in display\nNo: Unit is in \"normal\" mode",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 247,
                            "name": "power_failure___system_restore_state_unit_running_and_display_locked_bit_field",
                            "name_orig": "Power failure - system restore state (unit running? and display locked?) (bit field)",
                            "desc": "Power failure - system restore state (unit running? and display locked?) (bit field)",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 249,
                            "name": "reset_active_errors",
                            "name_orig": "Reset Active Errors",
                            "desc": "System flags (bit-field): bit0: reset active errors",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Misc"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 250,
                            "name": "air_handling_units",
                            "name_orig": "Air Handling Units",
                            "desc": "Expected number of ventilation units",
                            "type": "int",
                            "min": 1,
                            "max": 20,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 251,
                            "name": "actual_number_of_air_handling_units",
                            "name_orig": "Actual number of air handling units",
                            "desc": "actual number of ventilation units",
                            "type": "int",
                            "min": 0,
                            "max": 20,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 252,
                            "name": "show_startup_guide",
                            "name_orig": "Show Startup Guide",
                            "desc": "Indicates whether startup guide should be shown on next startup",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "0": "Yes",
                                "1": "No"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 255,
                            "name": "software_version",
                            "name_orig": "Software Version",
                            "desc": "Software Version",
                            "type": "decimal",
                            "min": 0,
                            "max": 32,
                            "res": 0.001,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 256,
                            "name": "whether_filter_test_should_be_forced_during_startup_or_not",
                            "name_orig": "Whether Filter Test should be forced during startup or not",
                            "desc": "Whether Filter Test should be forced during startup or not",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 257,
                            "name": "communication_id",
                            "name_orig": "Communication ID",
                            "desc": "The units id on the internal communication bus, 0 = Master, 1-19 = slave",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 258,
                            "name": "group_id",
                            "name_orig": "Group ID",
                            "desc": "Which Group is this unit member of",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 259,
                            "name": "group_0_master",
                            "name_orig": "Group 0 Master",
                            "desc": "Id of groupmaster for Group 0",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 260,
                            "name": "group_1_master",
                            "name_orig": "Group 1 Master",
                            "desc": "Id of groupmaster for Group 1",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 261,
                            "name": "group_2_master",
                            "name_orig": "Group 2 Master",
                            "desc": "Id of groupmaster for Group 2",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 262,
                            "name": "group_3_master",
                            "name_orig": "Group 3 Master",
                            "desc": "Id of groupmaster for Group 3",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 263,
                            "name": "group_4_master",
                            "name_orig": "Group 4 Master",
                            "desc": "Id of groupmaster for Group 4",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 264,
                            "name": "group_5_master",
                            "name_orig": "Group 5 Master",
                            "desc": "Id of groupmaster for Group 5",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 265,
                            "name": "group_6_master",
                            "name_orig": "Group 6 Master",
                            "desc": "Id of groupmaster for Group 6",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 266,
                            "name": "group_7_master",
                            "name_orig": "Group 7 Master",
                            "desc": "Id of groupmaster for Group 7",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 267,
                            "name": "group_8_master",
                            "name_orig": "Group 8 Master",
                            "desc": "Id of groupmaster for Group 8",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 268,
                            "name": "group_9_master",
                            "name_orig": "Group 9 Master",
                            "desc": "Id of groupmaster for Group 9",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 269,
                            "name": "group_10_master",
                            "name_orig": "Group 10 Master",
                            "desc": "Id of groupmaster for Group 10",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 270,
                            "name": "group_11_master",
                            "name_orig": "Group 11 Master",
                            "desc": "Id of groupmaster for Group 11",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 271,
                            "name": "group_12_master",
                            "name_orig": "Group 12 Master",
                            "desc": "Id of groupmaster for Group 12",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 272,
                            "name": "group_13_master",
                            "name_orig": "Group 13 Master",
                            "desc": "Id of groupmaster for Group 13",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 273,
                            "name": "group_14_master",
                            "name_orig": "Group 14 Master",
                            "desc": "Id of groupmaster for Group 14",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 274,
                            "name": "group_15_master",
                            "name_orig": "Group 15 Master",
                            "desc": "Id of groupmaster for Group 15",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 275,
                            "name": "group_16_master",
                            "name_orig": "Group 16 Master",
                            "desc": "Id of groupmaster for Group 16",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 276,
                            "name": "group_17_master",
                            "name_orig": "Group 17 Master",
                            "desc": "Id of groupmaster for Group 17",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 277,
                            "name": "group_18_master",
                            "name_orig": "Group 18 Master",
                            "desc": "Id of groupmaster for Group 18",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 278,
                            "name": "group_19_master",
                            "name_orig": "Group 19 Master",
                            "desc": "Id of groupmaster for Group 19",
                            "type": "int",
                            "min": 0,
                            "max": 19,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Airlinq"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 279,
                            "name": "stop",
                            "name_orig": "Stop",
                            "desc": "Disables all start priorities including timer",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 280,
                            "name": "t1",
                            "name_orig": "T1",
                            "desc": "Input function for input temperature 1",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 281,
                            "name": "t2",
                            "name_orig": "T2",
                            "desc": "Input function for input temperature 2",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 282,
                            "name": "t3",
                            "name_orig": "T3",
                            "desc": "Input function for input temperature 3",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 283,
                            "name": "t4",
                            "name_orig": "T4",
                            "desc": "Input function for input temperature 4",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 284,
                            "name": "t5",
                            "name_orig": "T5",
                            "desc": "Input function for input temperature 5\n (NA for AQC-LT)",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 285,
                            "name": "t6",
                            "name_orig": "T6",
                            "desc": "Input function for temperature input 5\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 286,
                            "name": "t7",
                            "name_orig": "T7",
                            "desc": "Input function for temperature input 7\n (NA for AQC-L and AQC-P pcb rev. 5)",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 287,
                            "name": "t8",
                            "name_orig": "T8",
                            "desc": "Input function for temperature input 8\n (NA for AQC-L and AQC-P pcb rev. 5)",
                            "type": "enum",
                            "min": 0,
                            "max": 10,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Temperature"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Inlet Temp",
                                "2": "Room Temp",
                                "3": "Exhaust Temp Ventilation Unit",
                                "4": "Outside Temp",
                                "5": "Evaporator Temp",
                                "6": "Condenser Temp",
                                "7": "Outside Temp Ventilation Unit",
                                "8": "Evaporator Temp In",
                                "9": "Evaporator Temp Out",
                                "10": "General Purpose Temp}"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 290,
                            "name": "ai_1",
                            "name_orig": "AI 1",
                            "desc": "Input function for input voltage (0-10V) 1",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "CO2 Sensor 1",
                                "2": "CO2 Sensor 2",
                                "3": "CO2 Sensor 3",
                                "4": "A-BMS Flow SetPoint",
                                "5": "A-BMS Temperature SetPoint",
                                "6": "0-10V Flow SetPoint",
                                "7": "Humidity Indoor",
                                "8": "Humidity Outdoor",
                                "9": "Ext Start",
                                "10": "A-BMS Start",
                                "11": "PIR 1",
                                "12": "Ventilation Floatswitch",
                                "13": "Comfort Cooling Error",
                                "14": "External Stop",
                                "15": "Boost",
                                "16": "Reset Filter Status",
                                "17": "PIR 2",
                                "18": "PIR 3",
                                "19": "Differential Pressure Switch (NO)",
                                "20": "Combined CO2 + TVOC + RH Room Sensor",
                                "21": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 291,
                            "name": "ai_2",
                            "name_orig": "AI 2",
                            "desc": "Input function for input voltage (0-10V) 2",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "CO2 Sensor 1",
                                "2": "CO2 Sensor 2",
                                "3": "CO2 Sensor 3",
                                "4": "A-BMS Flow SetPoint",
                                "5": "A-BMS Temperature SetPoint",
                                "6": "0-10V Flow SetPoint",
                                "7": "Humidity Indoor",
                                "8": "Humidity Outdoor",
                                "9": "Ext Start",
                                "10": "A-BMS Start",
                                "11": "PIR 1",
                                "12": "Ventilation Floatswitch",
                                "13": "Comfort Cooling Error",
                                "14": "External Stop",
                                "15": "Boost",
                                "16": "Reset Filter Status",
                                "17": "PIR 2",
                                "18": "PIR 3",
                                "19": "Differential Pressure Switch (NO)",
                                "20": "Combined CO2 + TVOC + RH Room Sensor",
                                "21": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 292,
                            "name": "ai_3",
                            "name_orig": "AI 3",
                            "desc": "Input function for voltage input (0-10V) 3\n (NA for AQC-L pcb rev. < 8 )",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "CO2 Sensor 1",
                                "2": "CO2 Sensor 2",
                                "3": "CO2 Sensor 3",
                                "4": "A-BMS Flow SetPoint",
                                "5": "A-BMS Temperature SetPoint",
                                "6": "0-10V Flow SetPoint",
                                "7": "Humidity Indoor",
                                "8": "Humidity Outdoor",
                                "9": "Ext Start",
                                "10": "A-BMS Start",
                                "11": "PIR 1",
                                "12": "Ventilation Floatswitch",
                                "13": "Comfort Cooling Error",
                                "14": "External Stop",
                                "15": "Boost",
                                "16": "Reset Filter Status",
                                "17": "PIR 2",
                                "18": "PIR 3",
                                "19": "Differential Pressure Switch (NO)",
                                "20": "Combined CO2 + TVOC + RH Room Sensor",
                                "21": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 293,
                            "name": "ai_4",
                            "name_orig": "AI 4",
                            "desc": "Input function for voltage input (0-10V) 4\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "CO2 Sensor 1",
                                "2": "CO2 Sensor 2",
                                "3": "CO2 Sensor 3",
                                "4": "A-BMS Flow SetPoint",
                                "5": "A-BMS Temperature SetPoint",
                                "6": "0-10V Flow SetPoint",
                                "7": "Humidity Indoor",
                                "8": "Humidity Outdoor",
                                "9": "Ext Start",
                                "10": "A-BMS Start",
                                "11": "PIR 1",
                                "12": "Ventilation Floatswitch",
                                "13": "Comfort Cooling Error",
                                "14": "External Stop",
                                "15": "Boost",
                                "16": "Reset Filter Status",
                                "17": "PIR 2",
                                "18": "PIR 3",
                                "19": "Differential Pressure Switch (NO)",
                                "20": "Combined CO2 + TVOC + RH Room Sensor",
                                "21": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 294,
                            "name": "ai_5",
                            "name_orig": "AI 5",
                            "desc": "Input function for input voltage (0-10V) 5\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "CO2 Sensor 1",
                                "2": "CO2 Sensor 2",
                                "3": "CO2 Sensor 3",
                                "4": "A-BMS Flow SetPoint",
                                "5": "A-BMS Temperature SetPoint",
                                "6": "0-10V Flow SetPoint",
                                "7": "Humidity Indoor",
                                "8": "Humidity Outdoor",
                                "9": "Ext Start",
                                "10": "A-BMS Start",
                                "11": "PIR 1",
                                "12": "Ventilation Floatswitch",
                                "13": "Comfort Cooling Error",
                                "14": "External Stop",
                                "15": "Boost",
                                "16": "Reset Filter Status",
                                "17": "PIR 2",
                                "18": "PIR 3",
                                "19": "Differential Pressure Switch (NO)",
                                "20": "Combined CO2 + TVOC + RH Room Sensor",
                                "21": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 296,
                            "name": "di_1",
                            "name_orig": "DI 1",
                            "desc": "Input function for digital input 1",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 297,
                            "name": "di_2",
                            "name_orig": "DI 2",
                            "desc": "Input function for digital input 2\n (special input on AQC-L, see electrical documentation)",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 298,
                            "name": "di_3",
                            "name_orig": "DI 3",
                            "desc": "Input function for digital input 3\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 299,
                            "name": "di_4",
                            "name_orig": "DI 4",
                            "desc": "Input function for digital input 4\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 300,
                            "name": "di_5",
                            "name_orig": "DI 5",
                            "desc": "Input function for digital input 5\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 301,
                            "name": "di_6",
                            "name_orig": "DI 6",
                            "desc": "Input function for digital input 6\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Ext Start",
                                "2": "A-BMS Start",
                                "3": "PIR 1",
                                "4": "Ventilation Floatswitch",
                                "5": "Comfort Cooling Error",
                                "6": "External Stop",
                                "7": "Boost",
                                "8": "Reset Filter Status",
                                "9": "PIR 2",
                                "10": "PIR 3",
                                "11": "Differential Pressure Switch (NO)",
                                "12": "Comfort Cooling Floatswitch"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 303,
                            "name": "do_1_open_collector",
                            "name_orig": "DO 1 (Open Collector)",
                            "desc": "Output function for open-collector output 1\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 304,
                            "name": "do_2_open_collector",
                            "name_orig": "DO 2 (Open Collector)",
                            "desc": "Output function for open-collector output 2\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 305,
                            "name": "do_3_open_collector",
                            "name_orig": "DO 3 (Open Collector)",
                            "desc": "Output function for open-collector output 3\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 306,
                            "name": "do_4_open_collector",
                            "name_orig": "DO 4 (Open Collector)",
                            "desc": "Output function for open-collector output 4\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 307,
                            "name": "do_5_open_collector",
                            "name_orig": "DO 5 (Open Collector)",
                            "desc": "Output function for open-collector output 5\n (NA for AQC-L and AQC-P pcb rev. 5)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 308,
                            "name": "do_k1_relay",
                            "name_orig": "DO K1 (Relay)",
                            "desc": "Output function for relay K1\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 309,
                            "name": "do_k2_relay",
                            "name_orig": "DO K2 (Relay)",
                            "desc": "Output function for relay K2\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 310,
                            "name": "do_k3_relay",
                            "name_orig": "DO K3 (Relay)",
                            "desc": "Output function for relay K3\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 311,
                            "name": "do_k4_relay",
                            "name_orig": "DO K4 (Relay)",
                            "desc": "Output function for relay K4\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 312,
                            "name": "do_k5_relay",
                            "name_orig": "DO K5 (Relay)",
                            "desc": "Output function for relay K5\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 313,
                            "name": "do_k6_relay",
                            "name_orig": "DO K6 (Relay)",
                            "desc": "Output function for relay K6\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 314,
                            "name": "do_k7_relay",
                            "name_orig": "DO K7 (Relay)",
                            "desc": "Output function for relay K7\n (NA for AQC-L)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 315,
                            "name": "do_k8_relay",
                            "name_orig": "DO K8 (Relay)",
                            "desc": "Output function for relay K8\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 316,
                            "name": "do_k9_relay",
                            "name_orig": "DO K9 (Relay)",
                            "desc": "Output function for relay K9\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 317,
                            "name": "do_k10_relay",
                            "name_orig": "DO K10 (Relay)",
                            "desc": "Output function for relay K10\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 318,
                            "name": "do_k11_relay",
                            "name_orig": "DO K11 (Relay)",
                            "desc": "Output function for relay K11\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 319,
                            "name": "do_k12_relay",
                            "name_orig": "DO K12 (Relay)",
                            "desc": "Output function for relay K12\n (NA for AQC-L and AQC-P pcb rev. > 5)\n Note: if this is selected as pre/comfort heater output, pwm will be disabled",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 320,
                            "name": "do_led_1",
                            "name_orig": "DO LED 1",
                            "desc": "Output function for LED 1\n (NA for AQC-P pcb rev. 5)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 321,
                            "name": "do_led_2",
                            "name_orig": "DO LED 2",
                            "desc": "Output function for LED 2\n (NA for AQC-P pcb rev. 5)",
                            "type": "enum",
                            "min": 0,
                            "max": 24,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Digital outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Main Damper - Time Controlled L2",
                                "2": "Main Damper - Time Controlled L3",
                                "3": "Main Damper - Spring Self Return",
                                "4": "Bypass Damper - Time Controlled L2",
                                "5": "Bypass Damper - Time Controlled L3",
                                "6": "Comfort Heater - Water",
                                "7": "Comfort Heater - Electric",
                                "8": "Comfort Cooling",
                                "9": "PreHeater",
                                "10": "Alarm",
                                "11": "LED - Debug",
                                "12": "LED - Filter Alarm",
                                "13": "LED - Filter Warning",
                                "14": "LED - Filter OK",
                                "15": "LED - Indicator",
                                "16": "Standby Relay",
                                "17": "CC Standby Relay",
                                "18": "Condensate Frost Protection",
                                "19": "Flow Distribution Valve - Time Controlled L2",
                                "20": "Flow Distribution Valve - Time Controlled L3",
                                "21": "LED - Status",
                                "22": "Window",
                                "23": "CC Water - Time Controlled L2",
                                "24": "CC Water - Time Controlled L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 322,
                            "name": "ao_1",
                            "name_orig": "AO 1",
                            "desc": "Output function for Analog out 1",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 323,
                            "name": "ao_2",
                            "name_orig": "AO 2",
                            "desc": "Output function for Analog out 2",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 324,
                            "name": "ao_3",
                            "name_orig": "AO 3",
                            "desc": "Output function for Analog out 3",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 325,
                            "name": "ao_4",
                            "name_orig": "AO 4",
                            "desc": "Output function for Analog out 4\n (NA for AQC-L pcb rev. < 8 )",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 326,
                            "name": "ao_5",
                            "name_orig": "AO 5",
                            "desc": "Output function for Analog out 5\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 327,
                            "name": "ao_6",
                            "name_orig": "AO 6",
                            "desc": "Output function for Analog out 6\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 328,
                            "name": "ao_7",
                            "name_orig": "AO 7",
                            "desc": "Output function for Analog out 7\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 35,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Analog outputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan",
                                "4": "Bypass Damper",
                                "5": "Inlet Damper",
                                "6": "LoopTest Ext. Pressure",
                                "7": "Test Ramp",
                                "8": "Main Damper - Time Controlled L2",
                                "9": "Main Damper - Time Controlled L3",
                                "10": "Main Damper - Spring Self Return",
                                "11": "Bypass Damper - Time Controlled L2",
                                "12": "Bypass Damper - Time Controlled L3",
                                "13": "Comfort Heater - Water",
                                "14": "Comfort Heater - Electric",
                                "15": "Comfort Cooling On/Off",
                                "16": "PreHeater",
                                "17": "Alarm",
                                "18": "LED - Debug",
                                "19": "LED - Filter Alarm",
                                "20": "LED - Filter Warning",
                                "21": "LED - Filter OK",
                                "22": "Window",
                                "23": "LED - Indicator",
                                "24": "Cooling Inverter",
                                "25": "Flow Distribution Valve",
                                "26": "Standby Relay",
                                "27": "CC Standby Relay",
                                "28": "Adaptive Inlet",
                                "29": "Condensate Frost Protection",
                                "30": "Flow Distribution Valve - Time Controlled L2",
                                "31": "Flow Distribution Valve - Time Controlled L3",
                                "32": "LED - Status",
                                "33": "CC Water - Time Controlled L2",
                                "34": "CC Water - Time Controlled L3",
                                "35": "Comfort Cooling 0-10V"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 329,
                            "name": "tacho_input_1",
                            "name_orig": "Tacho Input 1",
                            "desc": "Function of Tacho input 1",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Tacho inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 330,
                            "name": "tacho_input_2",
                            "name_orig": "Tacho Input 2",
                            "desc": "Function of Tacho input 2",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Tacho inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 331,
                            "name": "tacho_input_3",
                            "name_orig": "Tacho Input 3",
                            "desc": "Function of Tacho input 3\n (NA for AQC-L)",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "I/O Config",
                                "Tacho inputs"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Supply Fan",
                                "2": "Extraction Fan",
                                "3": "Recirculating Fan"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 333,
                            "name": "panel_flow_function",
                            "name_orig": "Panel Flow Function",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "General"
                            ],
                            "options": {
                                "0": "Direct",
                                "1": "None",
                                "2": "D-BMS"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 334,
                            "name": "panel_off_allowed",
                            "name_orig": "Panel OFF Allowed",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 21,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "General"
                            ],
                            "options": {
                                "0": "All",
                                "1": "None",
                                "2": "CID 160",
                                "3": "CID 161",
                                "4": "CID 162",
                                "5": "CID 163",
                                "6": "CID 164",
                                "7": "CID 165",
                                "8": "CID 166",
                                "9": "CID 167",
                                "10": "CID 168",
                                "11": "CID 169",
                                "12": "CID 170",
                                "13": "CID 171",
                                "14": "CID 172",
                                "15": "CID 173",
                                "16": "CID 174",
                                "17": "CID 175",
                                "18": "CID 176",
                                "19": "CID 177",
                                "20": "CID 178",
                                "21": "CID 179"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 335,
                            "name": "cc_mode",
                            "name_orig": "CC Mode",
                            "desc": "Comfort Cool Mode",
                            "type": "enum",
                            "min": 0,
                            "max": 4,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Settings"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "Internal",
                                "2": "External",
                                "3": "Inverter",
                                "4": "Water"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 336,
                            "name": "cc_defrost_exit_temp",
                            "name_orig": "CC Defrost Exit Temp",
                            "desc": "Temperature that causes comfort cool to exit defrost mode",
                            "type": "decimal",
                            "min": -20,
                            "max": 50,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 337,
                            "name": "cc_min_ot",
                            "name_orig": "CC Min OT",
                            "desc": "Comfort Cool minimum outside temperature",
                            "type": "decimal",
                            "min": -20,
                            "max": 50,
                            "res": 1,
                            "default": 16,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 338,
                            "name": "cc_cdt_alarm_temp",
                            "name_orig": "CC CDT Alarm Temp",
                            "desc": "Comfort Cool Condenser alarm temperature",
                            "type": "decimal",
                            "min": -20,
                            "max": 100,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 339,
                            "name": "cc_cdt_warning_temp",
                            "name_orig": "CC CDT Warning Temp",
                            "desc": "Comfort Cool Condenser warning temperature",
                            "type": "decimal",
                            "min": -20,
                            "max": 100,
                            "res": 1,
                            "default": 58,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 340,
                            "name": "cc_evt_min___defrost_temp",
                            "name_orig": "CC EVT min - Defrost Temp",
                            "desc": "Comfort Cool defrost activation temperature",
                            "type": "decimal",
                            "min": -20,
                            "max": 50,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 341,
                            "name": "cc_defrost_warning_time",
                            "name_orig": "CC Defrost Warning Time",
                            "desc": "Comfort Cool defrost time before setting warning",
                            "type": "int",
                            "min": 0,
                            "max": 480,
                            "res": 1,
                            "default": 120,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 342,
                            "name": "cc_compressor_wait_time",
                            "name_orig": "CC Compressor Wait Time",
                            "desc": "Comfort Cool time to wait between stop and start of compressor",
                            "type": "int",
                            "min": 0,
                            "max": 30,
                            "res": 1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 343,
                            "name": "cc_condensation_time",
                            "name_orig": "CC Condensation Time",
                            "desc": "Comfort Cool time delay before setting condensation warning",
                            "type": "int",
                            "min": 0,
                            "max": 600,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 344,
                            "name": "hc_barometric_pressure_outside",
                            "name_orig": "HC Barometric Pressure Outside",
                            "desc": "Humidity Control barometric pressure outside",
                            "type": "decimal",
                            "min": 30,
                            "max": 110,
                            "res": 0.1,
                            "default": 101.3,
                            "showInTechnical": true,
                            "unit": "kPa",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 345,
                            "name": "hc_barometric_pressure_room",
                            "name_orig": "HC Barometric Pressure Room",
                            "desc": "Humidity Control barometric pressure room",
                            "type": "decimal",
                            "min": 30,
                            "max": 110,
                            "res": 0.1,
                            "default": 101.3,
                            "showInTechnical": true,
                            "unit": "kPa",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 346,
                            "name": "hc_d_ah_min_a",
                            "name_orig": "HC d_AH_min_A",
                            "desc": "Delta absolute humidity minimum A-coefficient",
                            "type": "decimal",
                            "min": "-9.9990000000000006",
                            "max": "9.9990000000000006",
                            "res": 0.001,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 347,
                            "name": "hc_d_ah_min_b",
                            "name_orig": "HC d_AH_min_B",
                            "desc": "Delta absolute humidity minimum B-coefficient",
                            "type": "decimal",
                            "min": "-9.9990000000000006",
                            "max": "9.9990000000000006",
                            "res": 0.001,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 348,
                            "name": "hc_d_ah_min_c",
                            "name_orig": "HC d_AH_min_C",
                            "desc": "Delta absolute humidity minimum C-coefficient",
                            "type": "decimal",
                            "min": -99.99,
                            "max": 99.99,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 349,
                            "name": "hc_d_ah_max_a",
                            "name_orig": "HC d_AH_max_A",
                            "desc": "Delta absolute humidity maximum A-coefficient",
                            "type": "decimal",
                            "min": "-9.9990000000000006",
                            "max": "9.9990000000000006",
                            "res": 0.001,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 350,
                            "name": "hc_d_ah_max_b",
                            "name_orig": "HC d_AH_max_B",
                            "desc": "Delta absolute humidity maximum B-coefficient",
                            "type": "decimal",
                            "min": "-9.9990000000000006",
                            "max": "9.9990000000000006",
                            "res": 0.001,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 351,
                            "name": "hc_d_ah_max_c",
                            "name_orig": "HC d_AH_max_C",
                            "desc": "Delta absolute humidity maximum C-coefficient",
                            "type": "decimal",
                            "min": -99.99,
                            "max": 99.99,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Humidity"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 352,
                            "name": "panel_start_timeout",
                            "name_orig": "Panel Start Timeout",
                            "desc": "Time from panel start request to automatic return to standby, 0 = no timeout, remains started until user stop.",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 4,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 353,
                            "name": "panel_off_timeout",
                            "name_orig": "Panel Off Timeout",
                            "desc": "Time from panel standby request, to shift from off to standby mode",
                            "type": "int",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 354,
                            "name": "off_with_timeout_request",
                            "name_orig": "Off with timeout (request)",
                            "desc": "Sets system in off mode, returns to standby after timeout",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 355,
                            "name": "end_manual_override_request",
                            "name_orig": "End Manual Override (request)",
                            "desc": "Ends manual override",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 356,
                            "name": "airmaster_serial_number_msb",
                            "name_orig": "Airmaster Serial number MSB",
                            "desc": "High 16bit of airmaster serial number",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 357,
                            "name": "airmaster_serial_number_lsb",
                            "name_orig": "Airmaster Serial number LSB",
                            "desc": "Low 16bit of airmaster serial number",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 358,
                            "name": "hm_supply_air",
                            "name_orig": "HM Supply Air",
                            "desc": "Source of supply air in Holiday mode",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 0,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Holiday Mode"
                            ],
                            "options": {
                                "0": "Fan",
                                "1": "Window"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 359,
                            "name": "allow_boost",
                            "name_orig": "Allow Boost",
                            "desc": "Enable Boost function",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 0,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Boost"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 360,
                            "name": "boost_max_volt_in",
                            "name_orig": "Boost Max Volt In",
                            "desc": "max supply fan voltage when Boost is requested",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Boost"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 361,
                            "name": "boost_max_volt_out",
                            "name_orig": "Boost Max Volt Out",
                            "desc": "max exhaust fan voltage when Boost is requested",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Boost"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 362,
                            "name": "service_address",
                            "name_orig": "Service Address",
                            "desc": "sets the info shown on the AQD-P displays",
                            "type": "enum",
                            "min": 0,
                            "max": 13,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Airmaster AS",
                                "2": "Airmaster AB",
                                "3": "Airmaster Ltd.",
                                "4": "Airmaster AS",
                                "5": "Helios Ventilateurs",
                                "6": "Wesco AG",
                                "7": "Airmaster B.V.",
                                "8": "SAV Systems Ltd.",
                                "9": "Airflow Lufttechnik GmbH",
                                "10": "Johann WERNIG KG",
                                "11": "Sandviken Center -ventilasjonsservice AS",
                                "12": "Airmaster AS Norge",
                                "13": "AT-Air OY"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 363,
                            "name": "vph_options",
                            "name_orig": "VPH Options",
                            "desc": "Virtual Pre Heater Options",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Heating Surfaces"
                            ],
                            "options": {
                                "0": "N/A",
                                "1": "Electric",
                                "2": "Water",
                                "3": "Electric or Water"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 364,
                            "name": "last_filter_change_sec_utc_msb",
                            "name_orig": "Last Filter Change sec utc MSB",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 365,
                            "name": "last_filter_change_sec_utc_lsb",
                            "name_orig": "Last Filter Change sec utc LSB",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 366,
                            "name": "co2_low",
                            "name_orig": "CO2 Low",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 50,
                            "default": 900,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 367,
                            "name": "co2_mid",
                            "name_orig": "CO2 Mid",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 50,
                            "default": 1200,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 368,
                            "name": "start_priority_6",
                            "name_orig": "Start Priority 6",
                            "desc": "Start priority 6",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 369,
                            "name": "start_priority_7",
                            "name_orig": "Start Priority 7",
                            "desc": "Start priority 7",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 370,
                            "name": "simulate_mode",
                            "name_orig": "Simulate Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 371,
                            "name": "simulated_it",
                            "name_orig": "Simulated IT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 372,
                            "name": "simulated_rt",
                            "name_orig": "Simulated RT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 373,
                            "name": "simulated_gpt",
                            "name_orig": "Simulated GPT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 374,
                            "name": "simulated_ot",
                            "name_orig": "Simulated OT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 375,
                            "name": "simulated_evt",
                            "name_orig": "Simulated EVT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 376,
                            "name": "simulated_cdt",
                            "name_orig": "Simulated CdT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 377,
                            "name": "simulated_otv",
                            "name_orig": "Simulated OTV",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 378,
                            "name": "simulated_etv",
                            "name_orig": "Simulated ETV",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 50,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 379,
                            "name": "simulated_am_float_switch",
                            "name_orig": "Simulated AM Float Switch",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 380,
                            "name": "simulated_cc_float_switch",
                            "name_orig": "Simulated CC Float Switch",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 381,
                            "name": "simulated_external_start_input",
                            "name_orig": "Simulated External Start Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 382,
                            "name": "simulated_abms_start",
                            "name_orig": "Simulated ABMS Start",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 383,
                            "name": "simulated_pir_input",
                            "name_orig": "Simulated Pir Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 384,
                            "name": "simulated_external_stop_input",
                            "name_orig": "Simulated External Stop Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Simulation"
                            ],
                            "options": {
                                "0": "Inactive",
                                "1": "Active"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 385,
                            "name": "simulated_abms_temperature_setpoint",
                            "name_orig": "Simulated ABMS Temperature Setpoint",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 386,
                            "name": "simulated_abms_flow_setpoint",
                            "name_orig": "Simulated ABMS Flow Setpoint",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 387,
                            "name": "simulated_internal_co2",
                            "name_orig": "Simulated Internal CO2",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 800,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 388,
                            "name": "simulated_analog_co2_1",
                            "name_orig": "Simulated Analog CO2 1",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 389,
                            "name": "simulated_analog_co2_2",
                            "name_orig": "Simulated Analog CO2 2",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 390,
                            "name": "simulated_analog_co2_3",
                            "name_orig": "Simulated Analog CO2 3",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 391,
                            "name": "simulated_0_10v_flow",
                            "name_orig": "Simulated 0-10V Flow",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 392,
                            "name": "simulated_supply_fan",
                            "name_orig": "Simulated Supply Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 393,
                            "name": "simulated_extraction_fan",
                            "name_orig": "Simulated Extraction Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 394,
                            "name": "simulated_recirculating_fan",
                            "name_orig": "Simulated Recirculating Fan",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 395,
                            "name": "simulated_supply_airflow",
                            "name_orig": "Simulated Supply Airflow",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 397,
                            "name": "simulated_extraction_airflow",
                            "name_orig": "Simulated Extraction Airflow",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 398,
                            "name": "simulated_relative_humidity_supply",
                            "name_orig": "Simulated Relative Humidity Supply",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 399,
                            "name": "simulated_relative_humidity_extraction",
                            "name_orig": "Simulated Relative Humidity Extraction",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 400,
                            "name": "d_bms_type",
                            "name_orig": "D-BMS Type",
                            "desc": "Digital BMS Type",
                            "type": "enum",
                            "min": 0,
                            "max": 6,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Modbus",
                                "2": "KNX",
                                "3": "BACnet MS/TP",
                                "4": "BACnet IP",
                                "5": "Lon",
                                "6": "Ethernet"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 402,
                            "name": "modbus_address",
                            "name_orig": "Modbus Address",
                            "desc": "ModBus Slave Address",
                            "type": "int",
                            "min": 1,
                            "max": 247,
                            "res": 1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 403,
                            "name": "modbus_baud_rate",
                            "name_orig": "Modbus Baud Rate",
                            "desc": "ModBus BaudRate",
                            "type": "enum",
                            "min": 0,
                            "max": 5,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "9600",
                                "1": "19200",
                                "2": "38400",
                                "3": "57600",
                                "4": "115200",
                                "5": "230400"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 404,
                            "name": "modbus_parity",
                            "name_orig": "Modbus Parity",
                            "desc": "ModBus Parity",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "None (2 Stop bits)",
                                "1": "Odd (1 Stop bit)",
                                "2": "Even (1 Stop bit)"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 405,
                            "name": "bacnet_mstp_address",
                            "name_orig": "Bacnet MS/TP Address",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 406,
                            "name": "bacnet_mstp_baud_rate",
                            "name_orig": "Bacnet MS/TP Baud Rate",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "9600",
                                "1": "19200",
                                "2": "38400",
                                "3": "76800"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 407,
                            "name": "simulated_evt_in",
                            "name_orig": "Simulated EVT_IN",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 408,
                            "name": "simulated_evt_out",
                            "name_orig": "Simulated EVT_OUT",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 409,
                            "name": "simulated_hotgas_temperature",
                            "name_orig": "Simulated Hotgas Temperature",
                            "desc": "",
                            "type": "decimal",
                            "min": -50,
                            "max": 100,
                            "res": 0.1,
                            "default": 50,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 412,
                            "name": "co2_control_mode",
                            "name_orig": "CO2 Control Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Level",
                                "2": "RateOfChange",
                                "3": "Combined"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 413,
                            "name": "a_bms_defined_temperature",
                            "name_orig": "A-BMS Defined Temperature",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Analog"
                            ],
                            "options": {
                                "0": "Disabled",
                                "1": "A-BMS",
                                "2": "General"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 414,
                            "name": "allow_start_by_pir",
                            "name_orig": "Allow Start by PIR",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 415,
                            "name": "allow_start_by_co2",
                            "name_orig": "Allow Start by CO2",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 416,
                            "name": "allow_start_by_timer",
                            "name_orig": "Allow Start by Timer",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 417,
                            "name": "allow_start_by_panel",
                            "name_orig": "Allow Start by Panel",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 420,
                            "name": "normal_powerup_count",
                            "name_orig": "Normal Powerup Count",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 421,
                            "name": "watchdog_powerup_count",
                            "name_orig": "Watchdog Powerup Count",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 422,
                            "name": "hardfault_count",
                            "name_orig": "Hardfault Count",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 423,
                            "name": "sf_pid_kp",
                            "name_orig": "SF PID KP",
                            "desc": "Supply Fan Voltage PID, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 424,
                            "name": "sf_pid_ki",
                            "name_orig": "SF PID KI",
                            "desc": "Supply Fan Voltage PID, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 425,
                            "name": "sf_pid_kd",
                            "name_orig": "SF PID KD",
                            "desc": "Supply Fan Voltage PID, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 426,
                            "name": "sf_pid_ka",
                            "name_orig": "SF PID KA",
                            "desc": "Supply Fan Voltage PID, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 427,
                            "name": "ef_pid_kp",
                            "name_orig": "EF PID KP",
                            "desc": "Extraction Fan Voltage PID, Proportional Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 428,
                            "name": "ef_pid_ki",
                            "name_orig": "EF PID KI",
                            "desc": "Extraction Fan Voltage PID, Integral Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 429,
                            "name": "ef_pid_kd",
                            "name_orig": "EF PID KD",
                            "desc": "Extraction Fan Voltage PID, Differential Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 430,
                            "name": "ef_pid_ka",
                            "name_orig": "EF PID KA",
                            "desc": "Extraction Fan Voltage PID, Anti Windup Gain",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 431,
                            "name": "fan_voltage_control_pid_sample_t",
                            "name_orig": "Fan Voltage Control PID Sample T",
                            "desc": "Fan Voltage Control PID Sample Time seconds",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 10,
                            "res": 0.01,
                            "default": 0.5,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 432,
                            "name": "filter_max_life_time_alarm",
                            "name_orig": "Filter Max Life Time, Alarm",
                            "desc": "Alarm is activated when the filter is \"Filter Max Life Time, Alarm\" old. (0 months disables the Filter Max Life Time Alarm)",
                            "type": "int",
                            "min": 0,
                            "max": 48,
                            "res": 1,
                            "default": 14,
                            "showInTechnical": true,
                            "unit": "month(s)",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 433,
                            "name": "air_handling_unit_pulseskwh",
                            "name_orig": "Air Handling Unit, Pulses/kWh",
                            "desc": "EM 10: 1-phase = 1000 pulses/kWh \nEM 23: 3-phase = 100 pulses/kWh",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "Pulses/kWh",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Power Meter"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 434,
                            "name": "aqc_p_total_power_consumption_watt_hours_msb",
                            "name_orig": "AQC-P Total Power Consumption watt hours MSB",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 435,
                            "name": "comfort_cooling_pulseskwh",
                            "name_orig": "Comfort Cooling, Pulses/kWh",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "Pulses/kWh",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Power Meter"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 436,
                            "name": "aqc_c_total_power_consumption_watt_hours_msb",
                            "name_orig": "AQC-C Total Power Consumption watt hours MSB",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 437,
                            "name": "rtc_prescaler",
                            "name_orig": "RTC Prescaler",
                            "desc": "RTC-Prescaler Value from Production-Test",
                            "type": "int",
                            "min": 500,
                            "max": 1000,
                            "res": 1,
                            "default": 764,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 438,
                            "name": "timezone___offset_from_utc_in_minutes",
                            "name_orig": "TimeZone - offset from UTC in minutes",
                            "desc": "",
                            "type": "int",
                            "min": -1440,
                            "max": 1440,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 439,
                            "name": "automatic_daylight_saving_time",
                            "name_orig": "Automatic Daylight Saving Time",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Settings"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 440,
                            "name": "timer_1_days",
                            "name_orig": "Timer 1 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 31,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 441,
                            "name": "timer_1_flags",
                            "name_orig": "Timer 1 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 17,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 442,
                            "name": "timer_1_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 1 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 443,
                            "name": "timer_1_duration_minutes",
                            "name_orig": "Timer 1 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 444,
                            "name": "timer_1_flow_percent",
                            "name_orig": "Timer 1 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 445,
                            "name": "timer_1_temperature_c",
                            "name_orig": "Timer 1 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 1"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 446,
                            "name": "timer_2_days",
                            "name_orig": "Timer 2 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 31,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 447,
                            "name": "timer_2_flags",
                            "name_orig": "Timer 2 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 448,
                            "name": "timer_2_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 2 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 449,
                            "name": "timer_2_duration_minutes",
                            "name_orig": "Timer 2 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 450,
                            "name": "timer_2_flow_percent",
                            "name_orig": "Timer 2 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 451,
                            "name": "timer_2_temperature_c",
                            "name_orig": "Timer 2 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 452,
                            "name": "timer_3_days",
                            "name_orig": "Timer 3 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 96,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 453,
                            "name": "timer_3_flags",
                            "name_orig": "Timer 3 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 454,
                            "name": "timer_3_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 3 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 455,
                            "name": "timer_3_duration_minutes",
                            "name_orig": "Timer 3 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 456,
                            "name": "timer_3_flow_percent",
                            "name_orig": "Timer 3 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 457,
                            "name": "timer_3_temperature_c",
                            "name_orig": "Timer 3 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 3"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 458,
                            "name": "timer_4_days",
                            "name_orig": "Timer 4 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 96,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 459,
                            "name": "timer_4_flags",
                            "name_orig": "Timer 4 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 460,
                            "name": "timer_4_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 4 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 461,
                            "name": "timer_4_duration_minutes",
                            "name_orig": "Timer 4 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 462,
                            "name": "timer_4_flow_percent",
                            "name_orig": "Timer 4 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 463,
                            "name": "timer_4_temperature_c",
                            "name_orig": "Timer 4 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 4"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 464,
                            "name": "timer_5_days",
                            "name_orig": "Timer 5 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 127,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 465,
                            "name": "timer_5_flags",
                            "name_orig": "Timer 5 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 466,
                            "name": "timer_5_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 5 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 467,
                            "name": "timer_5_duration_minutes",
                            "name_orig": "Timer 5 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 468,
                            "name": "timer_5_flow_percent",
                            "name_orig": "Timer 5 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 469,
                            "name": "timer_5_temperature_c",
                            "name_orig": "Timer 5 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 5"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 470,
                            "name": "timer_6_days",
                            "name_orig": "Timer 6 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 127,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 471,
                            "name": "timer_6_flags",
                            "name_orig": "Timer 6 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 472,
                            "name": "timer_6_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 6 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 473,
                            "name": "timer_6_duration_minutes",
                            "name_orig": "Timer 6 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 600,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 474,
                            "name": "timer_6_flow_percent",
                            "name_orig": "Timer 6 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 475,
                            "name": "timer_6_temperature_c",
                            "name_orig": "Timer 6 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 6"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 476,
                            "name": "timer_7_days",
                            "name_orig": "Timer 7 days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 127,
                            "res": 1,
                            "default": 127,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 477,
                            "name": "timer_7_flags",
                            "name_orig": "Timer 7 flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ],
                            "options": [
                                {
                                    "name": "standard_ventilation",
                                    "name_orig": "Standard Ventilation",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 478,
                            "name": "timer_7_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 7 start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 479,
                            "name": "timer_7_duration_minutes",
                            "name_orig": "Timer 7 duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 1439,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 480,
                            "name": "timer_7_flow_percent",
                            "name_orig": "Timer 7 flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 40,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 481,
                            "name": "timer_7_temperature_c",
                            "name_orig": "Timer 7 temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Schedule 7"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 482,
                            "name": "timer_8_night_cool_days",
                            "name_orig": "Timer 8 (night cool) days",
                            "desc": "",
                            "type": "bitmask",
                            "min": 127,
                            "max": 127,
                            "res": 1,
                            "default": 127,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ],
                            "options": [
                                {
                                    "name": "monday",
                                    "name_orig": "Monday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "tuesday",
                                    "name_orig": "Tuesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "wednesday",
                                    "name_orig": "wednesday",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "thursday",
                                    "name_orig": "Thursday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "friday",
                                    "name_orig": "Friday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "saturday",
                                    "name_orig": "Saturday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "sunday",
                                    "name_orig": "Sunday",
                                    "pos": "Activ",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 483,
                            "name": "timer_8_night_cool_flags",
                            "name_orig": "Timer 8 (night cool) flags",
                            "desc": "",
                            "type": "bitmask",
                            "min": 0,
                            "max": 255,
                            "res": 1,
                            "default": 18,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ],
                            "options": [
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "night_cool",
                                    "name_orig": "Night Cool",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "egg_timer",
                                    "name_orig": "Egg Timer",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "enabled",
                                    "name_orig": "Enabled",
                                    "pos": "True",
                                    "neg": "False"
                                },
                                {
                                    "name": "unused",
                                    "name_orig": "unused",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                },
                                {
                                    "name": "internal_flag",
                                    "name_orig": "internal flag",
                                    "pos": "Active",
                                    "neg": "Inactive"
                                }
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 484,
                            "name": "timer_8_night_cool_start_timer_minutes_since_midnight",
                            "name_orig": "Timer 8 (night cool) start timer minutes since midnight",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 485,
                            "name": "timer_8_night_cool_duration_minutes",
                            "name_orig": "Timer 8 (night cool) duration minutes",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1439,
                            "res": 1,
                            "default": 360,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 486,
                            "name": "timer_8_night_cool_flow_percent",
                            "name_orig": "Timer 8 (night cool) flow %",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 487,
                            "name": "timer_8_night_cool_temperature_c",
                            "name_orig": "Timer 8 (night cool) temperature C",
                            "desc": "",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 1,
                            "default": 16,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Timer",
                                "Night Cooling"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 488,
                            "name": "database_version",
                            "name_orig": "Database Version",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 1151,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 489,
                            "name": "rtc_calibration",
                            "name_orig": "RTC-Calibration",
                            "desc": "RTC-Calibration Value from Production-Test",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 490,
                            "name": "d_bms_auto_vent",
                            "name_orig": "D-BMS auto vent.",
                            "desc": "Digital BMS automatic ventilation request",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 491,
                            "name": "d_bms_nightcool",
                            "name_orig": "D-BMS nightcool",
                            "desc": "Digital BMS nightcool request",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 492,
                            "name": "d_bms_holiday_mode",
                            "name_orig": "D-BMS Holiday mode",
                            "desc": "Digital BMS Holiday mode ventilation request",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 493,
                            "name": "d_bms_airflow",
                            "name_orig": "D-BMS airflow",
                            "desc": "Digital BMS airflow %",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 494,
                            "name": "d_bms_temperature",
                            "name_orig": "D-BMS temperature",
                            "desc": "Digital BMS temperature",
                            "type": "decimal",
                            "min": 8,
                            "max": 50,
                            "res": 0.1,
                            "default": 19,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 495,
                            "name": "d_bms_co2_ppm",
                            "name_orig": "D-BMS CO2 PPM",
                            "desc": "Digital BMS CO2 PPM",
                            "type": "int",
                            "min": -1,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 496,
                            "name": "reset_filter_status",
                            "name_orig": "Reset Filter Status",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 497,
                            "name": "run_filter_calibration",
                            "name_orig": "Run Filter Calibration",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 498,
                            "name": "start_test_procedure",
                            "name_orig": "Start Test Procedure",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 499,
                            "name": "start_looptest",
                            "name_orig": "Start Looptest",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Loop Test"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 500,
                            "name": "stop_all_units",
                            "name_orig": "Stop all units",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 501,
                            "name": "operating_mode_request",
                            "name_orig": "Operating Mode Request",
                            "desc": "intended to be used by Airlinq online API, write only param, will set the appropiate flags to enter the requested mode, readback will not return a valid value",
                            "type": "enum",
                            "min": 0,
                            "max": 7,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "OFF",
                                "1": "Standby",
                                "2": "Manual Start",
                                "3": "Holiday Mode",
                                "4": "Auto",
                                "5": "unused",
                                "6": "Night Cool",
                                "7": "Boost"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 502,
                            "name": "panel_flow_request_for_d_bms",
                            "name_orig": "Panel Flow Request For D-BMS",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": []
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 503,
                            "name": "operating_mode_request_airlinq_online",
                            "name_orig": "Operating Mode Request Airlinq online",
                            "desc": "Intended to be used wtih Airlinq Online homepage, once the request has been handled the MSB will be set to indicate the request has been handled",
                            "type": "enum",
                            "min": 0,
                            "max": 7,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [],
                            "options": {
                                "0": "OFF",
                                "1": "Standby",
                                "2": "Manual Start",
                                "3": "Holiday Mode",
                                "4": "Auto",
                                "5": "unused",
                                "6": "Night Cool",
                                "7": "Boost"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 513,
                            "name": "sf_a_coefficient",
                            "name_orig": "SF A Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 514,
                            "name": "sf_b_coefficient",
                            "name_orig": "SF B Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 515,
                            "name": "sf_c_coefficient",
                            "name_orig": "SF C Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 516,
                            "name": "sf_d_coefficient",
                            "name_orig": "SF D Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 517,
                            "name": "sf_e_coefficient",
                            "name_orig": "SF E Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 518,
                            "name": "sf_f_coefficient",
                            "name_orig": "SF F Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 519,
                            "name": "sf_g_coefficient",
                            "name_orig": "SF G Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 520,
                            "name": "sf_h_coefficient",
                            "name_orig": "SF H Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 521,
                            "name": "sf_i_coefficient",
                            "name_orig": "SF I Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 522,
                            "name": "ef_a_coefficient",
                            "name_orig": "EF A Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 523,
                            "name": "ef_b_coefficient",
                            "name_orig": "EF B Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 524,
                            "name": "ef_c_coefficient",
                            "name_orig": "EF C Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 525,
                            "name": "ef_d_coefficient",
                            "name_orig": "EF D Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 526,
                            "name": "ef_e_coefficient",
                            "name_orig": "EF E Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 527,
                            "name": "ef_f_coefficient",
                            "name_orig": "EF F Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 528,
                            "name": "ef_g_coefficient",
                            "name_orig": "EF G Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 529,
                            "name": "ef_h_coefficient",
                            "name_orig": "EF H Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 530,
                            "name": "ef_i_coefficient",
                            "name_orig": "EF I Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 531,
                            "name": "sf_x1x2_calculation",
                            "name_orig": "SF X1/X2 Calculation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 15,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ],
                            "options": {
                                "0": "X1: 1' | X2: 1'",
                                "1": "X1: 1' | X2: ln'",
                                "2": "X1: 1' | X2: 1/'",
                                "3": "X1: 1' | X2: 1/ln'",
                                "4": "X1: ln' | X2: 1'",
                                "5": "X1: ln' | X2: ln'",
                                "6": "X1: ln' | X2: 1/'",
                                "7": "X1: ln' | X2: 1/ln'",
                                "8": "X1: 1/' | X2: 1'",
                                "9": "X1: 1/' | X2: ln'",
                                "10": "X1: 1/' | X2: 1/'",
                                "11": "X1: 1/' | X2: 1/ln'",
                                "12": "X1: 1/ln' | X2: 1'",
                                "13": "X1: 1/ln' | X2: ln'",
                                "14": "X1: 1/ln' | X2: 1/'",
                                "15": "X1: 1/ln' | X2: 1/ln'"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 532,
                            "name": "ef_x1x2_calculation",
                            "name_orig": "EF X1/X2 Calculation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 15,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ],
                            "options": {
                                "0": "X1: 1' | X2: 1'",
                                "1": "X1: 1' | X2: ln'",
                                "2": "X1: 1' | X2: 1/'",
                                "3": "X1: 1' | X2: 1/ln'",
                                "4": "X1: ln' | X2: 1'",
                                "5": "X1: ln' | X2: ln'",
                                "6": "X1: ln' | X2: 1/'",
                                "7": "X1: ln' | X2: 1/ln'",
                                "8": "X1: 1/' | X2: 1'",
                                "9": "X1: 1/' | X2: ln'",
                                "10": "X1: 1/' | X2: 1/'",
                                "11": "X1: 1/' | X2: 1/ln'",
                                "12": "X1: 1/ln' | X2: 1'",
                                "13": "X1: 1/ln' | X2: ln'",
                                "14": "X1: 1/ln' | X2: 1/'",
                                "15": "X1: 1/ln' | X2: 1/ln'"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 533,
                            "name": "sf_bypass_opening",
                            "name_orig": "SF Bypass Opening",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 534,
                            "name": "ef_bypass_opening",
                            "name_orig": "EF Bypass Opening",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 535,
                            "name": "sf_bypass_correction",
                            "name_orig": "SF Bypass Correction",
                            "desc": "",
                            "type": "decimal",
                            "min": -100,
                            "max": 100,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 536,
                            "name": "ef_bypass_correction",
                            "name_orig": "EF Bypass Correction",
                            "desc": "",
                            "type": "decimal",
                            "min": -100,
                            "max": 100,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 537,
                            "name": "st_supply_fan_voltage",
                            "name_orig": "ST Supply Fan Voltage",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 538,
                            "name": "st_extraction_fan_voltage",
                            "name_orig": "ST Extraction Fan Voltage",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 539,
                            "name": "st_supply_fan_dp_reference",
                            "name_orig": "ST Supply Fan DP Reference",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 550,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 540,
                            "name": "st_extraction_fan_dp_reference",
                            "name_orig": "ST Extraction Fan DP Reference",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 550,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 541,
                            "name": "cc_icc_subtemp",
                            "name_orig": "CC ICC SubTemp",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 542,
                            "name": "cc_compressor_max_time_between_master_messages",
                            "name_orig": "CC Compressor Max Time Between Master Messages",
                            "desc": "",
                            "type": "int",
                            "min": 2,
                            "max": 300,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 543,
                            "name": "filter_max_life_time_warning",
                            "name_orig": "Filter Max Life Time, Warning",
                            "desc": "Warning period in months prior to the alarm.",
                            "type": "int",
                            "min": 0,
                            "max": 12,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "month(s)",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 544,
                            "name": "boost_afterrun_time",
                            "name_orig": "Boost Afterrun Time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1080,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Operation",
                                "Boost"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 545,
                            "name": "boost_auto_off_time",
                            "name_orig": "Boost Auto Off Time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 1080,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "min",
                            "guiGroupNameList": [
                                "Operation",
                                "Boost"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 546,
                            "name": "external_stop",
                            "name_orig": "External Stop",
                            "desc": "Note: requires external stop input setup in I/O Config menu",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "External Stop"
                            ],
                            "options": {
                                "0": "Group",
                                "1": "All"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 547,
                            "name": "sf_calibration",
                            "name_orig": "SF Calibration",
                            "desc": "",
                            "type": "decimal",
                            "min": -100,
                            "max": 100,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 548,
                            "name": "ef_calibration",
                            "name_orig": "EF Calibration",
                            "desc": "",
                            "type": "decimal",
                            "min": -100,
                            "max": 100,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 549,
                            "name": "adaptive_airflow_throw_setpoint",
                            "name_orig": "Adaptive Airflow Throw Setpoint",
                            "desc": "The setpoint is typically the distance between the inlet diffuser and the opposite wall.\nThe throw will automatically be adjusted to the setpoint value.",
                            "type": "decimal",
                            "min": 0,
                            "max": 100,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m",
                            "guiGroupNameList": [
                                "Operation",
                                "Room Details"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 550,
                            "name": "aa_narrow_a",
                            "name_orig": "AA Narrow A",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 551,
                            "name": "aa_narrow_b",
                            "name_orig": "AA Narrow B",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 552,
                            "name": "aa_wide_a",
                            "name_orig": "AA Wide A",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 553,
                            "name": "aa_wide_b",
                            "name_orig": "AA Wide B",
                            "desc": "",
                            "type": "decimal",
                            "min": -64992,
                            "max": 64992,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 554,
                            "name": "aa_wide_position",
                            "name_orig": "AA Wide Position",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 555,
                            "name": "aa_narrow_position",
                            "name_orig": "AA Narrow Position",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Adaptive Airflow"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 556,
                            "name": "nc_allow_cc",
                            "name_orig": "NC Allow CC",
                            "desc": "Allow Comfort Cooling during Nightcool periode",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 557,
                            "name": "condensate_frost_protection_on_temperature",
                            "name_orig": "Condensate Frost Protection On Temperature",
                            "desc": "",
                            "type": "decimal",
                            "min": -20,
                            "max": 20,
                            "res": 0.1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 558,
                            "name": "condensate_frost_protection_hysteresis",
                            "name_orig": "Condensate Frost Protection Hysteresis",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 20,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Condensation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 559,
                            "name": "ph_pid_kp",
                            "name_orig": "PH PID KP",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 560,
                            "name": "ph_pid_ki",
                            "name_orig": "PH PID KI",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 561,
                            "name": "ph_pid_kd",
                            "name_orig": "PH PID KD",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 562,
                            "name": "ph_pid_ka",
                            "name_orig": "PH PID KA",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 563,
                            "name": "ph_pid_min",
                            "name_orig": "PH PID Min",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 564,
                            "name": "ph_pid_max",
                            "name_orig": "PH PID Max",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 565,
                            "name": "ph_pid_sample_time",
                            "name_orig": "PH PID Sample Time",
                            "desc": "",
                            "type": "int",
                            "min": 10,
                            "max": 30000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 566,
                            "name": "ch_pid_kp",
                            "name_orig": "CH PID KP",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 567,
                            "name": "ch_pid_ki",
                            "name_orig": "CH PID KI",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 568,
                            "name": "ch_pid_kd",
                            "name_orig": "CH PID KD",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 569,
                            "name": "ch_pid_ka",
                            "name_orig": "CH PID KA",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 570,
                            "name": "ch_pid_min",
                            "name_orig": "CH PID Min",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 571,
                            "name": "ch_pid_max",
                            "name_orig": "CH PID Max",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 572,
                            "name": "ch_pid_sample_time",
                            "name_orig": "CH PID Sample Time",
                            "desc": "",
                            "type": "int",
                            "min": 10,
                            "max": 30000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 573,
                            "name": "ph_pwm_period",
                            "name_orig": "PH PWM Period",
                            "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
                            "type": "int",
                            "min": 1000,
                            "max": 30000,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 574,
                            "name": "ch_pwm_period",
                            "name_orig": "CH PWM Period",
                            "desc": "Note: when using analog output for PWM signal set PWM period to minimum 10000ms",
                            "type": "int",
                            "min": 1000,
                            "max": 30000,
                            "res": 1,
                            "default": 1000,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 575,
                            "name": "group_master_na_warning",
                            "name_orig": "Group Master NA Warning",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "On"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 576,
                            "name": "pcb_number",
                            "name_orig": "PCB Number",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ],
                            "options": {
                                "0": "Unknown",
                                "843": "JE843",
                                "861": "JE861",
                                "862": "JE862",
                                "865": "JE865",
                                "892": "JE892",
                                "901": "JE901"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 577,
                            "name": "pcb_revision",
                            "name_orig": "PCB Revision",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 20,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ],
                            "options": {
                                "0": "Unknown",
                                "1": "A",
                                "2": "B",
                                "3": "C",
                                "4": "D",
                                "5": "E",
                                "6": "F",
                                "7": "G",
                                "8": "H",
                                "9": "I",
                                "10": "J",
                                "11": "K",
                                "12": "L",
                                "13": "M",
                                "14": "N",
                                "15": "O",
                                "16": "P",
                                "17": "Q",
                                "18": "R",
                                "19": "S",
                                "20": "T"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 578,
                            "name": "cc_icc_standby_wait_on",
                            "name_orig": "CC ICC Standby Wait On",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 600,
                            "res": 1,
                            "default": 30,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 579,
                            "name": "cc_icc_standby_wait_off",
                            "name_orig": "CC ICC Standby Wait Off",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 600,
                            "res": 1,
                            "default": 180,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Settings"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 580,
                            "name": "dp_min",
                            "name_orig": "DP Min",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 550,
                            "res": 0.1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 581,
                            "name": "x2_input",
                            "name_orig": "X2 Input",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ],
                            "options": {
                                "0": "dP_ref",
                                "1": "tac"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 582,
                            "name": "dp_scale",
                            "name_orig": "DP Scale",
                            "desc": "",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 100,
                            "res": 0.01,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 583,
                            "name": "tacho_scale",
                            "name_orig": "Tacho Scale",
                            "desc": "",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 100,
                            "res": 0.01,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Measure"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 584,
                            "name": "rtmin",
                            "name_orig": "Rtmin",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 18,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Displacement"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 585,
                            "name": "auto_calibration_time",
                            "name_orig": "Auto calibration time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 250,
                            "res": 1,
                            "default": 25,
                            "showInTechnical": true,
                            "unit": "hour",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Filter",
                                "Self Test"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 586,
                            "name": "ethernet_ip",
                            "name_orig": "Ethernet IP",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 0,
                            "default": 0,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "dhcp",
                                "1": "static"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 597,
                            "name": "ethernet_iots_gateway_port_number",
                            "name_orig": "Ethernet IOTS Gateway Port Number",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 65535,
                            "res": 1,
                            "default": 55556,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 598,
                            "name": "it_hysteresis_for_cc_re_init",
                            "name_orig": "IT Hysteresis for CC re-init",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 599,
                            "name": "tc_valve_position_neutral",
                            "name_orig": "TC: Valve Position Neutral",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 600,
                            "name": "tc_valve_position_room_1",
                            "name_orig": "TC: Valve Position Room 1",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 20,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 601,
                            "name": "tc_valve_position_room_2",
                            "name_orig": "TC: Valve Position Room 2",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 80,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 602,
                            "name": "tc_run_time",
                            "name_orig": "TC: Run Time",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 75,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 603,
                            "name": "tc_cw_rotation",
                            "name_orig": "TC: CW Rotation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 604,
                            "name": "tc_ccw_rotation",
                            "name_orig": "TC: CCW Rotation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 605,
                            "name": "tc_0percent_position",
                            "name_orig": "TC: 0% Position",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "CW",
                                "1": "CCW"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 606,
                            "name": "tc_calibration_position",
                            "name_orig": "TC: Calibration Position",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "0%",
                                "1": "100%"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 607,
                            "name": "tc_self_calibration",
                            "name_orig": "TC: Self Calibration",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 608,
                            "name": "tc_cw_offset",
                            "name_orig": "TC: CW Offset",
                            "desc": "",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 609,
                            "name": "tc_ccw_offset",
                            "name_orig": "TC: CCW Offset",
                            "desc": "",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 610,
                            "name": "mode",
                            "name_orig": "Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "VC",
                                "2": "TC"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 611,
                            "name": "off_position",
                            "name_orig": "Off Position",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow Distribution"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 612,
                            "name": "hgt_a_coeff",
                            "name_orig": "HGT_A_coeff",
                            "desc": "",
                            "type": "decimal",
                            "min": -999.9,
                            "max": 999.9,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 613,
                            "name": "hgt_b_coeff",
                            "name_orig": "HGT_B_coeff",
                            "desc": "",
                            "type": "decimal",
                            "min": -999.9,
                            "max": 999.9,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 614,
                            "name": "screensaver_company_logo_id",
                            "name_orig": "Screensaver Company Logo ID",
                            "desc": "Defines which company logo to use in screensaver",
                            "type": "enum",
                            "min": 0,
                            "max": 4,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Airmaster",
                                "2": "Itho",
                                "3": "Wernig",
                                "4": "Airflow"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 615,
                            "name": "screensaver_airquality_mode",
                            "name_orig": "Screensaver Airquality Mode",
                            "desc": "Defines how the Airquality information is shown",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "0": "Disabled",
                                "1": "Graph",
                                "2": "Graph + Text",
                                "3": "Graph + Text (Large)"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 616,
                            "name": "screensaver_logo",
                            "name_orig": "Screensaver Logo",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 0,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "0": "None",
                                "1": "Company"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 617,
                            "name": "sl_enabled_time_periode_start_minutes_since_midnight",
                            "name_orig": "SL Enabled Time Periode Start (minutes since midnight)",
                            "desc": "Start time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
                            "type": "int",
                            "min": 0,
                            "max": 1440,
                            "res": 1,
                            "default": 420,
                            "showInTechnical": true,
                            "unit": "min.",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Status LED"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 618,
                            "name": "sl_enabled_time_periode_end_minutes_since_midnight",
                            "name_orig": "SL Enabled Time Periode End (minutes since midnight)",
                            "desc": "End time for status LED enabled time periode, the LED is only allowed to be on during the enabled time periode",
                            "type": "int",
                            "min": 0,
                            "max": 1440,
                            "res": 1,
                            "default": 1320,
                            "showInTechnical": true,
                            "unit": "min.",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Status LED"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 619,
                            "name": "sl_state_during_normal_operation",
                            "name_orig": "SL State During Normal Operation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Status LED"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "On"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 620,
                            "name": "bp_fan_cor_a_coefficient",
                            "name_orig": "BP Fan Cor A Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 621,
                            "name": "bp_fan_cor_b_coefficient",
                            "name_orig": "BP Fan Cor B Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 622,
                            "name": "bp_fan_cor_c_coefficient",
                            "name_orig": "BP Fan Cor C Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 623,
                            "name": "bp_fan_cor_low_limit",
                            "name_orig": "BP Fan Cor Low Limit",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 624,
                            "name": "bp_fan_cor_high_limit",
                            "name_orig": "BP Fan Cor High Limit",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 625,
                            "name": "nc_mode",
                            "name_orig": "NC Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ],
                            "options": {
                                "0": "NC Normal",
                                "1": "NC Boost",
                                "2": "NC Hybrid",
                                "3": "NC Boost Hybrid"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 626,
                            "name": "nc_boost_sfv",
                            "name_orig": "NC Boost SFV",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 627,
                            "name": "nc_boost_efv",
                            "name_orig": "NC Boost EFV",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 628,
                            "name": "nc_rtmin",
                            "name_orig": "NC RTmin",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 16,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 629,
                            "name": "nc_deltat",
                            "name_orig": "NC DeltaT",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Operation",
                                "Night Cooling",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 630,
                            "name": "cc_valve_pid_ke",
                            "name_orig": "CC Valve PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Valve"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 631,
                            "name": "cc_icc_pid_ke",
                            "name_orig": "CC ICC PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Cooling"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 632,
                            "name": "cc_hg_pid_ke",
                            "name_orig": "CC HG PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Hot Gas"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 633,
                            "name": "cc_evt_pid_ke",
                            "name_orig": "CC EVT PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Inverter",
                                "Evaporator"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 634,
                            "name": "qsa_pid_ke",
                            "name_orig": "QSA PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 635,
                            "name": "qea_pid_ke",
                            "name_orig": "QEA PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 636,
                            "name": "sf_pid_ke",
                            "name_orig": "SF PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 637,
                            "name": "ef_pid_ke",
                            "name_orig": "EF PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 638,
                            "name": "ph_pid_ke",
                            "name_orig": "PH PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Preheater"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 639,
                            "name": "ch_pid_ke",
                            "name_orig": "CH PID KE",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ],
                            "options": {
                                "0": "1",
                                "1": "RQF/100"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 640,
                            "name": "control_algorithm_cooling",
                            "name_orig": "Control Algorithm, Cooling",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Comfort Cooling",
                                "Settings"
                            ],
                            "options": {
                                "0": "PID0",
                                "1": "PID1"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 641,
                            "name": "control_algorithm_heating",
                            "name_orig": "Control Algorithm, Heating",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "General"
                            ],
                            "options": {
                                "0": "PID0",
                                "1": "PID1"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 642,
                            "name": "control_algorithm_flow_control",
                            "name_orig": "Control Algorithm, Flow Control",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Flow",
                                "Control"
                            ],
                            "options": {
                                "0": "PID0",
                                "1": "PID1"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 643,
                            "name": "self_test_tacho_alarm_count",
                            "name_orig": "Self test tacho alarm count",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 644,
                            "name": "self_test_tacho_warning_count",
                            "name_orig": "Self test tacho warning count",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 645,
                            "name": "self_test_differential_pressure_alarm_count",
                            "name_orig": "Self test differential pressure alarm count",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 9999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 646,
                            "name": "p_stop",
                            "name_orig": "P stop",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 300,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 647,
                            "name": "p_sf_total",
                            "name_orig": "P SF total",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 648,
                            "name": "p_sf",
                            "name_orig": "P SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 649,
                            "name": "p_ef_total",
                            "name_orig": "P EF total",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 650,
                            "name": "p_ef",
                            "name_orig": "P EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 651,
                            "name": "p_fans",
                            "name_orig": "P fans",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 652,
                            "name": "dp_sf",
                            "name_orig": "dP SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 653,
                            "name": "q_sf",
                            "name_orig": "Q SF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 3200,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 654,
                            "name": "q_sf_deviation",
                            "name_orig": "Q SF deviation",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 655,
                            "name": "dp_ef",
                            "name_orig": "dP EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 656,
                            "name": "q_ef",
                            "name_orig": "Q EF",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 3200,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 657,
                            "name": "q_ef_deviation",
                            "name_orig": "Q EF deviation",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 658,
                            "name": "sfp",
                            "name_orig": "SFP",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "J/m³",
                            "guiGroupNameList": [
                                "Performance Test Results"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 659,
                            "name": "ch_water_protection_otv",
                            "name_orig": "CH Water Protection OTV",
                            "desc": "To prevent damage caused by freezing, due to low outside temperature.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp Alarm"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 660,
                            "name": "ch_water_protection_rt__it",
                            "name_orig": "CH Water Protection RT / IT",
                            "desc": "To prevent damage caused by freezing, due to low RT or IT.\nNote: this check is always active regardless of \"disable low temp alarm\" timer",
                            "type": "decimal",
                            "min": -30,
                            "max": 50,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp Alarm"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 661,
                            "name": "flash_write_error_count",
                            "name_orig": "Flash Write error count",
                            "desc": "",
                            "type": "int",
                            "min": -32768,
                            "max": 32767,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Factory",
                                "Debug"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 662,
                            "name": "standby_power",
                            "name_orig": "Standby Power",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 300,
                            "res": 0.01,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 663,
                            "name": "ambient_pressure",
                            "name_orig": "Ambient Pressure",
                            "desc": "",
                            "type": "int",
                            "min": 700,
                            "max": 1200,
                            "res": 1,
                            "default": 1013,
                            "showInTechnical": true,
                            "unit": "mBar",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 664,
                            "name": "r_tvocmin",
                            "name_orig": "R_TVOCmin",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 65,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 665,
                            "name": "r_tvocmax",
                            "name_orig": "R_TVOCmax",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 220,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 666,
                            "name": "simulated_airquality",
                            "name_orig": "Simulated Airquality",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 200,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Simulation"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 667,
                            "name": "fco2",
                            "name_orig": "FCO2",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 14950,
                            "showInTechnical": true,
                            "unit": "m3/h / g/h",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 668,
                            "name": "f_tvoc",
                            "name_orig": "F_TVOC",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 15360,
                            "showInTechnical": true,
                            "unit": "m3/h / mg/h",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 669,
                            "name": "room_volume",
                            "name_orig": "Room Volume",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 3000,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "m3",
                            "guiGroupNameList": [
                                "Operation",
                                "Room Details"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 670,
                            "name": "co2_n_avg_now",
                            "name_orig": "CO2 n avg now",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 671,
                            "name": "co2_n_avg_bw",
                            "name_orig": "CO2 n avg bw",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 672,
                            "name": "tvoc_n_avg_now",
                            "name_orig": "TVOC n avg now",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 673,
                            "name": "tvoc_n_avg_bw",
                            "name_orig": "TVOC n avg bw",
                            "desc": "",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 674,
                            "name": "bp_location",
                            "name_orig": "BP Location",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ],
                            "options": {
                                "0": "Supply",
                                "1": "Extraction"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 675,
                            "name": "tvoc_control_mode",
                            "name_orig": "TVOC Control Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 3,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Level",
                                "2": "RateOfChange",
                                "3": "Combined"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 676,
                            "name": "electric_heater_afterrun",
                            "name_orig": "Electric Heater Afterrun",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 600,
                            "res": 1,
                            "default": 60,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Heating Surfaces"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 677,
                            "name": "co2_high",
                            "name_orig": "CO2 High",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 50,
                            "default": 2000,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 678,
                            "name": "co2_warning",
                            "name_orig": "CO2 Warning",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 50,
                            "default": 5000,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 679,
                            "name": "tvoc_low",
                            "name_orig": "TVOC Low",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 1,
                            "default": 65,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 680,
                            "name": "tvoc_mid",
                            "name_orig": "TVOC Mid",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 1,
                            "default": 220,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 681,
                            "name": "tvoc_high",
                            "name_orig": "TVOC High",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 1,
                            "default": 660,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 682,
                            "name": "tvoc_warning",
                            "name_orig": "TVOC Warning",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 32000,
                            "res": 1,
                            "default": 2200,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 683,
                            "name": "tvoc_start",
                            "name_orig": "TVOC Start",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 220,
                            "showInTechnical": true,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Operation",
                                "TVOC"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 684,
                            "name": "tvoc_sensor_installed",
                            "name_orig": "TVOC Sensor Installed",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "TVOC"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 685,
                            "name": "start_priority_8",
                            "name_orig": "Start Priority 8",
                            "desc": "Start priority 8",
                            "type": "enum",
                            "min": 0,
                            "max": 9,
                            "res": 1,
                            "default": 8,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Operation",
                                "Start Priority"
                            ],
                            "options": {
                                "0": "None",
                                "1": "External Start",
                                "2": "BMS",
                                "3": "PIR",
                                "4": "0-10V Flow",
                                "5": "Panel",
                                "6": "CO2",
                                "7": "Timer",
                                "8": "TVOC",
                                "9": "Room Temperature"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 686,
                            "name": "allow_start_by_tvoc",
                            "name_orig": "Allow Start by TVOC",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 687,
                            "name": "rh_room_sensor_installed",
                            "name_orig": "RH Room Sensor Installed",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Humidity"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 688,
                            "name": "co2_calibration",
                            "name_orig": "CO2 Calibration",
                            "desc": "To calibrate digital CO2 sensor, first set to zero, then set to the current CO2 level. Note: Sensirion recommends 6 minutes of runtime before doing calibration",
                            "type": "int",
                            "min": 0,
                            "max": 2000,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Factory",
                                "General"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 689,
                            "name": "co2_start",
                            "name_orig": "CO2 Start",
                            "desc": "Start operation for this unit \\n (Default value: 0 ppm) \\n  \\n CO2 Start = 0 ppm \\n Start operation at CO2 Min + 10% \\n  \\n CO2 Start = x ppm \\n Start operation at x ppm",
                            "type": "int",
                            "min": 0,
                            "max": 5000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Operation",
                                "CO2"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 690,
                            "name": "pir_start_delay",
                            "name_orig": "PIR Start delay",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 240,
                            "res": 1,
                            "default": 3,
                            "showInTechnical": true,
                            "unit": "min.",
                            "guiGroupNameList": [
                                "Operation",
                                "PIR"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 691,
                            "name": "rt_start_offset",
                            "name_orig": "RT Start Offset",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 50,
                            "res": 1,
                            "default": 2,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "High Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 692,
                            "name": "screensaver_show_flow",
                            "name_orig": "Screensaver Show Flow",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Airlinq Panel",
                                "Orbit"
                            ],
                            "options": {
                                "0": "Off",
                                "1": "On"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 693,
                            "name": "allow_start_by_room_temperatur",
                            "name_orig": "Allow Start by Room Temperatur",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "BMS",
                                "Digital"
                            ],
                            "options": {
                                "0": "No",
                                "1": "Yes"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 694,
                            "name": "pir_1_mode",
                            "name_orig": "PIR 1 Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "PIR"
                            ],
                            "options": {
                                "0": "Auto",
                                "1": "Active Low",
                                "2": "Active High"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 695,
                            "name": "pir_2_mode",
                            "name_orig": "PIR 2 Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "PIR"
                            ],
                            "options": {
                                "0": "Auto",
                                "1": "Active Low",
                                "2": "Active High"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 696,
                            "name": "pir_3_mode",
                            "name_orig": "PIR 3 Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "PIR"
                            ],
                            "options": {
                                "0": "Auto",
                                "1": "Active Low",
                                "2": "Active High"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 700,
                            "name": "threshold_room_temperature",
                            "name_orig": "Threshold Room Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 701,
                            "name": "threshold_inlet_temperature",
                            "name_orig": "Threshold Inlet Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 702,
                            "name": "threshold_outside_temperature",
                            "name_orig": "Threshold Outside Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 703,
                            "name": "threshold_general_purpose_temperature",
                            "name_orig": "Threshold General Purpose Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 704,
                            "name": "threshold_evaporator_temperature",
                            "name_orig": "Threshold Evaporator Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 705,
                            "name": "threshold_condenser_temperature",
                            "name_orig": "Threshold Condenser Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 706,
                            "name": "threshold_outside_temperature_ventilation",
                            "name_orig": "Threshold Outside Temperature Ventilation",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 707,
                            "name": "threshold_exhaust_temperature_ventilation",
                            "name_orig": "Threshold Exhaust Temperature Ventilation",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 708,
                            "name": "threshold_a_bms_temperature",
                            "name_orig": "Threshold A-BMS Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 709,
                            "name": "threshold_co2_level_room",
                            "name_orig": "Threshold CO2 Level Room",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 5000,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 710,
                            "name": "threshold_internal_co2_sensor",
                            "name_orig": "Threshold Internal CO2 Sensor",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 5000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 711,
                            "name": "threshold_a_co2_1",
                            "name_orig": "Threshold A-CO2 #1",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 5000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 712,
                            "name": "threshold_a_co2_2",
                            "name_orig": "Threshold A-CO2 #2",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 5000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 713,
                            "name": "threshold_a_co2_3",
                            "name_orig": "Threshold A-CO2 #3",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 5000,
                            "res": 1,
                            "default": 500,
                            "showInTechnical": false,
                            "unit": "ppm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 714,
                            "name": "threshold_a_bms_flow_percent",
                            "name_orig": "Threshold A-BMS Flow Percent",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 715,
                            "name": "threshold_comfort_heater_percent",
                            "name_orig": "Threshold Comfort Heater Percent",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 716,
                            "name": "threshold_bypass_damper_percent",
                            "name_orig": "Threshold Bypass Damper Percent",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 717,
                            "name": "threshold_adaptive_airflow",
                            "name_orig": "Threshold Adaptive Airflow",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.1,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 718,
                            "name": "threshold_cooling_percent",
                            "name_orig": "Threshold Cooling Percent",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 719,
                            "name": "threshold_pre_heater_percent",
                            "name_orig": "Threshold Pre Heater Percent",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 720,
                            "name": "threshold_supply_fan_voltage",
                            "name_orig": "Threshold Supply Fan Voltage",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.2,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 721,
                            "name": "threshold_extraction_fan_voltage",
                            "name_orig": "Threshold Extraction Fan Voltage",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.2,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 722,
                            "name": "threshold_recirculation_fan_voltage",
                            "name_orig": "Threshold Recirculation Fan Voltage",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 10,
                            "res": 0.1,
                            "default": 0.2,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 723,
                            "name": "threshold_supply_flow",
                            "name_orig": "Threshold Supply Flow",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 724,
                            "name": "threshold_extraction_flow",
                            "name_orig": "Threshold Extraction Flow",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 725,
                            "name": "threshold_supply_flow_1",
                            "name_orig": "Threshold Supply Flow #1",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10000,
                            "showInTechnical": false,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 726,
                            "name": "threshold_supply_flow_2",
                            "name_orig": "Threshold Supply Flow #2",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 10000,
                            "showInTechnical": false,
                            "unit": "m³/h",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 727,
                            "name": "threshold_supply_fan_rpm",
                            "name_orig": "Threshold Supply Fan RPM",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": false,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 728,
                            "name": "threshold_extraction_fan_rpm",
                            "name_orig": "Threshold Extraction Fan RPM",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": false,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 729,
                            "name": "threshold_recirculation_fan_rpm",
                            "name_orig": "Threshold Recirculation Fan RPM",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 10000,
                            "res": 1,
                            "default": 50,
                            "showInTechnical": false,
                            "unit": "rpm",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 730,
                            "name": "threshold_requested_temperature",
                            "name_orig": "Threshold Requested Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 731,
                            "name": "threshold_requested_flow",
                            "name_orig": "Threshold Requested Flow",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 732,
                            "name": "threshold_relative_humidity_supply_air",
                            "name_orig": "Threshold Relative Humidity Supply Air",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 733,
                            "name": "threshold_relative_humidity_extraction_air",
                            "name_orig": "Threshold Relative Humidity Extraction Air",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 734,
                            "name": "threshold_system_mode",
                            "name_orig": "Threshold System Mode",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 735,
                            "name": "threshold_system_condition",
                            "name_orig": "Threshold System Condition:",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 736,
                            "name": "threshold_system_alarm",
                            "name_orig": "Threshold System Alarm:",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 737,
                            "name": "threshold_am_float_switch",
                            "name_orig": "Threshold AM Float Switch",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 738,
                            "name": "threshold_manuel_start",
                            "name_orig": "Threshold Manuel Start",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 739,
                            "name": "threshold_cc_float_switch",
                            "name_orig": "Threshold CC Float switch",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 740,
                            "name": "threshold_a_bms_start",
                            "name_orig": "Threshold A-BMS Start",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 741,
                            "name": "threshold_pir",
                            "name_orig": "Threshold PIR",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 742,
                            "name": "threshold_alarm_input",
                            "name_orig": "Threshold Alarm Input",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 743,
                            "name": "threshold_recirculation_damper",
                            "name_orig": "Threshold Recirculation Damper",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 744,
                            "name": "threshold_main_air_damper",
                            "name_orig": "Threshold Main Air Damper",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 745,
                            "name": "threshold_general_warning_id_0_15",
                            "name_orig": "Threshold General warning id 0-15",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 746,
                            "name": "threshold_general_warning_id_16_31",
                            "name_orig": "Threshold General warning id 16-31",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 747,
                            "name": "threshold_general_alarm_id_0_15",
                            "name_orig": "Threshold General alarm id 0-15",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 748,
                            "name": "threshold_general_alarm_id_16_31",
                            "name_orig": "Threshold General alarm id 16-31",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 749,
                            "name": "threshold_filter_remaining_service_life_days",
                            "name_orig": "Threshold Filter, remaining service life [days]",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1000,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "days",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 750,
                            "name": "threshold_air_handling_unit_power_consumption",
                            "name_orig": "Threshold Air Handling Unit Power Consumption",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "kWh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 751,
                            "name": "threshold_air_handling_unit_power_consumption_decimal_points",
                            "name_orig": "Threshold Air Handling Unit Power Consumption decimal points",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.001,
                            "max": 0.999,
                            "res": 0.001,
                            "default": 0.1,
                            "showInTechnical": false,
                            "unit": "kWh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 752,
                            "name": "threshold_utc_time_in_seconds",
                            "name_orig": "Threshold UTC Time in seconds",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 3600,
                            "showInTechnical": false,
                            "unit": "s",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 753,
                            "name": "threshold_air_handling_unit_power_consumption",
                            "name_orig": "Threshold Air Handling Unit Power Consumption",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": false,
                            "unit": "Wh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 754,
                            "name": "threshold_remaining_service_life_percent",
                            "name_orig": "Threshold Remaining Service Life [%]",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 101,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 755,
                            "name": "threshold_remaining_service_life_hours",
                            "name_orig": "Threshold Remaining Service Life [Hours]",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "h",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 756,
                            "name": "threshold_evaporator_in_temperature",
                            "name_orig": "Threshold Evaporator In Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 757,
                            "name": "threshold_evaporator_out_temperature",
                            "name_orig": "Threshold Evaporator Out Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 758,
                            "name": "threshold_hotgas_temperature",
                            "name_orig": "Threshold Hotgas Temperature",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 100,
                            "res": 0.1,
                            "default": 0.5,
                            "showInTechnical": false,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 759,
                            "name": "threshold_stepper_valve_opening",
                            "name_orig": "Threshold Stepper Valve Opening",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 1,
                            "max": 100,
                            "res": 0.1,
                            "default": 100,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 760,
                            "name": "threshold_analog_in_1",
                            "name_orig": "Threshold Analog in 1",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 10,
                            "res": 0.01,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 761,
                            "name": "threshold_analog_in_2",
                            "name_orig": "Threshold Analog in 2",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 10,
                            "res": 0.01,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 762,
                            "name": "threshold_analog_in_3",
                            "name_orig": "Threshold Analog in 3",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.01,
                            "max": 10,
                            "res": 0.01,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 763,
                            "name": "threshold_hardware_errors",
                            "name_orig": "Threshold Hardware errors",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 764,
                            "name": "threshold_bacnet_device_id",
                            "name_orig": "Threshold Bacnet Device Id",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 765,
                            "name": "threshold_cooling_unit_power_consumption",
                            "name_orig": "Threshold Cooling Unit Power Consumption",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "kWh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 766,
                            "name": "threshold_cooling_unit_power_consumption_decimal_points",
                            "name_orig": "Threshold Cooling Unit Power Consumption decimal points",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.001,
                            "max": 0.999,
                            "res": 0.001,
                            "default": 0.1,
                            "showInTechnical": false,
                            "unit": "kWh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 767,
                            "name": "threshold_cooling_unit_power_consumption",
                            "name_orig": "Threshold Cooling Unit Power Consumption",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 100,
                            "showInTechnical": false,
                            "unit": "Wh",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 768,
                            "name": "threshold_supply_air_pressure_difference",
                            "name_orig": "Threshold Supply air pressure difference",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 550,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 769,
                            "name": "threshold_extracton_air_pressure_difference",
                            "name_orig": "Threshold Extracton air pressure difference",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.1,
                            "max": 550,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "Pa",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 770,
                            "name": "threshold_simplified_operating_mode",
                            "name_orig": "Threshold Simplified Operating Mode",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 771,
                            "name": "threshold_hardware_errors_lsb",
                            "name_orig": "Threshold Hardware Errors LSB",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 772,
                            "name": "threshold_hardware_errors_msb",
                            "name_orig": "Threshold Hardware Errors MSB",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 773,
                            "name": "threshold_air_handling_unit_current_power_consumption",
                            "name_orig": "Threshold Air Handling Unit Current Power Consumption",
                            "desc": "threshold for online logging",
                            "type": "decimal",
                            "min": 0.0099945068359375,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 18688,
                            "showInTechnical": false,
                            "unit": "W",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 774,
                            "name": "threshold_air_quality_tvoc",
                            "name_orig": "Threshold Air quality TVOC",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 10,
                            "showInTechnical": false,
                            "unit": "ppb",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 775,
                            "name": "threshold_air_quality_raw_signal",
                            "name_orig": "Threshold Air quality RAW signal",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 32767,
                            "res": 1,
                            "default": 32767,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 776,
                            "name": "threshold_rqf_prodco2",
                            "name_orig": "Threshold RQF_ProdCO2",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 777,
                            "name": "threshold_rqf_prodtvoc",
                            "name_orig": "Threshold RQF_ProdTVOC",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 100,
                            "res": 1,
                            "default": 5,
                            "showInTechnical": false,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": false,
                        "data": {
                            "id": 778,
                            "name": "threshold_started_by",
                            "name_orig": "Threshold Started By",
                            "desc": "threshold for online logging",
                            "type": "int",
                            "min": 1,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": false,
                            "unit": "",
                            "guiGroupNameList": [
                                "Datalog",
                                "Thresholds For Online Log"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 821,
                            "name": "sf_ppr",
                            "name_orig": "SF PPR",
                            "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
                            "type": "int",
                            "min": 1,
                            "max": 99,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "Pulses/Rev.",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 822,
                            "name": "ef_ppr",
                            "name_orig": "EF PPR",
                            "desc": "Number of tacho pulses pr. fan revolution (Pulses pr. Revolution).",
                            "type": "int",
                            "min": 1,
                            "max": 99,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "Pulses/Rev.",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 823,
                            "name": "sf_model",
                            "name_orig": "SF Model",
                            "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 824,
                            "name": "ef_model",
                            "name_orig": "EF Model",
                            "desc": "This value is used to identify the installed fan.\nIn units produced before the introduction of this parameter, the parameter will be set to 0, during firmware update, which corresponds to unknown fan.\n2 = ebm-papst, 3 = Ziehl-Abegg, 4 = Belmont, 5 = Baluberg, 6 = CEBI, 7 = AFL Motors, 8 = Ecofit, 9 = Alternativ 1, 10 = Alternativ 2.",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Fan"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 825,
                            "name": "enthalpy_a_coefficient",
                            "name_orig": "Enthalpy A Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 826,
                            "name": "enthalpy_b_coefficient",
                            "name_orig": "Enthalpy B Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 827,
                            "name": "enthalpy_c_coefficient",
                            "name_orig": "Enthalpy C Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 828,
                            "name": "enthalpy_d_coefficient",
                            "name_orig": "Enthalpy D Coefficient",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 829,
                            "name": "enthalpy_safety_margin",
                            "name_orig": "Enthalpy Safety Margin",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 16384,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 830,
                            "name": "enthalpy_threshold",
                            "name_orig": "Enthalpy Threshold",
                            "desc": "",
                            "type": "decimal",
                            "min": -1000,
                            "max": 1000,
                            "res": 1e-10,
                            "default": 15360,
                            "showInTechnical": true,
                            "unit": "°C",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Low Temp"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 831,
                            "name": "cc_condensate_severity",
                            "name_orig": "CC Condensate Severity",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "Settings"
                            ],
                            "options": {
                                "0": "Warning",
                                "1": "Alarm"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 832,
                            "name": "mode",
                            "name_orig": "Mode",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ],
                            "options": {
                                "0": "TC",
                                "1": "VC"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 833,
                            "name": "tc_runtime",
                            "name_orig": "TC: Runtime",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "sec",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 834,
                            "name": "tc_open",
                            "name_orig": "TC: Open",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ],
                            "options": {
                                "0": "CW",
                                "1": "CCW"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 835,
                            "name": "tc_cw_rotation",
                            "name_orig": "TC: CW Rotation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 836,
                            "name": "tc_ccw_rotation",
                            "name_orig": "TC: CCW Rotation",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 2,
                            "res": 1,
                            "default": 1,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ],
                            "options": {
                                "0": "L2",
                                "1": "L3",
                                "2": "L2+L3"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 837,
                            "name": "tc_self_calibration",
                            "name_orig": "TC: Self Calibration",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 30000,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 838,
                            "name": "tc_cw_offset",
                            "name_orig": "TC: CW Offset",
                            "desc": "",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 839,
                            "name": "tc_ccw_offset",
                            "name_orig": "TC: CCW Offset",
                            "desc": "",
                            "type": "int",
                            "min": -999,
                            "max": 999,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "ms",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 840,
                            "name": "vc_min_position",
                            "name_orig": "VC: Min Position",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 841,
                            "name": "vc_max_position",
                            "name_orig": "VC: Max Position",
                            "desc": "",
                            "type": "decimal",
                            "min": 0,
                            "max": 10,
                            "res": 0.1,
                            "default": 10,
                            "showInTechnical": true,
                            "unit": "V",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 842,
                            "name": "engage",
                            "name_orig": "Engage",
                            "desc": "",
                            "type": "enum",
                            "min": 0,
                            "max": 1,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "",
                            "guiGroupNameList": [
                                "Hardware Setup",
                                "Comfort Cooling",
                                "CC Water"
                            ],
                            "options": {
                                "0": "False",
                                "1": "True"
                            }
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 843,
                            "name": "ch_pid_adaptive_max_flow_pct_low",
                            "name_orig": "CH PID Adaptive Max Flow pct Low",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 844,
                            "name": "ch_pid_adaptive_max_flow_pct_high",
                            "name_orig": "CH PID Adaptive Max Flow pct High",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 845,
                            "name": "ch_pid_adaptive_max_ch_pct_low",
                            "name_orig": "CH PID Adaptive Max CH pct Low",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    },
                    {
                        "type": "parameter",
                        "isVisibleToTechnicalBasic": true,
                        "data": {
                            "id": 846,
                            "name": "ch_pid_adaptive_max_ch_pct_high",
                            "name_orig": "CH PID Adaptive Max CH pct High",
                            "desc": "",
                            "type": "int",
                            "min": 0,
                            "max": 100,
                            "res": 1,
                            "default": 0,
                            "showInTechnical": true,
                            "unit": "%",
                            "guiGroupNameList": [
                                "Process Settings",
                                "Heating",
                                "Comfort Heater"
                            ]
                        }
                    }
                ]
            }
        ]
    }
}