import {deviceParams} from "../../../helpers/constants.js";
import {paramNames, paramSpec} from "../../parameters/parameterSpec.js";
import {awareTelemNames} from "../../parameters/awareTelemSpec.js";
import {awareParamNames} from "../../parameters/awareParamSpec.js";
import {range, toInteger} from 'lodash'
import {getTelemetry} from '../../../api/api.js'
import moment from 'moment'
import {isAwareDevice, saveCSV} from '../../../helpers/helperfunctions.js'

export const graphTypes = {
    simpleCO2: "simpleCO2",
    simpleTVOC: "simpleTVOC",
    multiHumidity: "multiHumidity",
    simpleAirflow: "simpleAirflow",
    multiPowerConsumption: "multiPowerConsumption",
    multiTemperature: "multiTemperature",
    multiAirflowM3H: "multiAirflowM3H",
    stepStartedBy: "stepStartedBy",
    awareStepStartedBy: "awareStepStartedBy",
    stepMainState: "stepMainState",
    stepRecirculationState: "stepRecirculationState",
    heater: "heater",
    heating_cooling: "heating_cooling",
    heating_cooling_with_eev: "heating_cooling_with_eev",
    multiTemperaturesCooling: "multiTemperaturesCooling",
    multiTemperaturesHeatPump: "multiTemperaturesHeatPump",
    multiDampersAndSetpoints: "multiDampersAndSetpoints",
    multiCondensateSystem: "multiCondensateSystem",
    simpleCoolingPercent: "simpleCoolingPercent",
    multiCO2: "multiCO2",
    multiTVOC: "multiTVOC",
    multiFanRpm: "multiFanRpm",
    stepSystemMode: "stepSystemMode",
    awareStepSystemMode: "awareStepSystemMode",
    multiSystemConditions: "multiSystemConditions",
    multiSystemWarnings: "multiSystemWarnings",
    multiSystemAlarms: "multiSystemAlarms"
}

export function getJEGraphTooltipKeys(graphType, device) {
    switch (graphType) {
        case graphTypes.simpleCO2:
            return [deviceParams.co2]
        case graphTypes.simpleTVOC:
            return [deviceParams.voc]
        case graphTypes.multiHumidity:
            return [paramNames.relative_humidity_supply_air, paramNames.relative_humidity_extraction_air]
        case "New":
            return [deviceParams.airflow]
        case graphTypes.simpleAirflow:
            return [deviceParams.airflow]
        case graphTypes.multiPowerConsumption:
            return [deviceParams.cooling_unit_power_consumption, deviceParams.air_handling_unit_power_consumption]
        case graphTypes.multiTemperature:
            return [deviceParams.inlet, deviceParams.room, deviceParams.outlet,
                (device.installed_components.cooler && document.location.href.includes("dashboard")) ? deviceParams.outside : deviceParams.outside_ventilation]
        case graphTypes.multiAirflowM3H:
            return [deviceParams.supply_flow, deviceParams.extraction_flow]
        case graphTypes.stepStartedBy:
            return deviceParams.started_by
        case graphTypes.heater:
            return [deviceParams.comfort_heater, deviceParams.pre_heater]
        case graphTypes.multiTemperaturesCooling:
            return [deviceParams.temperature_evaporator_in, deviceParams.temperature_evaporator_out, deviceParams.temperature_hotgas, deviceParams.outside]
        case graphTypes.simpleCoolingPercent:
            return [paramNames.cooling_percent]
        case graphTypes.multiCO2:
            return [deviceParams.co2, deviceParams.co2_control_limit_min, deviceParams.co2_control_limit_max]
        case graphTypes.multiTVOC:
            return [deviceParams.voc, deviceParams.tvoc_control_limit_min, deviceParams.tvoc_control_limit_max]
        case graphTypes.multiFanRpm:
            return [deviceParams.fan_supply_rpm, deviceParams.fan_extraction_rpm]
        case graphTypes.stepSystemMode:
            return [deviceParams.system_mode]
        case graphTypes.multiSystemConditions:
            return [deviceParams.sc_low_temp_process, deviceParams.sc_high_temp_process, deviceParams.sc_condensation_process, deviceParams.sc_high_room_temp]
        case graphTypes.multiSystemWarnings:
            return [deviceParams.sc_non_critial_hardware_fault, deviceParams.sc_filter_change_needed, deviceParams.sc_condenser_overheated, deviceParams.sc_compressor_locked, deviceParams.sc_comfort_cool_defrost_warning, deviceParams.sc_comfort_cool_hotgas_warning, deviceParams.sc_comfort_cool_pressure_warning]
        case graphTypes.multiSystemAlarms:
            return [deviceParams.system_alarm_low_temp, deviceParams.system_alarm_condensation, deviceParams.system_alarm_filter, deviceParams.system_alarm_critical_hardware_fault]
    }
    return []
}

export function getAwareGraphTooltipKeys(graphType) {
    switch (graphType) {
        case graphTypes.simpleCO2:
            return [awareTelemNames.co2_ar]
        case graphTypes.simpleTVOC:
            return [awareTelemNames.tvoc_ar]
        case graphTypes.multiHumidity:
            return [awareTelemNames.rh_ae, awareTelemNames.rh_ai]
        case graphTypes.simpleAirflow:
            return [awareTelemNames.rqf]
        case "New":
            return [awareTelemNames.rqf]
        case graphTypes.multiPowerConsumption:
            return [awareTelemNames.pm_pm_p_act, awareTelemNames.ech_pm_p_act, awareTelemNames.eph_pm_p_act]
        case graphTypes.multiTemperature:
            return [awareTelemNames.t_ai, awareTelemNames.t_ar, awareTelemNames.t_ao, awareTelemNames.t_ae, awareTelemNames.udt, awareTelemNames.rqt]
        case graphTypes.multiAirflowM3H:
            return [awareTelemNames.fan_sup_flow, awareTelemNames.fan_ext_flow]
        case graphTypes.awareStepStartedBy:
            return awareTelemNames.started_by
        case graphTypes.stepSystemMode:
            return [deviceParams.system_mode]
        case graphTypes.awareStepSystemMode:
            return [awareTelemNames.op_mode]
        case graphTypes.stepMainState:
            return [awareTelemNames.msm_state]
        case graphTypes.stepRecirculationState:
            return [awareTelemNames.rc_state]
        case graphTypes.heater:
            console.error("heater selected for aware (should not be possible)")
            return [] // aware does not have heater, it has heating_cooling
        case graphTypes.heating_cooling:
            return [awareTelemNames.ech_1_pct, awareTelemNames.ech_2_pct, awareTelemNames.ech_3_pct, awareTelemNames.hvac_pct]
        case graphTypes.heating_cooling_with_eev:
            return [awareTelemNames.ech_1_pct, awareTelemNames.ech_2_pct, awareTelemNames.ech_3_pct, awareTelemNames.hvac_pct, awareTelemNames.eev_pct]
        case graphTypes.multiTemperaturesHeatPump:
            return [awareTelemNames.t_vto, awareTelemNames.t_vti, awareTelemNames.t_voc, awareTelemNames.t_lbo, awareTelemNames.t_lbi, awareTelemNames.t_lie, awareTelemNames.t_loe, awareTelemNames.t_pci, awareTelemNames.t_pco, awareTelemNames.t_vic, awareTelemNames.delta_t_evap]
        case graphTypes.multiDampersAndSetpoints:
            return [awareTelemNames.damper_main_sup_pos, awareTelemNames.damper_main_ext_pos, awareTelemNames.damper_bypass_pos, awareTelemNames.damper_recirc_in_pos, awareTelemNames.damper_recirc_out_pos, awareTelemNames.ech_1_pct, awareTelemNames.ech_2_pct, awareTelemNames.ech_3_pct, awareTelemNames.hvac_pct, awareTelemNames.eev_pct]
        case graphTypes.multiCondensateSystem:
            return [awareTelemNames.cds_lvl_sup_phe, awareTelemNames.cds_lvl_sup_ahe, awareTelemNames.cds_lvl_ext_phe, awareTelemNames.cds_lvl_ext_ahe]
        case graphTypes.simpleCoolingPercent:
            console.error("simpleCoolingPercent TODO")
            return []
        // return [paramNames.cooling_percent]
        case graphTypes.multiCO2:
            return [awareTelemNames.co2_ar, awareParamNames.co2_control_min, awareParamNames.co2_control_max]
        case graphTypes.multiFanRpm:
            console.error("stepFanRPM TODO")
            return []
        // return [deviceParams.system_mode]
        case graphTypes.multiSystemConditions:
            console.error("multiSystemConditions TODO")
            return []
        // return [deviceParams.sc_low_temp_process, deviceParams.sc_high_temp_process, deviceParams.sc_condensation_process, deviceParams.sc_high_room_temp]
        case graphTypes.multiSystemWarnings:
            console.error("multiSystemWarnings TODO")
            return []
        // return [deviceParams.sc_non_critial_hardware_fault, deviceParams.sc_filter_change_needed, deviceParams.sc_condenser_overheated, deviceParams.sc_compressor_locked, deviceParams.sc_comfort_cool_defrost_warning, deviceParams.sc_comfort_cool_hotgas_warning, deviceParams.sc_comfort_cool_pressure_warning]
        case graphTypes.multiSystemAlarms:
            console.error("multiSystemAlarms TODO")
            return []
        // return [deviceParams.system_alarm_low_temp, deviceParams.system_alarm_condensation, deviceParams.system_alarm_filter, deviceParams.system_alarm_critical_hardware_fault]
    }
    console.error(`could not find keys for graphType '${graphType}'`)
    return []
}

function shouldShowPowerConsumptionChart(device) {
    return !(device.latest_telemetry[paramNames.air_handling_unit_power_consumption] == 0
        && device.latest_telemetry[paramNames.cooling_unit_power_consumption] == 0);
}

export function isGraphValidForDevice(device, graphType) {
    switch (graphType) {
        case graphTypes.simpleCO2:
            return isAwareDevice(device.serial_number) ? true : device.installed_components.co2_sensor
        case graphTypes.simpleTVOC:
            return isAwareDevice(device.serial_number) ? true : device.installed_components.tvoc_sensor
        case graphTypes.multiHumidity:
            return isAwareDevice(device.serial_number) ? true : device.installed_components.humidity_control_sensor
        case graphTypes.simpleAirflow:
            return true
        case graphTypes.multiPowerConsumption:
            return isAwareDevice(device.serial_number) ? false : shouldShowPowerConsumptionChart(device)
        case graphTypes.multiTemperature:
            return true
        case graphTypes.multiAirflowM3H:
            return isAwareDevice(device.serial_number) ? true : device.device_twin.reported?.parameters[deviceParams.flow_mode] === "1"
        case graphTypes.stepStartedBy:
            return isAwareDevice(device.serial_number) ? false : device?.device_twin?.reported?.applicationVersion >= '6243'
        case graphTypes.awareStepStartedBy:
            return isAwareDevice(device.serial_number)
        case graphTypes.stepMainState:
            return isAwareDevice(device.serial_number)
        case graphTypes.stepRecirculationState:
            return isAwareDevice(device.serial_number)
        case graphTypes.multiTemperaturesCooling:
            return !isAwareDevice(device.serial_number)
        case graphTypes.multiTemperaturesHeatPump:
            return isAwareDevice(device.serial_number)
        case graphTypes.multiDampersAndSetpoints:
            return isAwareDevice(device.serial_number)
        case graphTypes.multiCondensateSystem:
            return isAwareDevice(device.serial_number)
        case graphTypes.simpleCoolingPercent:
            return !isAwareDevice(device.serial_number)
        case graphTypes.multiCO2:
            return !isAwareDevice(device.serial_number)
        case graphTypes.multiTVOC:
            return isAwareDevice(device.serial_number) ? false : device.installed_components.tvoc_sensor
        case graphTypes.multiFanRpm:
            return true
        case graphTypes.stepSystemMode:
            return !isAwareDevice(device.serial_number)
        case graphTypes.awareStepSystemMode:
            return isAwareDevice(device.serial_number)
        case graphTypes.multiSystemConditions:
            return true
        case graphTypes.multiSystemWarnings:
            return true
        case graphTypes.multiSystemAlarms:
            return true
    }
    return true
}

export function exportCSV(graphKeys, telemetryList, t) {
    const headerWithNameList = graphKeys.map(h => `${h} (${t(`telemetry:${h}`)})`)
    for (const telem of telemetryList) {
        const dataRows = telem.dataList.map(d => `${moment(d["ts"]).local().format('YYYY-MM-DDTHH:mm:ssZ')},${graphKeys.map(h => d[h]).join(",")}`)
        const csvString = `${t('common:timestamp')},${headerWithNameList.join(",")}\n${dataRows.join("\n")}`
        saveCSV(`AO_export_${telem.id}.csv`, csvString)
    }
}

export async function exportAllCSV(device, start, end) {
    const telemKeyArr = range(1000, 1100).filter(t => paramSpec[t])
    const allTelemetry = await getTelemetry(telemKeyArr, device.serial_number, start, end)
    const allTelemetrySpecList = getAllTelemetrySpecList()
    const headerWithNameList = ['Timestamp', 'SerialNumber', ...Object.keys(allTelemetrySpecList)]
    const secondRowWithParameterIdList = ['', '', ...Object.values(allTelemetrySpecList).map(v => v.replaceAll("TS", '').replaceAll(".", ":"))]

    const telemDataRows = []
    allTelemetry.dataList.forEach(dataPoint => {
        const cells = [moment(dataPoint["ts"]).local().format('YYYY-MM-DDTHH:mm:ssZ'), device.serial_number]
        Object.values(allTelemetrySpecList).forEach(value => {
            if (value === "TS") {
                cells.push(moment(dataPoint["ts"]).local().format('YYYY-MM-DDTHH:mm:ssZ'))
                return
            }
            const s = value.split(".")
            if (s.length === 2) {
                try {
                    cells.push(dataPoint[s[0]][s[1]])
                } catch (e) {
                    cells.push(0)
                }
            } else {
                cells.push(dataPoint[value])
            }
        })
        telemDataRows.push(cells.join(","))
    })

    const telemCsvString = `${headerWithNameList.join(",")}\n${secondRowWithParameterIdList.join(",")}\n${telemDataRows.join("\n")}`
    saveCSV(`telemetry_${device.serial_number}.csv`, telemCsvString)

    let descriptionCsvString = "id;name;description;options\n"
    descriptionCsvString += ";Timestamp;The timestamp of the telemetry event;\n"
    descriptionCsvString += ";SerialNumber;The serial number of the device;\n"
    Object.entries(allTelemetrySpecList).forEach(([key, value]) => {
        if (value === "TS") {
            descriptionCsvString += ";UpdatedDate;The timestamp of the telemetry event;\n"
            return
        }
        const s = value.split(".")
        if (s.length !== 2 || s[1] === "0") {
            const telemSpec = paramSpec[toInteger(s[0])]
            descriptionCsvString += `${s[0]};${key};${(telemSpec.desc)};${(getOptionsString(telemSpec.options))}\n`
        }
    })
    saveCSV(`telemetry_description.csv`, descriptionCsvString)
}

export function exportPDF(deviceName, deviceSerial) {
    const graphContainerEle = document.getElementById("graph-container")
    graphContainerEle.style.width = "1000px"
    window.setTimeout(() => {
        let url = '/'; // frontslash as url gives empty top text
        const WinPrint = window.open(url, '', 'left=0,top=0,width=1100,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write("<style>#top-information {margin-bottom: 20px} h4 {margin-bottom: 0; font-size: 20px}</style>")
        WinPrint.document.write(`<h1>${deviceName} - ${deviceSerial}</h1>`);
        WinPrint.document.write(graphContainerEle.outerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }, 100)
    window.setTimeout(() => {
        graphContainerEle.style.width = "initial"
    }, 200)
}

export function NowPlusDays(daysToAdd) {
    const d = new Date()
    d.setDate(d.getDate() + daysToAdd)
    return d
}

function getAllTelemetrySpecList() {
    return {
        "StartedBy": "1078",
        "RoomTemperature": "1000",
        "InletTemperature": "1001",
        "OutsideTemperature": "1002",
        "GeneralPurposeTemperature": "1003",
        "EvaporatorTemperature": "1004",
        "CondenserTemperature": "1005",
        "OutsideTemperatureVentilation": "1006",
        "ExhaustTemperatureVentilation": "1007",
        "ABMSTemperature": "1008",
        "CO2LevelRoom": "1009",
        "InternalCO2Sensor": "1010",
        "ACo21": "1011",
        "ACo22": "1012",
        "ACo23": "1013",
        "ABMSFlowPercent": "1014",
        "ComfortHeaterPercent": "1015",
        "BypassDamperPercent": "1016",
        "CoolingPercent": "1018",
        "PreHeaterPercent": "1019",
        "SupplyFanVoltage": "1020",
        "ExtractionFanVoltage": "1021",
        "RecirculationFanVoltage": "1022",
        "SupplyFlow": "1023",
        "ExtractionFlow": "1024",
        "SupplyFanRPM": "1027",
        "ExtractionFanRPM": "1028",
        "RecirculationFanRPM": "1029",
        "RequestedTemperature": "1030",
        "RequestedFlow": "1031",
        "RelativeHumiditySupplyAir": "1032",
        "RelativeHumidityExtractionAir": "1033",
        "SystemMode": "1034",
        "SysCon_LowTempProcess": "1035.0",
        "SysCon_HighTempProcess": "1035.1",
        "SysCon_CondensationProcess": "1035.2",
        "SysCon_NonCriticalHardwareFault": "1035.3",
        "SysCon_CondenserOverheated": "1035.4",
        "SysCon_CompressorLocked": "1035.5",
        "SysCon_FilterChangeNeeded": "1035.6",
        "SysCon_HighRoomTemp": "1035.7",
        "SysCon_AbnormalFilterTestCalibrationResult": "1035.8",
        "SysCon_ManualOverrideActive": "1035.9",
        "SysCon_ComfortCoolDefrostWarning": "1035.10",
        "SysCon_ComfortCoolCondensationWarning": "1035.11",
        "SysCon_BoostModeActive": "1035.12",
        "SysCon_ComfortCoolHotgasWarning": "1035.13",
        "SysCon_ComfortCoolPressureWarning": "1035.14",
        "SysAlarm_LowTempAlarm": "1036.0",
        "SysAlarm_CondensationAlarm": "1036.1",
        "SysAlarm_FilterAlarm": "1036.2",
        "SysAlarm_CriticalHardwareFault": "1036.3",
        "AMFloatSwitch": "1037",
        "ManuelStart": "1038",
        "CCFloatSwitch": "1039",
        "ABMSStart": "1040",
        "PIR": "1041",
        "AlarmInput": "1042",
        "RecirculationDamper": "1043",
        "MainAirDamper": "1044",
        "FilterRemainingServiceLife": "1049",
        "TotalPowerConsumption": "1053",
        "RemainingServiceLifePercent": "1054",
        "RemainingServiceLifeHours": "1055",
        "EvaporatorInTemperature": "1056",
        "EvaporatorOutTemperature": "1057",
        "HotgasTemperature": "1058",
        "StepperValveOpening": "1059",
        "AnalogIn1": "1060",
        "AnalogIn2": "1061",
        "AnalogIn3": "1062",
        "HWError_RoomTemperaturesensor": "1063.0",
        "HWError_InletTemperaturesensor": "1063.1",
        "HWError_OutsideTemperaturesensor": "1063.2",
        "HWError_GeneralPurposeTemperaturesensor": "1063.3",
        "HWError_CondenserTemperaturesensor": "1063.4",
        "HWError_EvaporatorTemperaturesensor": "1063.5",
        "HWError_ExhaustTemperaturesensorVentilationUnit": "1063.6",
        "HWError_OutsideTemperaturesensorVentilationUnit": "1063.7",
        "HWError_SupplyflowSensor1": "1063.8",
        "HWError_SupplyflowSensor2": "1063.9",
        "HWError_ExtractionflowSensor": "1063.10",
        "HWError_CO2Sensor": "1063.11",
        "HWError_SupplyFan": "1063.12",
        "HWError_ExtractionFan": "1063.13",
        "HWError_EvaporatorInTemperaturesensor": "1063.14",
        "HWError_EvaporatorOutTemperaturesensor": "1063.15",
        "HWError_HotgasTemperaturesensor": "1063.16",
        "HWError_ComfortCoolingConnectionLost": "1063.17",
        "HWError_ComfortCoolingStepdriver": "1063.18",
        "HWError_ComfortCoolingFrequencyInverter": "1063.19",
        "HWError_HumiditySupplyAirSensor": "1063.20",
        "HWError_HumidityExtractionAirSensor": "1063.21",
        "HWError_HumiditySensorSettings": "1063.22",
        "CoolingPowerConsumption": "1067",
        "SupplyAirPressureDifference": "1068",
        "ExtractonAirPressureDifference": "1069",
        "SimplifiedOperatingMode": "1070",
        "UpdatedDate": "TS",
        "AdaptiveInletVoltage": "1017",
        "UserDefinedFlow": "1031",
        "UserDefinedTemp": "1030",
        "AirHandlingUnitPowerConsumption": "1053",
        "VOCLevelRoom": "1074"
    }
}

export function getOptionsString(options) {
    if (!options) return ""
    if (Array.isArray(options)) {
        return `{${options.map((o, idx) => `${idx}=[${o.name_orig.replaceAll(' ', '')}|${o.neg}|${o.pos}]`).join(',')}}`
    }
    // is object
    return `{${Object.entries(options).map(([k, v]) => `${k}=${v.replaceAll(' ', '')}`).join(',')}}`
}

export const chartColors = ["#33a54c", "#67a7dd", "#d3745f", "#c9d45a", "#334149",
    "#7584a1", "#facdc5", "#753998", "#21d3e4", "#5648fc",
    "#8c8e16", "#24b85d", "#d6b812", "#b5186b", "#4b1bb4",
    "#b6c7c7", "#fa4f3e"]

