import i18next from "i18next"
import {roleConstants, storageConstants, syslang} from "./constants.js"
import {get} from "lodash"
import {useTranslation} from 'react-i18next'
import {paramNames} from "../components/parameters/parameterSpec.js";

/** Get css variable value
 * @param varName
 * @returns {string}
 */
export const getCssVarValue = (varName) =>
    getComputedStyle(document.documentElement).getPropertyValue(`--bs-${varName}`)

/** Evaluate the profile inputs and return a series of errors, if they are either empty or does not obey a given standard.
 * @param {string} givenName property for the first name
 * @param {string} surname property for the last name
 * @param {string} mail property for the email (Evaluated with RegEx to ensure that it has the correct email format)
 * @param {string} mobilePhone property for the phone number
 * @param {string} dialCode property for the selected dialCode (Used for checking if the user only inputs dialcode)
 * @returns object with errors for distinct inputs
 */
export const profileValidator = (givenName, surname, mail, mobilePhone, dialCode) => {
    let errors = {}
    let givenNameError = stringValidator(givenName)
    let surnameError = stringValidator(surname)
    let mailError = mailValidator(mail)
    let mobileError = mobilePhoneValidator(mobilePhone)
    let dialCodeError = dialCodeValidator(dialCode)

    if (givenNameError !== "") {
        errors.givenName = givenNameError
    }
    if (surnameError !== "") {
        errors.surname = surnameError
    }
    if (mailError !== "") {
        errors.mail = mailError
    }
    if (mobileError !== "") {
        errors.mobilePhone = mobileError
    }
    if (dialCodeError !== "") {
        errors.dialCode = dialCodeError
    }
    return errors
}

// eslint-disable-next-line no-unused-vars
function dialCodeValidator(dialCode) {
    // TODO ??
    return ""
}

/** Evaluate the profile inputs and return a series of errors, if they are either empty or does not obey a given standard.
 * @param {string} mobilePhone property for the phone number
 * @param {string} dialCode property for the selected dialCode (Used for checking if the user only inputs dialcode)
 * @returns object with errors for distinct inputs
 */
export const mobilePhoneValidator = (mobilePhone, dialCode) => {
    if (!mobilePhone || mobilePhone === dialCode) {
        return i18next.t('inputerrors:empty')
    } else {
        return ""
    }
}

/** Evaluate the profile inputs and return a series of errors, if they are either empty or does not obey a given standard.
 * @param {string} mail property for the email (Evaluated with RegEx to ensure that it has the correct email format)
 * @returns object with errors for distinct inputs
 */
export const mailValidator = (mail) => {
    if (!mail) {
        return i18next.t('inputerrors:empty')
    } else if (!/\S+@\S+\.\S+/.test(mail)) {
        return i18next.t('inputerrors:email_invalid')
    } else {
        return ''
    }
}

/** Evaluate the profile inputs and return a series of errors, if they are either empty or does not obey a given standard.
 * @param {string} serialNumber property for the device serial number (Evaluated with RegEx to ensure that it has the correct format)
 * @returns object with errors for distinct inputs
 */
export const deviceSerialNumberValidator = (serialNumber) => {
    if (!serialNumber) {
        return i18next.t('inputerrors:empty')
    } else if (!/^\d*\.?\d+$/.test(serialNumber)) {
        return i18next.t('inputerrors:serialNumber_invalid')
    } else {
        return ''
    }
}

/** Evaluate the profile inputs and return a series of errors, if they are either empty or does not obey a given standard.
 * @param {string} stringInput property for the form input
 * @returns object with errors for distinct inputs
 */
export const stringValidator = (stringInput) => {
    if (!stringInput || !stringInput.trim()) {
        return i18next.t('inputerrors:empty')
    } else {
        return ''
    }
}

/** Flattens the tree and returns an array
 * @param {*} obj nested tree with children
 * @returns array with flattened tree
 */
export const flattenTree = (obj) => {
    let arr = []
    for (const element of obj) {
        let result = flattenTree(element.children)
        arr.push(element)
        arr = [...arr, ...result]
    }
    return arr
}

/** Get roles that user with `role` can assign
 * @param {[string]} roleList
 */
export const getAssignableSystemRoles = (roleList) => {
    const {t} = useTranslation(['common'])
    return [
        ...roleList.includes(roleConstants.Admin) ? [{
            id: process.env.REACT_APP_APP_ADMIN,
            option: t('common:appRoles.App.Admin')
        }] : [],
        ...roleList.includes(roleConstants.Admin) ? [{
            id: process.env.REACT_APP_APP_PREMIUMUSER,
            option: t("common:appRoles.App.Premium")
        }] : [],
        ...roleList.includes(roleConstants.Admin) || roleList.includes(roleConstants.Technical)
            ? [{id: process.env.REACT_APP_APP_TECHNICALUSER, option: t("common:appRoles.App.Technical")}] : [],
        ...roleList.includes(roleConstants.Admin) || roleList.includes(roleConstants.Technical) || roleList.includes(roleConstants.TechnicalBasic)
            ? [{id: process.env.REACT_APP_APP_TECHNICALBASIC, option: t("common:appRoles.App.TechnicalBasic")}] : [],
        ...roleList.includes(roleConstants.Admin) || roleList.includes(roleConstants.TechnicalBasic) ||
        roleList.includes(roleConstants.Technical) || roleList.includes(roleConstants.Premium) || roleList.includes(roleConstants.Basic)
            ? [{id: process.env.REACT_APP_APP_BASICUSER, option: t("common:appRoles.App.BasicUser")}] : [],
        {id: process.env.REACT_APP_APP_VIEWER, option: t("common:appRoles.App.Viewer")}
    ]
}

/** Pick specific fields from an object
 * @param {object} o
 * @param  {...any} fields
 * @returns
 */
export const pick = (o, ...fields) => {
    return fields.reduce((a, x) => {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(x)) a[x] = o[x]
        return a
    }, {})
}

/** Finds a specific group or device in a supplied grouphierarchy
 * @param {string} id item id to find
 * @param {string} type of item to find
 * @param {[object]} hierarchy hierarchy of groups
 * @returns {object} the found item if any
 */
export function findDeviceOrGroup(id, type, hierarchy) {
    return type === "device"
        ? findDevice(id, hierarchy)
        : findGroup(id, hierarchy)
}

/** Finds a specific group in a supplied hierarchy
 * @param {string} groupId group id to find
 * @param {[object]} allowedGroups array of allowed groups
 * @returns {object} the found group if any
 */
export const findGroup = (groupId, allowedGroups) => {
    if (groupId === '') {
        return '' // TODO: change to null
    }
    for (let group of allowedGroups) {
        if (group.id === groupId) {
            return group
        } else if (group.children) {
            let item = findGroup(groupId, group.children)
            if (item) return item
        }
    }
}

/** Finds a device in the hierarchy
 * @param {string} deviceId Id of the device
 * @param {[object]} allowedGroups array of allowed groups
 * @returns {object} found device if any
 */
export const findDevice = (deviceId, allowedGroups) => {
    if (deviceId === '') {
        return '' // TODO: change to null
    }
    for (let group of allowedGroups) {
        for (let device of group.devices) {
            if (device.id === deviceId) {
                return device
            }
        }
        if (group.children) {
            let item = findDevice(deviceId, group.children)
            if (item) return item
        }
    }
}

/** Removes a specified group from the source array
 * @param {object} itemToRemove the group which needs to be removed from the source array
 * @param {object} hierarchy a reference to the hierarchy which needs to be manipulated */
export const removeGroupFromHierarchy = (hierarchy, itemToRemove) => {
    for (let i = 0; i < hierarchy.length; i++) {
        if (hierarchy[i].id !== itemToRemove.id) {
            removeGroupFromHierarchy(hierarchy[i].children, itemToRemove)
        } else {
            hierarchy.splice(i, 1)
        }
    }
}

/** Get all devices in a given groups hierarchy
 * @param groupHierarchyList A group Hierarchy
 * @returns {[Object]} List of devices in given group hierarchy
 */
export const getAllDevicesFromGroupHierachy = (groupHierarchyList) => {
    const deviceList = []
    getDevicesInGroup(deviceList, Array.isArray(groupHierarchyList) ? groupHierarchyList : [groupHierarchyList])
    return deviceList
}

function getDevicesInGroup(deviceList, groupList) {
    for (const group of groupList) {
        if (group.devices) deviceList.push(...group.devices)
        if (group.children) getDevicesInGroup(deviceList, group.children)
    }
}

/** @param {[object]} groupList
 * @param {boolean} includeDevices
 * @returns {*[{id: string, name: string, path: [string]}]} */
export function getPathToItems(groupList, includeDevices) {
    const resultList = []
    for (const group of groupList) {
        getPathToItemsHelper(group, [group.id], resultList, includeDevices)
    }
    return resultList
}

function getPathToItemsHelper(group, curPath, resultList, includeDevices) {
    resultList.push({id: group.id, name: group.name, path: curPath})
    if (group.children) {
        for (const child of group.children) {
            getPathToItemsHelper(child, [...curPath, child.id], resultList, includeDevices)
        }
    }
    if (includeDevices && group.devices) {
        for (const device of group.devices) {
            resultList.push({
                id: device.id,
                name: device.name,
                serial_number: device.serial_number,
                path: [...curPath, device.id]
            })
        }
    }
}

/** Constructs a string based on a supplied array of object names
 * @param {string[]} arr should contain an array of object names (found traversing the tree)
 * @param {string} selectedName current name selected in the tree (to display as last item)
 * @returns string
 */
export const createPathStringFromArray = (arr, selectedName) => {
    return arr.reverse().join(' \u2192 ') + ' \u2192 ' + selectedName
}

/** Constructs a path to the current group in the hierarchy (bottom-up traverse)
 * @param {object} parent object which is selected (recursive function which traverse the hierarchy from the selected item)
 * @param {list[object]} source list of available objects
 * @returns an array of object names from selected item to parent
 */
export const constructPath = (parent, source) => {
    if (!parent) return []
    let path = []
    if (parent !== "") {
        path.push(parent.name)
        let item = constructPath(findGroup(parent.parent, source), source)
        if (item.length > 0) {
            path = path.concat(item)
        }
    }
    return path
}

/** Constructs a path to the current group in the hierarchy (bottom-up traverse)
 * @param {object} parent object which is selected (recursive function which traverse the hierarchy from the selected item)
 * @param {list[object]} source list of available objects
 * @returns an array of object name id pairs from selected item to parent
 */
export const getGroupPathWithIdName = (parent, source) => {
    if (!parent) return []
    let path = []
    if (parent !== "") {
        path.push({name: parent.name, id: parent.id})
        let item = getGroupPathWithIdName(findGroup(parent.parent, source), source)
        if (item.length > 0) {
            path = path.concat(item)
        }
    }
    return path
}

/** generate html from alarm og warning object
 * @param {object} alarmOrWarningObj
 * @returns {string} HTML string
 */
export function generateAwareAlarmWarningsTableFromJson(alarmOrWarningObj) {
    let html = ""
    alarmOrWarningObj.forEach(function (val) {
        html += "<div class='cat'>"
        Object.keys(val).forEach(function (key) {
            let value = val[key]
            if (key === 'first_stamp') {
                value = new Date(value * 1000).toLocaleString()
            }
            key = key.replace("_", " ")
            html += "<strong>" + capitalizeFirstLetter(key) + "</strong>: " + value + "<br>"
        })
        html += "</div>"
    })
    return html
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/** Pulls devices from group objects
 * @param {Object[]} groups list of groups to pull devices from
 * @return array of devices
 */
export const pullDevicesFromHierarchy = (groups) => {
    let tempDevices = []
    for (let allowedGroup of groups) {
        tempDevices = tempDevices.concat(allowedGroup.devices)
        if (allowedGroup.children) {
            tempDevices = tempDevices.concat(pullDevicesFromHierarchy(allowedGroup.children))
        }
    }
    return tempDevices
}

export const telemetryColorsForDevice = (device) => {

    // === JE
    // 366 CO2 Low
    // 367 CO2 Mid
    // 677 CO2 High
    // 678 CO2 Warning
    // 679 TVOC Low
    // 680 TVOC Mid
    // 681 TVOC High
    // 682 TVOC Warning

    // === Aware
    // "co2_level_grey": 300,
    // "co2_level_blue": 400,
    // "co2_level_dark_green": 500,
    // "co2_level_light_green": 600,
    // "co2_level_yellow": 1000,
    // "co2_level_orange": 2000,
    // "co2_level_red": 5000,
    // "tvoc_level_grey": 65,
    // "tvoc_level_blue": 100,
    // "tvoc_level_dark_green": 150,
    // "tvoc_level_light_green": 200,
    // "tvoc_level_yellow": 220,
    // "tvoc_level_orange": 1100,
    // "tvoc_level_red": 2200,

    const colorSet = [
        {bg: 'rgb(117,117,117)', dim: 'rgb(231,231,231)', dimDarker: 'rgb(221,221,221)', smiley: 'happy'},
        {bg: 'rgb(0,150,222)', dim: 'rgb(195,247,255)', dimDarker: 'rgb(185,237,245)', smiley: 'happy'},
        {bg: "rgb(6, 113, 56)", dim: 'rgb(223 242 227)', dimDarker: 'rgb(213 232 217)', smiley: 'happy'},
        {bg: "rgb(120,192,67)", dim: 'rgb(239 255 226)', dimDarker: 'rgb(229 245 216)', smiley: 'happy'},
        {bg: "rgb(222,204,34)", dim: "rgb(255,247,173)", dimDarker: "rgb(245 237 163)", smiley: 'mid'},
        {bg: "rgb(248,149,29)", dim: 'rgb(255 247 237)', dimDarker: 'rgb(245 237 227)', smiley: 'unhappy'},
        {bg: "rgb(237,33,36)", dim: 'rgb(255 232 232)', dimDarker: 'rgb(245 222 222)', smiley: 'unhappy'}]

    const getCO2ColorObj = function (value) {
        if (!value) return [colorSet[0]]
        const params = device.device_twin.reported.parameters
        if (isAwareDevice(device.serial_number)) {
            if (!params["co2_level_grey"]) {
                console.warn(`Device '${device.serial_number}' have no parameter 'co2_level_grey' (and probably not the rest either)`)
                return colorSet[0]
            }
            // TODO: get props in awareParamNames
            if (value < Number(params["co2_level_grey"]))
                return colorSet[0]
            if (value < Number(params["co2_level_blue"]))
                return colorSet[1]
            if (value < Number(params["co2_level_dark_green"]))
                return colorSet[2]
            if (value < Number(params["co2_level_light_green"]))
                return colorSet[3]
            if (value < Number(params["co2_level_yellow"]))
                return colorSet[4]
            if (value < Number(params["co2_level_orange"]))
                return colorSet[5]
            return colorSet[6]
        } else { // JE
            const low = Number(params[paramNames.co2_low]), mid = Number(params[paramNames.co2_mid]),
                high = Number(params[paramNames.co2_high] == "0" ? 2000 : params[paramNames.co2_high]),
                warning = Number(params[paramNames.co2_warning] == "0" ? 5000 : params[paramNames.co2_warning])
            if (value < low)
                return colorSet[2]
            if (value < mid)
                return colorSet[3]
            if (value < high)
                return colorSet[4]
            if (value < warning)
                return colorSet[5]
            return colorSet[6]
        }
    }

    const getTVOCColorObj = function (value) {
        if (!value) return colorSet[0]
        const params = device.device_twin.reported.parameters
        if (isAwareDevice(device.serial_number)) {
            if (!params["tvoc_level_grey"]) {
                console.warn(`Device '${device.serial_number}' have no parameter 'tvoc_level_grey' (and probably not the rest either)`)
                return colorSet[0]
            }
            // TODO: get props in awareParamNames
            if (value < Number(params["tvoc_level_grey"]))
                return colorSet[0]
            if (value < Number(params["tvoc_level_blue"]))
                return colorSet[1]
            if (value < Number(params["tvoc_level_dark_green"]))
                return colorSet[2]
            if (value < Number(params["tvoc_level_light_green"]))
                return colorSet[3]
            if (value < Number(params["tvoc_level_yellow"]))
                return colorSet[4]
            if (value < Number(params["tvoc_level_orange"]))
                return colorSet[5]
            return colorSet[6]
        } else {
            const params = device.device_twin.reported.parameters
            const low = Number(params[paramNames.tvoc_low]), mid = Number(params[paramNames.tvoc_mid]),
                high = Number(params[paramNames.tvoc_high]), warning = Number(params[paramNames.tvoc_warning])
            if (low < value)
                return colorSet[2]
            if (mid < value)
                return colorSet[3]
            if (high < value)
                return colorSet[4]
            if (warning < value)
                return colorSet[5]
            return colorSet[6]
        }
    }

    return {
        getTVOCColorObj: getTVOCColorObj,
        getCO2ColorObj: getCO2ColorObj
    }
}

/** Return formatted datetime string (eg: 2/11/2022 06:53:11) given datetime (could be Date obj, unix epoc number or ISO string)
 * @param {Date|string|number} datetime
 * @param {boolean} showDate
 * @param {boolean} showTime
 * @param showSeconds
 * @param {string} dateDelimiter
 * @param {string} timeDelimiter
 * @returns {string}
 */
export const formatDateTimeString = (datetime, showDate, showTime, showSeconds = false, dateDelimiter = '/', timeDelimiter = ":") => {
    // TODO: test with other locales
    const localeString = new Date(datetime).toLocaleString("da-DK")
    const [date, time] = localeString.split(" ")
    if (showDate && showTime)
        return `${date.replaceAll(".", dateDelimiter)} ${formatTimeString(time, timeDelimiter, showSeconds)}`
    if (showDate)
        return date.replaceAll(".", dateDelimiter)
    return formatTimeString(time, timeDelimiter, showSeconds)
}

/** format time string
 * @param timeStr should be format hh.mm.ss (eg. 01.11.13)
 * @param delimiter
 * @param showSeconds
 * @returns {string} formatted string (eg 01:11:13 if delimiter = ':')
 */
const formatTimeString = (timeStr, delimiter, showSeconds) => {
    let split = timeStr.split(".")
    return showSeconds ? `${split[0]}${delimiter}${split[1]}${delimiter}${split[2]}` : `${split[0]}${delimiter}${split[1]}`
}

/** @param {string | Date} start
 * @param {string | Date} end
 * @param {number} num
 * @returns {unknown[]}
 */
export const getTicks = (start, end, num) => {
    const min = typeof start === "string" ? new Date(start).getTime() : start
    const max = typeof end === "string" ? new Date(end).getTime() : end
    const diff = (max - min) / num
    const ticks = [...Array(num).keys()].map(i => min + (diff * i))
    return [...ticks, max]
}

/** Get hours difference between start and end
 * @param {string | Date} start
 * @param {string | Date} end
 * @returns {number} Hours difference between start and end
 */
export const getTimeDiffHours = (start, end) => {
    const min = typeof start === "string" ? new Date(start).getTime() : start
    const max = typeof end === "string" ? new Date(end).getTime() : end
    return (max - min) / 1000 / 60 / 60
}

export const formatMinutesAsTimeString = (d, pad = true) => {
    let date = new Date(1970, 0, 0, 0, 0, 0)
    let afterMinutesAdded = new Date(date.getTime() + d * 60000)

    let hours = afterMinutesAdded.getHours()
    let minutes = afterMinutesAdded.getMinutes()

    if (pad) {
        hours = hours.toString().padStart(2, '0')
        minutes = minutes.toString().padStart(2, '0')
    }
    return hours + ":" + minutes
}

/**
 * Serial numbers are always at least 7 chars. Prepend zeroes until 7 chars.
 * @param serial_number
 * @returns {string}
 */
export const prependZeroes = (serial_number) => {
    const numCharsToPrepend = Math.max(7 - serial_number.length, 0)
    return "0".repeat(numCharsToPrepend) + serial_number
}

/**
 * Create GUID. Optionally, give a string which will be used as the first 8 chars of the GUID.
 * If this is given, it must be exactly 8 chars. Fill with x if you don't care what it becomes.
 * @param preString
 * @returns {string}
 */
export function createUUID(preString = "") {
    let dt = new Date().getTime()
    if (preString && preString.length !== 8)
        throw new Error("If preString is used it must be 8 chars. fill out with x if you don't care what to put.")
    const replace_str = preString ? `${preString}-xxxx-4xxx-yxxx-xxxxxxxxxxxx` : 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    return replace_str.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
}

/** replaces given single char of str at index, returns updated string
 * @param str
 * @param index
 * @param chr
 * @returns {string}
 */
export const setCharAt = (str, index, chr) =>
    str.substring(0, index) + chr + str.substring(index + 1)

export function getComparator(order = 'asc', orderBy = "") {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export function descendingComparator(a, b, orderBy) {
    if (get(b, orderBy) < get(a, orderBy)) return -1
    if (get(b, orderBy) > get(a, orderBy)) return 1
    return 0
}

/** Get min number in array of numbers
 * Faster and more memory efficient than Math.min
 * @param {[number]} arr
 * @returns {number}
 */
export function arrayMin(arr) {
    let len = arr.length, min = Infinity
    while (len--) {
        if (arr[len] < min) {
            min = arr[len]
        }
    }
    return min
}

/** Get max number in array of numbers
 * Faster and more memory efficient than Math.max
 * @param {[number]} arr
 * @returns {number}
 */
export function arrayMax(arr) {
    let len = arr.length, max = -Infinity
    while (len--) {
        if (arr[len] > max) {
            max = arr[len]
        }
    }
    return max
}

export function getFormattedNumber(numberStr, factionDigits = 1) {
    const userlang = localStorage.getItem(storageConstants.userLanguage)
    let lang = syslang.find(l => l.id === userlang)
    if (!lang) {
        console.warn(`could not find syslang with id ${userlang}. Using english instead.`)
        lang = syslang.find(l => l.id === "en")
    }
    if(numberStr === undefined || numberStr === null) return "-"
    
    const numberVal = Number(numberStr)
    return numberVal.toLocaleString(lang.icuCode, {
        maximumFractionDigits: factionDigits,
        minimumFractionDigits: factionDigits
    })
}

export function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
    }
    return bytes
}

export function base64ToUnicode(base64String) {
    return decodeURIComponent(atob(base64String).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export const saveByteArray = (function () {
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (data, name) {
        const blob = new Blob(data, {type: "octet/stream"}),
            url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = name
        a.click()
        window.URL.revokeObjectURL(url)
    }
}())

export function saveBase64String(fileName, base64String) {
    saveByteArray([base64ToArrayBuffer(base64String)], fileName);
}

export function saveText(filename, text) {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/txt;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

export function saveCSV(filename, text) {
    const element = document.createElement('a')
    const byteOrderMark = "\uFEFF" // apparantly excel does not recognize csv as utf8 unless this BOM is there
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(byteOrderMark + text))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function deviceIsTouch() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0))
}

/** Whether is Aware device
 *  @param {string} serial_number
 * @returns {boolean} */
export function isAwareDevice(serial_number) {
    return serial_number.startsWith("40")
}

/** return time string version of minutes
 * @param {int} minutes eg. 1200
 * @returns {string} if minutes=1200, return '20:00'
 */
export function getTimeHHmmStringFromMinutesSinceMidnight(minutes) {
    const midnight = new Date(new Date().setHours(0, 0, 0, 0))
    const sinceMidnight = new Date(midnight.getTime() + minutes * 60000)
    return sinceMidnight.toTimeString().slice(0, 5)
}

/** @param {string} timeString eg '20:00'
 * @returns {int} eg. 1200 if timeString='20:00' */
export function getMinutesSinceMidnightFromString(timeString) {
    const [hours, min] = timeString.split(":").map(str => parseInt(str))
    return (hours * 60) + min;
}

/** @param {int} startMinutesSinceMidnight eg 1200 (which would mean '20:00')
 * @param {int} endMinutesSinceMidnight eg 1320 (which would mean '22:00')
 * @returns {int} 120 if startMinutesSinceMidnight=1200 and endMinutesSinceMidnight=1320 */
export function getTimerDuration(startMinutesSinceMidnight, endMinutesSinceMidnight) {
    // if end is earlier than start, then it means it has wrapped a day, so we add a day (in minutes) to end before subtracting start
    return ((endMinutesSinceMidnight < startMinutesSinceMidnight ? 24 * 60 : 0) + endMinutesSinceMidnight) - startMinutesSinceMidnight
}

export const getFormattedDate = (dateStr) => {
    const lastUpdated = new Date(dateStr)
    return lastUpdated.getDate().toString().padStart(2, '0') + "/" + (lastUpdated.getMonth() + 1).toString().padStart(2, '0') + "/" + lastUpdated.getFullYear() + " " + lastUpdated.getHours().toString().padStart(2, '0') + ":" + lastUpdated.getMinutes().toString().padStart(2, '0') + ":" + lastUpdated.getSeconds().toString().padStart(2, '0');
}

export const convertEpochToDateTime = (epoch) => {
    const date = new Date(epoch * 1000)
    return date.toLocaleString()
}

/** Removes chars from right of string
 * @param {string} str
 * @param {string} charlist
 * @returns {string}
 */
export function trimRight(str, charlist) {
    return str.replace(new RegExp("[" + charlist + "]+$"), "");
}

export function roundToFractionDigits(value, fractionDigits = 1) {
    if (value === undefined || value === null) return "-"
    if (value === "-") return value
    const num = typeof value === "string" ? parseFloat(value.replaceAll(",", ".")) : value
    return num.toFixed(fractionDigits)
}

export function AnyReportedParameters(device) {
    return !!(device?.device_twin?.reported?.parameters && Object.keys(device.device_twin.reported.parameters).length > 0)
}
