import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {deviceParams} from '../../../../helpers/constants';
import {useDispatch} from "react-redux";
import {setOverviewFilter} from "../../../../helpers/reduxstore/reducers/filterReducer.js";
import {getCurrentLanguage} from '../../../../helpers/i18nConfig.js'
import {paramNames} from "../../../parameters/parameterSpec.js";
import {Button} from "react-bootstrap";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";
import {isAwareDevice, prependZeroes} from "../../../../helpers/helperfunctions.js";
import DeviceOperation from "../tabledependencies/deviceoperation/DeviceOperation.js";
import AirQuality from "../tabledependencies/airquality/AirQuality.js";
import RoomTemperature from "../tabledependencies/roomtemperature/RoomTemperature.js";
import DeviceFlow from "../tabledependencies/deviceflow/DeviceFlow.js";
import DeviceAlarm from "../tabledependencies/devicealarm/DeviceAlarm.js";
import DeviceName from "../tabledependencies/devicename/DeviceName.js";
import {awareTelemNames} from "../../../../helpers/awareParamSpec.js";
import Spinner from "react-bootstrap/Spinner";

/** Renders the table for the overview page
 * @param className
 * @param {[Object]} shownDeviceList list of devices to view in the table
 * @param {boolean} listViewChosen property to collapse or expand the cells in the table
 * @param {boolean} deviceListLoading whether device list is loading
 * @param {Object} deviceListSort
 */
export default function DeviceOverviewTableBody({
                                                    className, shownDeviceList, listViewChosen,
                                                    deviceListLoading, deviceListSort
                                                }) {
    const {t} = useTranslation(['overviewpage'])
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const sortHandler = (headerData) => {
        dispatch(setOverviewFilter({
            deviceListSort: {
                sortDirection: headerData.sortValue === deviceListSort?.sortValue && deviceListSort?.sortDirection === 'asc' ? 'desc' : "asc",
                sortValue: headerData.sortValue
            }
        }))
    }

    const navigateToDeviceDashboard = (deviceSerialNumber) => {
        navigate(`/device/${deviceSerialNumber}/dashboard`, {});
    }

    const deviceHeaders = [
        {
            name: t("overviewpage:table.headers.name"),
            sortValue: "name",
            type: "string",
            headerClass: "",
        },
        {
            name: t("overviewpage:table.headers.airflow"),
            sortValue: `latest_telemetry['${deviceParams.airflow}']`,
            type: "number",
            headerClass: "d-md-table-cell d-none",
        },
        {
            name: t("overviewpage:table.headers.operation"),
            sortValue: `latest_telemetry['${deviceParams.started_by}']`,
            type: "string",
            headerClass: "d-lg-table-cell d-none",
        },
        {
            name: t("overviewpage:table.headers.quality"),
            sortValue: `latest_telemetry['${deviceParams.co2}']`, // previously severity, but I have never seen that property on device
            type: "number",
            headerClass: "d-sm-table-cell d-none text-nowrap",
        },
        {
            name: t("overviewpage:table.headers.temperature"),
            sortValue: `latest_telemetry['${deviceParams.room}']`,
            type: "number",
            headerClass: "d-lg-table-cell d-none",
        },
        {
            name: t("overviewpage:table.headers.status"),
            sortValue: `translated_conditions['${getCurrentLanguage()}']`,
            type: "array",
            headerClass: "",
        }
    ]

    return (
        <div className={className}>
            <table className={`w-100 airmaster-table ${listViewChosen ? 'list-view' : 'large-view'}`}>
                <thead>
                <tr>
                    {deviceHeaders.map((header, idx) =>
                        <th key={idx} className={header.headerClass}>
                            <Button onClick={() => sortHandler(header)}
                                    variant='outline-primary' active={deviceListSort?.sortValue === header.sortValue}
                                    style={{borderRadius: '10px', padding: '0.75rem', paddingLeft: '1.5rem'}}
                                    className='d-flex w-100 text-nowrap'>
                                <span>{header.name}</span>
                                {deviceListSort?.sortValue === header.sortValue && <>
                                    {deviceListSort.sortDirection === "desc" ?
                                        <ChevronDown className='filter-chevron'/> :
                                        <ChevronUp className='filter-chevron'/>}
                                </>}
                            </Button>
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {deviceListLoading && <tr className='bg-transparent'>
                    <td className='p-4' colSpan={7}>
                        <div className='d-flex justify-content-center align-items-center'
                             style={{flex: '1'}}>
                            <Spinner style={{height: '50px', width: '50px'}} animation="border"
                                     variant="primary"
                                     size='lg'></Spinner>
                        </div>
                    </td>
                </tr>}
                {!deviceListLoading && shownDeviceList.map(device =>
                    <tr key={device.id} className={`cursor-pointer ${getConditionBg(device)}`}
                        onClick={() => navigateToDeviceDashboard(device.serial_number)}>
                        <td>
                            <DeviceName listViewChosen={listViewChosen} name={device.name} path={device.path}
                                        model={device.model} sn={prependZeroes(device.serial_number)}/>
                        </td>
                        <td className='d-md-table-cell d-none'>
                            {device.connected &&
                                <DeviceFlow
                                    className={`overview-cell d-flex flex-column justify-content-center align-items-center position-relative ${!device.connected ? 'd-none' : ''}`}
                                    showComponent={device.connected} listViewChosen={listViewChosen}
                                    flow={device.latest_telemetry[deviceParams.airflow]}/>
                            }
                        </td>
                        <td className='d-lg-table-cell d-none'>
                            <DeviceOperation
                                device={device}
                                className={`d-flex flex-column align-items-center position-relative ${!device.connected ? 'd-none' : ''}`}
                                listViewChosen={listViewChosen}
                                startedby={isAwareDevice(device.serial_number) ? device.latest_telemetry[awareTelemNames.started_by] : (device?.latest_telemetry?.[deviceParams.started_by] ?? device.device_twin?.reported?.parameters?.[deviceParams.started_by_param] ?? "undefined")}
                                mode={isAwareDevice(device.serial_number) ? device.latest_telemetry[awareTelemNames.op_mode] : device.latest_telemetry[deviceParams.system_mode]}
                                isAware={isAwareDevice(device.serial_number)}/>
                        </td>
                        <td className='d-sm-table-cell d-none text-nowrap'>
                            <AirQuality className={`${!device.connected ? 'd-none' : ''}`}
                                        device={device}
                                        isCO2Installed={device.installed_components.co2_sensor}
                                        isVocInstalled={device.installed_components.tvoc_sensor}
                                        isHumidityInstalled={device.installed_components.humidity_control_sensor}
                                        co2={device.latest_telemetry[deviceParams.co2]}
                                        humidity={device.latest_telemetry[deviceParams.humidity]}
                                        voc={device.latest_telemetry[deviceParams.voc]}
                                        listViewChosen={listViewChosen}
                            />
                        </td>
                        <td className='d-lg-table-cell d-none'>
                            <RoomTemperature
                                className={`d-flex justify-content-center ${!device.connected ? 'd-none' : ''}`}
                                room={device.latest_telemetry?.[deviceParams.room]}
                                inlet={device.latest_telemetry?.[deviceParams.inlet]}
                                outlet={device.latest_telemetry?.[deviceParams.outlet]}
                                outside={getOutside(device)}
                            />
                        </td>
                        <td>
                            <div
                                className='overview-cell d-flex flex-column justify-content-center align-items-center position-relative'>
                                <DeviceAlarm device={device} listViewChosen={listViewChosen}/>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

function getOutside(device) {
    if (!device.connected) return "-"
    if (device.installed_components.cooler) return device?.latest_telemetry?.[paramNames.outside_temperature]
    return device?.latest_telemetry?.[paramNames.outside_temperature_ventilation]
}

function getConditionBg(device) {
    if (!device.connected) return ''
    if (Object.keys(device.system_alarm)?.length > 0) return 'danger'
    if (Object.keys(device.system_condition)?.length > 0) return ''
    return ''
}
